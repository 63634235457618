import HomePageComponent from "./components/HomePageComponent";
import axios from "axios";

const getBestsellers = async () => {
    const { data } = await axios.get("/api/products/bestsellers");
    return data;
}

const HomePage = () => {
    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <HomePageComponent fetchSetting={fetchSetting} getBestsellers={getBestsellers} />;
};

export default HomePage;
