import {
    Row,
    Col,
    Container,
    Form,
    Button,
    CloseButton,
    Table,
    Alert,
    Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { changeCategory, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";
import paths from "../../../router/paths";

const onHover = {
    cursor: "pointer",
    position: "absolute",
    left: "5px",
    top: "-10px",
    transform: "scale(2.7)",
};

const EditPostPageComponent = ({
    fetchPosts,
    updatePostApiRequest,
    reduxDispatch,
    imageDeleteHandler,
    uploadImagesApiRequest,
    uploadImagesCloudinaryApiRequest
}) => {
    const [validated, setValidated] = useState(false);
    const [posts, setPosts] = useState({});
    const [updatePostResponseState, setUpdatePostResponseState] = useState({
        message: "",
        error: "",
    });

    const [imageRemoved, setImageRemoved] = useState(false)
    const [isUploading, setIsUploading] = useState("");
    const [imageUploaded, setImageUploaded] = useState(false);

    const { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        fetchPosts(id)
            .then((posts) => setPosts(posts))
            .catch((er) => console.log(er));
    }, [id, imageRemoved, imageUploaded, fetchPosts]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;

        const formInputs = {
            title: form.title.value,
            author: form.author.value,
            content: form.content.value,
        };

        if (event.currentTarget.checkValidity() === true) {
            updatePostApiRequest(id, formInputs)
                .then((data) => {
                    if (data.message === "post updated") navigate("/admin/posts");
                })
                .catch((er) =>
                    setUpdatePostResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    })
                );
        }

        setValidated(true);
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    }

    return (
        <Container className="mb-5 pb-5">
            <Row className="justify-content-md-center mt-5">
                <Col md={1}>
                    <Link to={paths.ADMINPOSTS} className="btn btn-outline-primary my-3">
                        Go Back
                    </Link>
                </Col>
                <Col md={6}>
                    <h1>Edit Blog Post</h1>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
                        <Form.Group className="mb-3" controlId="formBasicTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                name="title"
                                required
                                type="text"
                                defaultValue={posts.title}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicAuthor">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                                name="author"
                                required
                                type="text"
                                defaultValue={posts.author}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Content</Form.Label>
                            <Form.Control
                                name="content"
                                required
                                as="textarea"
                                rows={3}
                                defaultValue={posts.content}
                            />
                        </Form.Group>

                        <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
                            <Form.Label>Images</Form.Label>
                            <Row>
                                {posts.images &&
                                    posts.images.map((image, idx) => (
                                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                                            <Image
                                                crossOrigin="anonymous"
                                                src={image.path ?? null}
                                                fluid
                                            />
                                            <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                                        </Col>
                                    ))}
                            </Row>
                            <Form.Control type="file" multiple onChange={e => {
                                setIsUploading("upload files in progress ...");
                                if (process.env.NODE_ENV !== "production") {
                                    // to do: change to !==
                                    uploadImagesApiRequest(e.target.files, id)
                                        .then(data => {
                                            setIsUploading("upload file completed");
                                            setImageUploaded(!imageUploaded);
                                        })
                                        .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                                } else {
                                    uploadImagesCloudinaryApiRequest(e.target.files, id);
                                    setIsUploading("upload file completed. wait for the result take effect, refresh also if neccassry");
                                    setTimeout(() => {
                                        setImageUploaded(!imageUploaded);
                                    }, 5000)
                                }
                            }} />
                            {isUploading}
                        </Form.Group>
                        <div className="text-center">
                            <Button variant="outline-primary" type="submit">
                                UPDATE
                            </Button>
                        </div>
                        {updatePostResponseState.error ?? ""}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default EditPostPageComponent;