import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const EagleMountainLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleEagleMountain}
                description={setting.seoHelmentDescriptionEagleMountain}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeEagleMountain} />
            <div className="row eagleMountainBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Eagle Mountain Mobile Locksmith</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        Welcome to Eagle Mountain, a rapidly growing city in Utah County known for its stunning mountain views, family-friendly atmosphere, and thriving
                        business community. At the heart of the city, you'll find Captain Lock and Key, your trusted local locksmith providing top-quality services for your
                        home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        Our team of experienced technicians is equipped with the latest tools and technology to handle all of your locksmith needs. We offer a wide range of
                        services, including:
                    </p>
                    <p className="font-size-16">
                        Residential Locksmith Services: From lock repair and installation to rekeying and key duplication, our residential locksmith services provide peace of
                        mind and enhanced security for your home and family.
                        Commercial Locksmith Services: We specialize in high-security locks, access control systems, and master key systems to keep your business secure and
                        running smoothly.
                        Automotive Locksmith Services: Whether you need a new key fob or transponder key programming, our automotive locksmith services ensure that you never
                        get locked out of your car or stranded on the road.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we understand the importance of security and the value of prompt and reliable service. That's why we prioritize fast response
                        times, affordable rates, and friendly customer service. As a local business, we take pride in serving the community and building lasting relationships
                        with our customers.
                    </p>
                    <p className="font-size-16">
                        Whether you need emergency locksmith services or are looking to enhance the security of your home or business, Captain Lock and Key is here to help.
                        Contact us today to schedule an appointment or receive a free quote for our services. We are proud to be Eagle Mountain's trusted locksmith.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row eagleMountainBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Residential locksmith services in Eagle mountain</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you're looking for reliable and trustworthy residential locksmith services in Eagle Mountain, look no further than our locksmith company.
                                    We provide a wide range of residential locksmith services to help protect your home and keep your family safe. Whether you need a new lock
                                    installed, a key duplicated, or a lock repaired, our team of skilled professionals has the expertise and experience to handle any residential
                                    locksmith issue. We use only the highest quality products and techniques to ensure that your locks and security systems are functioning
                                    properly and providing the maximum level of protection. We offer fast and reliable service at an affordable price, and we are committed
                                    to providing the best possible customer experience. With our residential locksmith services, you can have peace of mind knowing that your
                                    home is secure and protected. Contact us today to schedule a consultation and experience the convenience and reliability of our high-quality
                                    residential locksmith services in Eagle Mountain.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Eagle Mountain Commercial Locksmith Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    As a business owner in Eagle Mountain, you need to take the security of your property seriously. That's where our commercial locksmith services
                                    come in. Our team of skilled professionals provides a comprehensive range of commercial locksmith services to help protect your business and keep
                                    it secure. We understand that every business has unique security needs, which is why we offer customized solutions to meet your specific
                                    requirements. Our services include high-security locks, master key systems, access control systems, CCTV installation, and more. We use only
                                    the highest quality products and techniques to ensure that your locks and security systems are functioning properly and providing the maximum
                                    level of protection. We offer fast and reliable service at an affordable price, and we are committed to providing the best possible customer
                                    experience. With our commercial locksmith services in Eagle Mountain, you can have peace of mind knowing that your business is protected from
                                    theft and intrusion. Contact us today to schedule a consultation and experience the convenience and reliability of our high-quality commercial
                                    locksmith services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Emergency Locksmith Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you find yourself in an emergency lockout situation, don't panic. Our locksmith company provides fast and reliable emergency locksmith services
                                    to help you get back to your routine quickly. Whether you're locked out of your home, car, or business, our team of skilled technicians is available
                                    24/7 to help you out. We use the latest technology and techniques to ensure that we can handle any emergency lockout situation, including broken
                                    keys, lost keys, or keys locked inside the property. Our emergency locksmith services are affordable, fast, and reliable, and we are committed to
                                    providing the best possible customer experience. With our emergency locksmith services, you can have peace of mind knowing that help is just a phone
                                    call away. Contact us today to schedule a consultation and experience the convenience and reliability of our high-quality emergency locksmith
                                    services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">High Security Lock Installation in Eagle mountain</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Protecting your property and keeping your family or business safe is essential, and our locksmith company provides high-security lock installation
                                    services in Eagle Mountain to help you achieve just that. Our team of skilled professionals has the expertise and experience to install the highest
                                    quality high-security locks to provide maximum protection for your home or business. We use only the best products and techniques to ensure that
                                    your locks are functioning correctly and providing the maximum level of security. Our high-security locks are designed to prevent unauthorized
                                    access and resist tampering, and we offer customized solutions to meet your unique security needs. Our high-security lock installation services
                                    in Eagle Mountain are fast, reliable, and affordable, and we are committed to providing the best possible customer experience. With our high-security
                                    lock installation services, you can have peace of mind knowing that your property is protected from theft and intrusion. Contact us today to
                                    schedule a consultation and experience the convenience and reliability of our high-quality high-security lock installation services in Eagle
                                    Mountain.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Adams Right mechanism Replacement</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you're experiencing issues with your Adams Right mechanism, our locksmith company provides expert replacement services to help you restore the
                                    security of your property quickly. Our team of skilled professionals has the expertise and experience to replace your Adams Right mechanism with a
                                    new, reliable, and high-quality lock that will provide maximum security for your property. We use only the best products and techniques to ensure
                                    that your new lock is functioning correctly and providing the maximum level of protection. Our Adams Right mechanism replacement services are fast,
                                    reliable, and affordable, and we are committed to providing the best possible customer experience. With our Adams Right mechanism replacement
                                    services, you can have peace of mind knowing that your property is secure from unauthorized access and theft. Contact us today to schedule a
                                    consultation and experience the convenience and reliability of our high-quality Adams Right mechanism replacement services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Key Replacement</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Losing or breaking your car keys can be a frustrating and stressful experience. That's why our locksmith company provides fast and reliable car key
                                    replacement services to help you get back on the road quickly. Our team of skilled professionals has the expertise and experience to replace your
                                    car keys with high-quality, durable keys that will work perfectly with your vehicle. We use only the latest technology and techniques to ensure that
                                    we can handle any type of car key replacement, including transponder keys, key fobs, and remote keys. Our car key replacement services are
                                    affordable, fast, and reliable, and we are committed to providing the best possible customer experience. With our car key replacement services,
                                    you can have peace of mind knowing that you won't be stranded without a key for long. Contact us today to schedule a consultation and experience
                                    the convenience and reliability of our high-quality car key replacement services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default EagleMountainLocksmithComponent;
