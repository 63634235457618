import axios from "axios";
import ResidentialSafeComponent from "./components/ResidentialSafeComponent";

const ResidentialSafePage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <ResidentialSafeComponent fetchSetting={fetchSetting} />;
};

export default ResidentialSafePage;
