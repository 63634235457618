import paths from '../router/paths';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaBars } from 'react-icons/fa';
import { logout } from "../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { setChatRooms, setSocket, setMessageReceived, removeChatRoom } from "../redux/actions/chatActions";

function MobileHeaderComponent() {
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.userRegisterLogin);
    const { messageReceived } = useSelector((state) => state.adminChat);
    
    useEffect(() => {
        if (userInfo.isAdmin) {
            var audio = new Audio("/audio/chat-msg.mp3");
            const socket = socketIOClient();
            socket.emit("admin connected with server", "Admin" + Math.floor(Math.random() * 1000000000000));
            socket.on("server sends message from client to admin", ({ user, message }) => {
                dispatch(setSocket(socket));
                //   let chatRooms = {
                //     fddf54gfgfSocketID: [{ "client": "dsfdf" }, { "client": "dsfdf" }, { "admin": "dsfdf" }],
                //   };
                dispatch(setChatRooms(user, message));
                dispatch(setMessageReceived(true));
                audio.play();
            })
            socket.on("disconnected", ({ reason, socketId }) => {
                //   console.log(socketId, reason)
                dispatch(removeChatRoom(socketId));
            })
            return () => socket.disconnect();
        }
    }, [userInfo.isAdmin, dispatch])

    const myFunction = (e) => {
        document.getElementById("navbarToggleExternalContent").classList.toggle("show");
    }
    // Close the dropdown if the user clicks outside of it
    window.onclick = function (event) {
        if (!event.target.matches('.dropbtn')) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    }

    return (
        <>
            <nav className="row navbar text-dark navbar-light bg-light pt-3 pb-3">
                <div className="ms-5 col container-fluid ">
                    <button className="navbar-toggler border-light dropbtn" type="button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                        <FaBars/>
                    </button>
                </div>
                
                <div className="col container-fluid">
                    <button className="ms-auto me-5 navbar-toggler border-light dropbtn" type="button" data-bs-toggle="collapse" data-bs-target="#mobileContent2" aria-controls="mobileContent2" aria-expanded="false" aria-label="Toggle navigation">
                        <BsFillTelephoneFill />
                    </button>
                </div>
            </nav>
            <div className="collapse navbar-collapse bg-light dropdown-content zglenindex" id="navbarToggleExternalContent">
                <ul className="p-4 navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link text-dark" to={paths.ROOT}>Home</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className="nav-link text-dark" to={paths.BLOG}>Blog</Link>
                    </li>
                    <li className="nav-item dropdown text-dark">
                        <Link className="nav-link dropdown-toggle text-dark" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Services
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item dropEnd">
                                <Link className="font-size-nav nav-link text-dark" to={paths.COMMLOCK}>
                                    Commercial Locksmith
                                </Link>
                                <ul className="dropdown-menu font-size-nav">
                                    <li><Link className="dropdown-item" to={paths.PUSHBAR}>Push Bar Services</Link></li>
                                    <li><Link className="dropdown-item" to={paths.COMMREKEY}>Commercial Rekey Services</Link></li>
                                    <li><Link className="dropdown-item" to={paths.COMMLOCKINSTALL}>Lock Installation and Repair</Link></li>
                                    <li><Link className="dropdown-item" to={paths.COMMMASTERKEY}>Master Key Service</Link></li>
                                    <li><Link className="dropdown-item" to={paths.COMMKEYDUP}>Key Duplication</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropEnd">
                                <Link className="font-size-nav nav-link text-dark" to={paths.RESLOCK}>
                                    Residential Locksmith
                                </Link>
                                <ul className="dropdown-menu font-size-nav">
                                    <li><Link className="dropdown-item" to={paths.RESLOCKINSTALL}>Lock Installation</Link></li>
                                    <li><Link className="dropdown-item" to={paths.RESKEYEXTRACT}>Residential Key Extraction</Link></li>
                                    <li><Link className="dropdown-item" to={paths.RESSAFE}>Safe Install & Open</Link></li>
                                    <li><Link className="dropdown-item" to={paths.RESREKEY}>Residential Rekey</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropEnd">
                                <Link className="font-size-nav nav-link text-dark" to={paths.AUTOLOCK}>
                                    Automotive Locksmith
                                </Link>
                               
                            </li>
                            <li className="nav-item dropEnd">
                                <Link className="font-size-nav nav-link text-dark" to={paths.EMERGLOCK}>
                                    Emergency Locksmith
                                </Link>
                                <ul className="dropdown-menu font-size-nav">
                                    <li><Link className="dropdown-item" to={paths.BUSINESSLOCK}>Business Lockout</Link></li>
                                    <li><Link className="dropdown-item" to={paths.HOUSELOCK}>House Lockout</Link></li>
                                    <li><Link className="dropdown-item" to={paths.VEHICLELOCK}>Vehicle Lockout</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-dark" to={paths.ABOUT}>About</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-dark" to={paths.VEHICLES}>Vehicles</Link>
                    </li>
                    
                    <li className="nav-item">
                        <Link className="nav-link text-dark" to={paths.CONTACT}>Contact</Link>
                    </li>
                    
                    {userInfo.isAdmin ? (
                        <>
                            <li className="nav-item">
                                <Link className="nav-link text-dark" to={paths.ADMINMESSAGE}>
                                    Admin
                                    {messageReceived && <span className="position-absolute top-3 start-10 translate-middle p-2 bg-danger border border-light rounded-circle"></span>}
                                </Link>
                            </li>
                           
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-dark" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {userInfo.name}
                                </a>
                                <div className="dropdown-menu ps-3" aria-labelledby="#navbarDropdown">
                                    <Link className="nav-link" to={paths.USERPROFILE}><li>My Profile</li></Link>
                                    <Link className="nav-link" onClick={() => dispatch(logout())}>Logout</Link>
                                </div>
                            </li>
                        </>
                    ) : 
                      (
                                <>
                                    
                        </>
                        )}
                    
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle bg-light text-dark" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Service Areas
                        </a>
                        <div className="dropdown-menu droppingZindex" aria-labelledby="navbarDropdownMenuLink">
                            <Link className="dropdown-item" to={paths.GENOLA}>Genola Locksmith</Link>
                            <Link className="dropdown-item" to={paths.MAPLETON}>Mapleton Locksmith</Link>
                            <Link className="dropdown-item" to={paths.SPANISHFORK}>Spanish Fork Locksmith</Link>
                            <Link className="dropdown-item" to={paths.PAYSON}>Payson Locksmith</Link>
                            <Link className="dropdown-item" to={paths.SALEM}>Salem Locksmith</Link>
                            <Link className="dropdown-item" to={paths.SANTAQUIN}>Santaquin Locksmith</Link>
                            <Link className="dropdown-item" to={paths.OREM}>Orem Locksmith</Link>
                            <Link className="dropdown-item" to={paths.VINEYARD}>Vineyard Locksmith</Link>
                            <Link className="dropdown-item" to={paths.ELKRIDGE}>Elk Ridge Locksmith</Link>
                            <Link className="dropdown-item" to={paths.ALPINE}>Alpine Locksmith</Link>
                            <Link className="dropdown-item" to={paths.LEHI}>Lehi Locksmith</Link>
                            <Link className="dropdown-item" to={paths.AMERICANFORK}>American Fork Locksmith</Link>
                            <Link className="dropdown-item" to={paths.LINDON}>Lindon Locksmith</Link>
                            <Link className="dropdown-item" to={paths.EAGLEMOUNTAIN}>Eagle Mountain Locksmith</Link>
                            <Link className="dropdown-item" to={paths.PROVO}>Provo Locksmith</Link>

                        </div>
                    </li>
                    
                </ul>
            </div>
           
            <div className="collapse navbar-collapse2 bg-light text-dark dropdown-content" id="mobileContent2">
                <ul className="navbar-nav">
                    <li className="nav-item nav-link font-bold me-auto text-dark">
                        Contact Us
                    </li>
                    <li className="nav-item text-dark nav-link me-auto ms-2 mt-0 pt-0">
                        <a href="tel:(801) 609-8234">Tel: (801) 609-8234</a>
                    </li>
                
                </ul>
            </div>
        </>
    );
}

export default MobileHeaderComponent;