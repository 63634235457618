import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const PaysonLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitlePayson}
                description={setting.seoHelmentDescriptionPayson}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypePayson} />
            <div className="row paysonBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Payson, Utah</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        If you're in need of dependable locksmith services in Payson, look no further than Captain Lock and Key. Our team of knowledgeable technicians
                        are equipped with the latest tools and technology to provide high-quality locksmith services for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        We offer a comprehensive range of services, including but not limited to:
                    </p>
                    <ul className="font-size-16">
                        <li>
                            Residential Locksmith Services: We provide a complete range of locksmith services for your home, from lock repair and rekeying to installation and maintenance.
                        </li>
                        <li>
                            Commercial Locksmith Services: Our team can handle all of your business's locksmith needs, from high-security locks to access control systems and master key systems.
                        </li>
                        <li>
                            Automotive Locksmith Services: Whether you need car key duplication, transponder key programming, or ignition repair, our expert technicians have got you covered.
                        </li>
                        </ul>
                        <p className="font-size-16">
                        At Captain Lock and Key, we understand that the security of your property is of the utmost importance. That's why we provide fast, friendly, and dependable
                        service, so you can have peace of mind knowing that your locks are in good hands.
                        </p>
                    <p className="font-size-16">
                        We are proud to be the trusted locksmiths of Payson. Contact us today to schedule an appointment or to receive a free quote for our services.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row paysonBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Automotive locksmith services are designed to help drivers with all their lock-related needs. Whether you have locked your keys
                                    in your car, lost your car keys, or need to have your car's locks rekeyed, an automotive locksmith can help. They offer a wide
                                    range of services, including car lockout assistance, key duplication, and transponder key programming, among others. With their
                                    advanced tools and expertise, automotive locksmiths can quickly and effectively resolve any locksmith issue, without causing any
                                    damage to your vehicle. They are available 24/7 to provide fast and reliable assistance, anywhere in the area. Whether you are stranded
                                    on the side of the road or parked in a busy parking lot, an automotive locksmith can reach you and provide the assistance you need.
                                    With their mobile services, you don't have to worry about towing your car to a dealership, saving you time and money. Contact a
                                    local automotive locksmith today to receive fast and reliable assistance with all your locksmith needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Lock Rekey Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Lock rekeying is a process where a locksmith changes the internal mechanism of a lock so that it can be operated by a new key.
                                    This service is often performed when a key is lost or stolen, or when a tenant moves out of a rental property. Rekeying is a
                                    cost-effective alternative to installing new locks, as it allows you to maintain your existing locks while ensuring that they
                                    are only operable with your new key. A professional locksmith can rekey your locks quickly and efficiently, without causing any
                                    damage to your locks or the surrounding area. They have the necessary tools, skills, and expertise to accurately rekey your locks,
                                    ensuring that they work smoothly and effectively. With their fast and reliable services, locksmiths can help you save time and money,
                                    while ensuring that your property remains secure. Contact a local locksmith today to receive fast and reliable assistance with your
                                    lock rekey needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Commercial Locksmiths In Payson</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Commercial locksmiths play a critical role in ensuring the security and safety of businesses in Payson. They offer a wide range of services
                                    designed to help business owners protect their assets, employees, and customers, including lock installation, repair, and replacement services.
                                    Whether you need to upgrade your locks, repair a broken lock, or rekey your locks for security purposes, a professional commercial locksmith
                                    can assist you. They have the necessary tools, skills, and expertise to quickly and effectively solve any locksmith issue, without causing any
                                    damage to your business or disrupting your daily operations. With their mobile services, commercial locksmiths can reach you anywhere in Payson,
                                    at any time, to provide fast and reliable assistance. They are trained to handle a variety of locksmith needs, including lockouts, key duplication,
                                    and rekeying services. By choosing a licensed and experienced locksmith, you can ensure that your business is in good hands and that your
                                    locksmith needs are met efficiently and effectively. Contact a local commercial locksmith in Payson today to receive fast and reliable assistance
                                    with all your locksmith needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Lockouts In Payson</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Car lockouts can be an unexpected and stressful experience, especially when they occur at an inconvenient time. If you find yourself
                                    locked out of your car in Payson, it's essential to have a reliable locksmith on hand to assist you. A professional automotive
                                    locksmith can quickly and efficiently resolve your lockout issue, ensuring that you can get back on the road as soon as possible.
                                    With their extensive knowledge and experience in automotive locks and security systems, locksmiths are equipped to handle even the
                                    most complex car lockout situations. They have the necessary tools, skills, and expertise to quickly and effectively resolve any
                                    lockout issue, without causing any damage to your car or the surrounding area. With their fast and reliable services, locksmiths
                                    can ensure that your car lockout is resolved quickly and efficiently, giving you peace of mind and allowing you to get back to your
                                    daily activities as soon as possible. Contact a local automotive locksmith in Payson today to receive fast and reliable assistance
                                    with your car lockout needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Business Lockouts In Payson</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Business lockouts can be frustrating and stressful experiences, especially when they occur at an inconvenient time. If you find
                                    yourself locked out of your business in Payson, it's essential to have a trusted and reliable locksmith on hand to assist you.
                                    A professional locksmith can quickly and efficiently resolve your lockout issue, ensuring that you can get back to your business
                                    as soon as possible. With their extensive knowledge and experience in commercial locks and security systems, locksmiths are equipped
                                    to handle even the most complex lockout situations. They have the necessary tools, skills, and expertise to quickly and effectively
                                    resolve any lockout issue, without causing any damage to your business or the surrounding area. With their fast and reliable services,
                                    locksmiths can ensure that your business lockout is resolved quickly and efficiently, giving you peace of mind and allowing you to
                                    get back to your business operations as soon as possible. Contact a local locksmith in Payson today to receive fast and reliable
                                    assistance with your business lockout needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Residential Locksmiths In Payson</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Residential locksmiths play a crucial role in ensuring the security and safety of homes in Payson. They offer a wide range of services
                                    designed to help homeowners protect their homes and families, including lock installation, repair, and replacement services. Whether
                                    you need to replace old or worn out locks, install new locks, or repair a broken lock, a professional residential locksmith can assist
                                    you. They have the necessary tools, skills, and expertise to quickly and effectively solve any locksmith issue, without causing any
                                    damage to your home. With their mobile services, residential locksmiths can reach you anywhere in Payson, at any time, to provide fast
                                    and reliable assistance. They are trained to handle a variety of locksmith needs, including lockouts, key duplication, and rekeying
                                    services. By choosing a licensed and experienced locksmith, you can ensure that your home is in good hands and that your locksmith needs
                                    are met efficiently and effectively. Contact a local residential locksmith in Payson today to receive fast and reliable assistance with
                                    all your locksmith needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default PaysonLocksmithComponent;
