import React, { useState } from "react";

const ContactComponent = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showAlertSuc, setShowAlertSuc] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            message: e.target.message.value,
        };

        try {
            const response = await fetch('https://www.locksmithmenu.com/api/custContact/post?company=64c09f1cfcdd9b1f7cf1ff66&web=Captain%20Lock%20and%20Key', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setShowAlertSuc(true);
                setTimeout(() => setShowAlertSuc(false), 3000);
                setFormSubmitted(true);
            } else {
                console.log('Error submitting form');
            }
        } catch (error) {
            console.log('Error submitting form', error);
        }
    };

    return (
        <div className="container fw-bold mb-5 mt-5 pb-4 ps-4 pe-4 borderingLight">
            {showAlertSuc && (
                <div className="alert alert-success" role="alert">
                    Contact Form Submitted Successfully
                    <div className="alert-line-container">
                        <div className="alert-line"></div>
                        <span className="alert-close" onClick={() => setShowAlertSuc(false)}>X</span>
                    </div>
                </div>
            )}
            <form id="myContactForm" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input className="form-control" placeholder="John Doe" type="text" id="name" name="name" required />
                </div>
                <div className="mt-3">
                    <label htmlFor="email">Email:</label>
                    <input className="form-control" placeholder="example@gmail.com" type="email" id="email" name="email" required />
                </div>
                <div className="mt-3">
                    <label htmlFor="phone">Phone Number:</label>
                    <input className="form-control" type="tel" placeholder="(801) 888-8888" id="phone" name="phone" required />
                </div>
                <div className="mt-3">
                    <label htmlFor="message">Message:</label>
                    <textarea className="form-control" id="message" name="message" required></textarea>
                </div>
                <div className="mt-3 row justify-content-center text-center">
                    <div className="col-sm-5">
                        <button type="submit" className="btn btn-outline-primary" disabled={formSubmitted}>
                            {formSubmitted ? 'Submitted' : 'Send Message'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ContactComponent;
