import axios from "axios";
import GenolaLocksmithComponent from "./components/GenolaLocksmithComponent";

const GenolaLocksmithPage = () => {
    
    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <GenolaLocksmithComponent fetchSetting={fetchSetting} />;
};

export default GenolaLocksmithPage;
