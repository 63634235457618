import axios from "axios";
import PostPageComponent from "./components/PostPageComponent";

const fetchPosts = async (abctrl) => {
    const { data } = await axios.get("/api/posts/admin", {
        signal: abctrl.signal,
    })
    return data;
}
const createPosts = async (abctrl) => {
    const { data } = await axios.post("/api/posts/admin/post", {
        signal: abctrl.signal,
    })
    return data;
}
const deletePost = async (postId) => {
    const { data } = await axios.delete(`/api/posts/admin/${postId}`);
    return data
}
const AdminPostPage = () => {
    return <PostPageComponent createPosts={createPosts} deletePost={deletePost} fetchPosts={fetchPosts} />
};

export default AdminPostPage;
