import React from 'react'
import Ford from '../gallery/vehicleLogo/ford-logo.webp';
import Kia from '../gallery/vehicleLogo/kia-logo.webp';
import Lexus from '../gallery/vehicleLogo/lexus-logo.webp';
import Toyota from '../gallery/vehicleLogo/toyota-Logo.webp';
import Chevy from '../gallery/vehicleLogo/chevrolet-logo.webp';
import Audi from '../gallery/vehicleLogo/audi-logo.webp';
import Bmw from '../gallery/vehicleLogo/bmw-logo.webp';
import Buick from '../gallery/vehicleLogo/buick-logo.webp';
import Cadillac from '../gallery/vehicleLogo/cadillac-logo.webp';
import Chrysler from '../gallery/vehicleLogo/chrysler-logo.webp';
import Dodge from '../gallery/vehicleLogo/dodge-logo.webp';
import Gmc from '../gallery/vehicleLogo/gmc-logo.webp';
import Honda from '../gallery/vehicleLogo/honda-logo.webp';
import Hyundai from '../gallery/vehicleLogo/hyundai-logo.webp';
import Jeep from '../gallery/vehicleLogo/jeep-logo.webp';
import Mercedes from '../gallery/vehicleLogo/mercedes-benz-logo.webp';
import Mini from '../gallery/vehicleLogo/mini-logo.webp';
import Mitshubishi from '../gallery/vehicleLogo/mitshubishi-logo.webp';
import Mzda from '../gallery/vehicleLogo/mzda-logo.webp';
import Nissan from '../gallery/vehicleLogo/nissan-logo.webp';
import Volkswagon from '../gallery/vehicleLogo/volkswagon-logo.webp';
import Acura from '../gallery/vehicleLogo/acura_logo.webp';

const VehicleLogoGallery = () => {
    return (
        <>
            <div className="w-100 mb-5">
                <div className="row text-center mt-5">
                    <div className="col me-5">
                        <img className="img-fluid img-thumbnail img-background" src={Ford} alt="Ford Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Kia} alt="Kia Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Lexus} alt="Lexus Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Toyota} alt="Toyota Logo" />
                    </div>
                </div>
                <div className="row text-center mt-5">
                    <div className="col me-5">
                        <img className="img-fluid img-thumbnail img-background" src={Honda} alt="Honda Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Hyundai} alt="Hyundai Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Jeep} alt="Jeep Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Mercedes} alt="Mercedes Logo" />
                    </div>
                </div>
                <div className="row text-center mt-5">
                    <div className="col me-5">
                        <img className="img-fluid img-thumbnail img-background" src={Mini} alt="Mini Cooper Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Mitshubishi} alt="Mitshubishi Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Mzda} alt="Mazda Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Nissan} alt="Nissan Logo" />
                    </div>
                </div>
                <div className="row text-center mt-5">
                    <div className="col me-5">
                        <img className="img-fluid img-thumbnail img-background" src={Chevy} alt="Chevrolet Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Audi} alt="Audi Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Bmw} alt="BMW Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Buick} alt="Buick Logo" />
                    </div>
                </div>
                <div className="row text-center mt-5">
                    <div className="col me-5">
                        <img className="img-fluid img-thumbnail img-background" src={Cadillac} alt="Cadillac Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Chrysler} alt="Chrysler Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Dodge} alt="Dodge Logo" />
                    </div>
                    <div className="col">
                        <img className="img-fluid img-thumbnail img-background" src={Gmc} alt="GMC Logo" />
                    </div>
                </div>
                <div className="row text-center mt-5">
                    <div className="col-sm-3">
                    </div>
                    <div className="col-sm-3">
                        <img className="img-fluid img-thumbnail img-background" src={Volkswagon} alt="Volkswagon Logo" />
                    </div>
                    <div className="col-sm-3">
                        <img className="img-fluid img-thumbnail img-background" src={Acura} alt="Acura Logo" />
                    </div>
                </div>
            </div>
       </>
    )
}

export default VehicleLogoGallery;