import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import FB from "../../gallery/fb.png";
import Angi from "../../gallery/angieslist.png";
import Google from "../../gallery/google.png";

const ResidentialSafeComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleResSafe}
                description={setting.seoHelmentDescriptionResResSafe}
                name={setting.seoHelmentName}
                type="webpage" />
            <div className="row ResidentialSafeBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Safe Services</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row pb-5 bg-light justify-content-center pt-5">
                        <div className="col-sm-7">
                            <p className="font-size-16">
                                Safe services provided by professional locksmiths include a range of solutions for securing and accessing safes of all types. These experts have the knowledge and tools
                                required to install, repair, and unlock safes, providing valuable security measures for homes and businesses. They can also assist with safe combination changes and
                                upgrades to ensure that only authorized individuals have access to the contents of the safe. In the event of a lost or forgotten safe combination, a locksmith can use
                                specialized tools and techniques to gain entry to the safe without causing any damage. They can also offer guidance on choosing the right type of safe to meet specific
                                security needs, whether it be a fireproof safe, a floor safe, or a wall safe. Safe services provided by professional locksmiths are an important aspect of ensuring the
                                safety and security of valuable items and assets.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>

                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Key Safe Lockout
                            </h2>
                            <p className="font-size-16">
                                A key safe lockout can be a frustrating experience, especially if the contents of the key safe are essential. A key safe is designed to provide secure storage for keys,
                                access cards, and other important items, and losing access to these items can cause a significant disruption to daily routines. If you find yourself locked out of your key
                                safe, it's important to resist the urge to try to force it open, as this can cause damage to the lock or the safe itself. Instead, contact a professional locksmith who
                                can safely and efficiently open the key safe and restore access to its contents. They can use specialized tools and techniques to unlock the safe without causing any damage
                                and can also provide guidance on how to prevent future lockouts. With the help of a professional locksmith, a key safe lockout can be resolved quickly and with minimal
                                hassle, allowing you to get back to your daily routine.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>

                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Safe Lockout
                            </h2>
                            <p className="font-size-16">
                                A safe lockout can be a stressful and concerning experience, particularly if the contents of the safe are valuable or important. When a safe lockout occurs, it's important
                                to resist the temptation to try to force the safe open, as this can cause irreversible damage to the lock and the safe itself. Instead, it's best to contact a professional
                                locksmith who has the knowledge and expertise to safely and efficiently open the safe without causing any damage. Locksmiths use specialized tools and techniques to gain
                                access to the safe and can also provide guidance on how to prevent future lockouts. If the lock is damaged or beyond repair, the locksmith can also provide lock replacement
                                services to ensure the safe remains secure. A safe lockout can be a nerve-wracking experience, but with the help of a professional locksmith, it can be resolved quickly and
                                with minimal hassle, ensuring the safety and security of the safe's contents.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>

                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Digital and Dial combination Safe Lockout
                            </h2>
                            <p className="font-size-16">
                                Digital and dial combination safe lockouts can be frustrating and time-consuming experiences, as they require specialized knowledge and tools to resolve. If you find
                                yourself locked out of a digital or dial combination safe, it's important to resist the urge to attempt to force the lock open, as this can cause damage to the safe and
                                make it more difficult to resolve the lockout. Instead, contact a professional locksmith who has experience with opening digital and dial combination safes. They will
                                use specialized tools and techniques to open the safe without causing any damage and can also provide guidance on how to prevent future lockouts. If the lock is damaged
                                or beyond repair, the locksmith can provide lock replacement services to ensure the safety and security of the safe's contents. With the help of a professional locksmith,
                                a digital or dial combination safe lockout can be resolved quickly and efficiently, minimizing any disruption to your daily routine.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>

                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Safe Installation
                            </h2>
                            <p className="font-size-16">
                                Safe installation is a crucial service provided by professional locksmiths to ensure the security and protection of valuable items and assets. Installing a safe requires
                                careful planning and execution to ensure that it is securely fastened and positioned in an optimal location. Professional locksmiths have the knowledge and experience
                                required to advise on the right type of safe to meet specific security needs, as well as the appropriate size and location for installation. They can also provide guidance
                                on additional security measures such as alarms and monitoring systems. Once the right safe has been chosen, the locksmith will carefully install it in a secure location
                                using specialized tools and techniques to ensure that it is fastened securely to the wall or floor. After installation, the locksmith will test the safe to ensure that it
                                is functioning correctly and provide instructions on how to operate it. Safe installation is a critical aspect of ensuring the safety and security of valuable items and
                                assets, and should be entrusted to a professional locksmith with the necessary expertise and experience.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center pt-5">
                <div className="col-sm-12 text-center">
                    <div className="borderingLight pt-5 pb-5">
                        <h2 className="fw-bolder text-center ms-4 pt-2 pb-4">Places To Review Captain Lock and Key</h2>
                        <div className="row justify-content-center">
                            <div className="col-3">
                                <img src={FB} className="img-thumbnail img-background" alt="Facebook Logo" />
                            </div>
                            <div className="col-3">
                                <img src={Angi} className="img-thumbnail img-background" alt="Angis Leads Logo" />
                            </div>
                            <div className="col-3">
                                <img src={Google} className="img-thumbnail img-background" alt="Google Logo" />
                            </div>
                        </div>
                        <Link to={paths.CONTACT} className="mt-3 btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResidentialSafeComponent;
