import axios from "axios";
import VineyardLocksmithComponent from "./components/VineyardLocksmithComponent";


const VineyardLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <VineyardLocksmithComponent fetchSetting={fetchSetting} />;
};

export default VineyardLocksmithPage;
