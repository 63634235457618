import axios from "axios";
import OremLocksmithComponent from "./components/OremLocksmithComponent";


const OremLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <OremLocksmithComponent fetchSetting={fetchSetting} />;
};

export default OremLocksmithPage;
