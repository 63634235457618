import axios from "axios";

export const uploadImagesApiRequest = async (images, postId) => {
  const formData = new FormData();
  Array.from(images).forEach((image) => {
    formData.append("images", image);
  });
    const { data } = await axios.post("/api/posts/admin/upload?postId=" + postId, formData);
  return data;
};

export const uploadImagesCloudinaryApiRequest = (images, postId) => {
    const url = "https://api.cloudinary.com/v1_1/dkgaujqhn/image/upload";
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
        let file = images[i];
        formData.append("file", file);
        formData.append("upload_preset", "acsijtdn");
        fetch(url, {
            method: "POST",
            body: formData,
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            axios.post("/api/posts/admin/upload?cloudinary=true&postId=" + postId, data);
        })
    }
}