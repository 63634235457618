import {
    Image,
} from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import ImageZoom from "js-image-zoom";
import { useEffect, useState } from "react";
import MetaComponent from "../../components/MetaComponent";
import { useParams } from "react-router-dom";

const BlogDetailsPageComponent = ({
    getPostDetails,
    fetchSetting
}) => {
    const { id } = useParams();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [setting, setSetting] = useState([]);

    useEffect(() => {
        if (posts.images) {
            var options = {
 
                scale: 2,
                offset: { vertical: 0, horizontal: 0 },
            };

            posts.images.map(
                (image, id) =>
                    new ImageZoom(document.getElementById(`imageId${id + 1}`), options)
            );
        }
    });

    useEffect(() => {
        getPostDetails(id)
            .then((data) => {
                setPosts(data);
                setLoading(false);
            })
            .catch((er) =>
                setError(
                    er.response.data.message ? er.response.data.message : er.response.data
                )
            );
    }, [id, getPostDetails]);

    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <>
            <MetaComponent
                title={posts.title}
                description={posts.content}
                name={setting.seoHelmentName}
                type={setting.seoHelmentType} />
            <div className="container-fluid w-100 p-0">
                <div className="row">
                    {loading ? (
                        <h2>Loading Blog details ...</h2>
                    ) : error ? (
                        <h2>{error}</h2>
                    ) : (
                                <>
                                    <div className="col">
                                       
                                <Carousel className="w-100">
                                    {posts.images
                                        ? posts.images.map((image, id) => (
                                            <Carousel.Item key={id}>
                                                <Image
                                                    crossOrigin="anonymous"
                                                    fluid
                                                    style={{ height: "500px", width: "100%", objectFit: "cover",  }}
                                                    src={`${image.path ?? null}`}
                                                />
                                            </Carousel.Item>
                                        ))
                                        : null}
                                </Carousel>
                                    </div>
                        </>
                    )}
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-6">
                        <h1 className="text-center mt-3">{posts.title}</h1>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-sm-6">
                        <p className="font-size-18">{posts.content}</p>
                    </div>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-sm-6">
                        <p className="font-size-16">Written By: {posts.author} | {new Date(posts.updatedAt).toLocaleDateString()}</p>
                    </div>
                </div>
            </div>
        </>

    );
};

export default BlogDetailsPageComponent;
