import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import paths from './paths';
import Layout from '../components/layout';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
/*Protected User Routes */
import UserProfilePage from '../pages/user/UserProfilePage';
/*Protected Admin Routes */
import AdminChatsPage from '../pages/admin/AdminChatsPage';
import AdminEditSettingsPage from '../pages/admin/AdminEditSettingsPage';
import AdminUsersPage from '../pages/admin/AdminUsersPage';
import AdminEditUserPage from '../pages/admin/AdminEditUserPage';
import AdminContactPage from '../pages/admin/AdminContactPage';

import RoutesWithUserChatComponent from '../components/user/RoutesWithUserChatComponent';
import ScrollToTop from '../utils/ScrollToTop';
import NotFoundPage from '../pages/notFoundPage';
import ProtectedRoutesComponent from '../components/ProtectedRoutesComponent';
import AboutUsPage from '../pages/AboutUsPage';
import ContactUsPage from '../pages/ContactUsPage';
import VehiclesPage from '../pages/VehiclesPage';
import MapletonLocksmithPage from '../pages/MapletonLocksmithPage';
import SpanishForkLocksmithPage from '../pages/SpanishForkLocksmithPage';
import PaysonLocksmithPage from '../pages/PaysonLocksmithPage';
import SalemLocksmithPage from '../pages/SalemLocksmithPage';
import SantaquinLocksmithPage from '../pages/SantaquinLocksmithPage';
import OremLocksmithPage from '../pages/OremLocksmithPage';
import VineyardLocksmithPage from '../pages/VineyardLocksmithPage';
import ElkRidgeLocksmithPage from '../pages/ElkRidgeLocksmithPage';
import AlpineLocksmithPage from '../pages/AlpineLocksmithPage';
import LehiLocksmithPage from '../pages/LehiLocksmithPage';
import AmericanForkLocksmithPage from '../pages/AmericanForkLocksmithPage';
import LindonLocksmithPage from '../pages/LindonLocksmithPage';
import EagleMountainLocksmithPage from '../pages/EagleMountainPage';
import ProvoLocksmithPage from '../pages/ProvoLocksmithPage';
import GenolaLocksmithPage from '../pages/GenolaLocksmithPage';
import AdminPostPage from '../pages/admin/AdminPostsPage';
import AdminCreatePostPage from '../pages/admin/AdminCreatePostPage';
import AdminEditPostPage from '../pages/admin/AdminEditPostPage';
import BlogPage from '../pages/BlogPage';
import PostDetailsPage from '../pages/PostDetailPage';
import AutomotiveLocksmithPage from '../pages/AutomotiveLocksmithPage';
import CommercialLocksmithPage from '../pages/CommercialLocksmithPage';
import EmergancyLocksmithPage from '../pages/EmergancyLocksmithPage';
import ResidentialLocksmithPage from '../pages/ResidentialLocksmithPage';
import BusinessLockoutPage from '../pages/BusinessLockoutPage';
import VehicleLockoutPage from '../pages/VehicleLockoutPage';
import HomeLockoutPage from '../pages/HomeLockoutPage';
import PushBarLocksmithPage from '../pages/PushBarLocksmithPage';
import CommercialMasterKeyDupPage from '../pages/CommercialKeyDupPage';
import CommercialMasterKeyPage from '../pages/CommercialMasterKeyPage';
import CommercialInstallPage from '../pages/CommercialInstallPage';
import CommercialRekeyPage from '../pages/CommercialRekeyPage';
import ResidentialInstallPage from '../pages/ResidentialInstallPage';
import ResidentialRekeyPage from '../pages/ResidentialRekeyPage';
import ResidentialSafePage from '../pages/ResidentialSafePage';
import ResidentialKeyExtractionPage from '../pages/ResidentialKeyExtractionPage';

const RouterComponent = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Layout>
                <Routes>
                    <Route element={<RoutesWithUserChatComponent />}>
                        <Route path={paths.ROOT} caseSensitive={false} element={<HomePage />} />
                        <Route path={paths.CONTACT} caseSensitive={false} element={<ContactUsPage />} />
                        <Route path={paths.AUTOLOCK} caseSensitive={false} element={<AutomotiveLocksmithPage />} />
                        <Route path={paths.COMMLOCK} caseSensitive={false} element={<CommercialLocksmithPage />} />
                        <Route path={paths.EMERGLOCK} caseSensitive={false} element={<EmergancyLocksmithPage />} />
                        <Route path={paths.RESLOCK} caseSensitive={false} element={<ResidentialLocksmithPage />} />
                        <Route path={paths.BUSINESSLOCK} caseSensitive={false} element={<BusinessLockoutPage />} />
                        <Route path={paths.VEHICLELOCK} caseSensitive={false} element={<VehicleLockoutPage />} />
                        <Route path={paths.HOUSELOCK} caseSensitive={false} element={<HomeLockoutPage />} />
                        <Route path={paths.PUSHBAR} caseSensitive={false} element={<PushBarLocksmithPage />} />
                        <Route path={paths.COMMKEYDUP} caseSensitive={false} element={<CommercialMasterKeyDupPage />} />
                        <Route path={paths.COMMMASTERKEY} caseSensitive={false} element={<CommercialMasterKeyPage />} />
                        <Route path={paths.COMMLOCKINSTALL} caseSensitive={false} element={<CommercialInstallPage />} />
                        <Route path={paths.COMMREKEY} caseSensitive={false} element={<CommercialRekeyPage />} />
                        <Route path={paths.BLOG} caseSensitive={false} element={<BlogPage />} />
                        <Route path={paths.BLOGDETAIL} caseSensitive={false} element={<PostDetailsPage />} />
                        <Route path={paths.ABOUT} caseSensitive={false} element={<AboutUsPage />} />
                        <Route path={paths.GENOLA} caseSensitive={false} element={<GenolaLocksmithPage />} />
                        <Route path={paths.MAPLETON} caseSensitive={false} element={<MapletonLocksmithPage />} />
                        <Route path={paths.SPANISHFORK} caseSensitive={false} element={<SpanishForkLocksmithPage />} />
                        <Route path={paths.PAYSON} caseSensitive={false} element={<PaysonLocksmithPage />} />
                        <Route path={paths.SALEM} caseSensitive={false} element={<SalemLocksmithPage />} />
                        <Route path={paths.SANTAQUIN} caseSensitive={false} element={<SantaquinLocksmithPage />} />
                        <Route path={paths.OREM} caseSensitive={false} element={<OremLocksmithPage />} />
                        <Route path={paths.VINEYARD} caseSensitive={false} element={<VineyardLocksmithPage />} />
                        <Route path={paths.ELKRIDGE} caseSensitive={false} element={<ElkRidgeLocksmithPage />} />
                        <Route path={paths.ALPINE} caseSensitive={false} element={<AlpineLocksmithPage />} />
                        <Route path={paths.LEHI} caseSensitive={false} element={<LehiLocksmithPage />} />
                        <Route path={paths.AMERICANFORK} caseSensitive={false} element={<AmericanForkLocksmithPage />} />
                        <Route path={paths.LINDON} caseSensitive={false} element={<LindonLocksmithPage />} />
                        <Route path={paths.EAGLEMOUNTAIN} caseSensitive={false} element={<EagleMountainLocksmithPage />} />
                        <Route path={paths.PROVO} caseSensitive={false} element={<ProvoLocksmithPage />} />
                        <Route path={paths.RESLOCKINSTALL} caseSensitive={false} element={<ResidentialInstallPage />} />
                        <Route path={paths.RESREKEY} caseSensitive={false} element={<ResidentialRekeyPage />} />
                        <Route path={paths.RESSAFE} caseSensitive={false} element={<ResidentialSafePage />} />
                        <Route path={paths.RESKEYEXTRACT} caseSensitive={false} element={<ResidentialKeyExtractionPage />} />

                        <Route path={paths.VEHICLES} caseSensitive={false} element={<VehiclesPage />} />
                        <Route path={paths.LOGIN} caseSensitive={false} element={<LoginPage />} />
                    </Route>
                    {/*Protected User Routes */}
                    <Route element={<ProtectedRoutesComponent admin={false} />}>
                        <Route path={paths.USERPROFILE} caseSensitive={false} element={<UserProfilePage />} />
                        </Route>
                    {/*Protected Admin Routes */}
                    <Route element={<ProtectedRoutesComponent admin={true} />}>
                        <Route path={paths.ADMINCHATS} caseSensitive={false} element={<AdminChatsPage />} />
                        <Route path={paths.ADMINPOSTS} caseSensitive={false} element={<AdminPostPage />} />
                        <Route path={paths.ADMINCREATEPOST} caseSensitive={false} element={<AdminCreatePostPage />} />
                        <Route path={paths.ADMINEDITPOST} caseSensitive={false} element={<AdminEditPostPage />} />
                        <Route path={paths.ADMINEDITUSER} caseSensitive={false} element={<AdminEditUserPage />} />
                        <Route path={paths.ADMINUSERS} caseSensitive={false} element={<AdminUsersPage />} />
                        <Route path={paths.ADMINSETTINGS} caseSensitive={false} element={<AdminEditSettingsPage />} />
                        <Route path={paths.ADMINMESSAGE} caseSensitive={false} element={<AdminContactPage />} />
                    </Route>
                    <Route path={paths.NOTFOUND} caseSensitive={false} element={<NotFoundPage />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
};

export default RouterComponent;