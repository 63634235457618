import axios from "axios";
import CommercialKeyDupComponent from "./components/CommercialKeyDupComponent";

const CommercialMasterKeyDupPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <CommercialKeyDupComponent fetchSetting={fetchSetting} />;
};

export default CommercialMasterKeyDupPage;
