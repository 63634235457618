import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import Home from "../../gallery/Homeowner-Inserting-key-into-lock.jpg";
import Install from "../../gallery/Locksmith-installing-residential-hardware.jpg";
import Unlock from "../../gallery/Person-unlocking-a-safe-with-code.jpg";
import Deadbolt from "../../gallery/Close-Up-broken-key-in-deadbolt.jpg";

const ResidentialLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100 m-0 p-0">
            <MetaComponent
                title={setting.seoHelmentTitleResid}
                description={setting.seoHelmentDescriptionResid}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeResid} />
            <div className="row ResidentialBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Springville Residential Locksmith</h1>
                            </div>
                        </div>
                        <div className="row text-center mb-3 justify-content-center">
                            <div className="col-sm-8 text-center text-light">
                        <p className="font-size-18 text-light text-center">
                            Captain Lock and Key is your reliable residential locksmith service in Springville for all your key, lock, and security needs. We provide a wide range of services, from
                            lock installation to key duplication and emergency lockout assistance. Our skilled technicians are available 24/7 to respond to your call, ensuring a fast response time
                            of about 30 minutes. We understand the importance of a swift response in a lockout situation, and we take pride in delivering efficient and reliable services to our clients.
                            Our goal is to keep your home safe and secure, and we offer customized security solutions to fit your specific needs. Whether you need a lock repair or a high-security lock
                            installation, we've got you covered. Contact Captain Lock and Key today for all your residential locksmith needs in Springville.
                                </p>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5 pb-5 pt-5 bg-light">
                <div className="col-md-7">
                    <p className="font-size-16">
                        At Captain Lock and Key, we believe that providing top-quality residential locksmith services to our customers is just the beginning. We also offer expert consultation on how
                        to make your home more secure and efficient. As a new homeowner, you may want to have your home rekeyed to ensure that you have the only keys for your property. Additionally,
                        you may want to have a security system installed for peace of mind. We understand the importance of safety and security for our customers, and we take pride in making sure that
                        you feel safe and secure in your home, no matter where you live or what type of property you have.
                    </p>
                    <p className="font-size-16">
                        To ensure the highest level of service, we only hire the best locksmiths with expertise and integrity. Our team stays up-to-date on the latest technologies, including home
                        automation and smart locks. We use cutting-edge equipment that is well-maintained to deliver exceptional service for any job, big or small. We take customer satisfaction seriously,
                        and we work with each customer until they are fully satisfied with our services. When you choose Captain Lock and Key for your residential locksmith needs, you can trust that you
                        will never be disappointed. Contact us today to learn more about our services and how we can help secure your home in Springville.
                    </p>
                </div>
            </div>
            <div className="container mb-5 pb-5">
            <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h2 className="fw-bolder">Residential Locksmith Services</h2>
                        <p className="font-size-16">
                            To discover more about our comprehensive range of residential locksmith services, please don't hesitate to call Captain Lock and Key
                            at <a href="tel: (801) 609-8234">(801) 609-8234</a>. Our team of experienced
                            professionals is available to help with all your locksmith needs and will provide you with expert advice and guidance to ensure your home's security is top-notch.
                        </p>
                        <p className="font-size-16">
                            Our residential locksmith services are designed to cater to the unique requirements of homeowners in Springville. Whether you need lock installation, repair, or replacement
                            services, we can help. Our technicians are equipped with the latest tools and technologies to deliver exceptional service for any job, no matter how big or small. We can also
                            assist with key duplication, rekeying, and emergency lockout services to provide you with the peace of mind you need. Our customized security solutions are designed to keep
                            your property safe and secure.
                        </p>
                        <p className="font-size-16">
                            We take pride in delivering fast and reliable services to our customers, and we're committed to ensuring your complete satisfaction. If you have any questions about our
                            services or would like to schedule an appointment with one of our experienced locksmiths, please call us today. At Captain Lock and Key, we're always here to help you with
                            all your residential locksmith needs.
                        </p>
                        <div className="row justify-content-center mt-5">
                            <div className="col ms-5 me-5">
                                <div className="text-center">
                                    <img src={Deadbolt} alt="Close up of a broken key in a deadbolt" className="aboutPictures" />
                                </div>
                                <h2 className="font-size-20 text-center mt-5 fw-bold">
                                    Broken key extraction
                                </h2>
                                <p className="font-size-12 mt-4">
                                    At Captain Lock and Key, we understand the frustration that comes with a key breaking off and getting stuck in a lock, which is why we offer residential key extraction
                                    services to homeowners in Springville. It's a common occurrence that can happen to anyone, and trying to extract the key yourself can cause further damage to your lock
                                    or door. It's always best to leave it to the professionals.
                                </p>
                                <p className="font-size-12 mt-4">
                                    Our expert locksmiths are highly trained and equipped with the latest tools and techniques to safely remove the broken key from your lock without causing any damage.
                                    We understand the importance of time in such situations, and that's why we offer fast and reliable services to our customers. Whether it's an emergency or non-emergency
                                    situation, you can count on us to be there for you.
                                </p>
                                <p className="font-size-12 mt-4">
                                    If you find yourself in a broken key situation, just give us a call, and we'll take care of the rest. Our team of professionals will arrive at your location promptly to
                                    extract the broken key and get you back inside your home in no time. We can even provide you with a new key if needed, so you don't have to worry about getting locked
                                    out again. Contact Captain Lock and Key today to take advantage of our residential key extraction service.
                                </p>
                            </div>
                            <div className="col ms-5 me-5">
                                <div className="text-center">
                                    <img src={Home} alt="Homeowner inserting a key into deadbolt" className="aboutPictures" />
                                </div>
                                <h2 className="font-size-20 text-center mt-5 fw-bold">
                                    Residential rekey services
                                </h2>
                                <p className="font-size-12 mt-4">
                                    There are many reasons why homeowners might need to have their locks rekeyed. It's important to do this to prevent anyone from easily breaking into your home. If you've
                                    lost a key or recently moved into a new home, it's a good idea to get your locks rekeyed. Otherwise, there could be multiple keys out there that can unlock your front
                                    door. At Captain Lock and Key, we offer residential rekey services to ensure that unauthorized intruders cannot enter your home.
                                </p>
                                <p className="font-size-12 mt-4">
                                    While some may think that a house rekey is a big job, our experienced locksmiths can perform it quickly and efficiently. We are knowledgeable on rekeying just about any
                                    type of lockout there. The process may differ for various lock types, such as deadbolts, doorknobs, or screen door locks, but our experienced locksmiths can handle any
                                    type of rekey. We will evaluate your locks and determine which ones need to be rekeyed to secure your home. We have all the necessary tools and resources to complete
                                    your house rekey job in a timely and efficient manner.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col ms-5 me-5">
                                <div className="text-center">
                                    <img src={Install} alt="Locksmith installing residential hardware" className="aboutPictures" />
                                </div>
                                <h2 className="font-size-20 text-center mt-5 fw-bold">
                                    Residential Lock Installation
                                </h2>
                                <p className="font-size-12 mt-4">
                                    Captain Lock and Key is dedicated to providing professional locksmith services for your home, including lock installation. Whether you need to replace an old door
                                    knob or deadbolt or require a completely new installation, our team of experts can handle the job. A new installation involves drilling a hole in the door where there
                                    was none before, and our experienced technicians can install regular locks, high security locks, pick-proof locks, and bump-proof locks. We can also advise you on the
                                    best lock options to meet your specific needs. If your locks are damaged beyond repair or you want to upgrade your existing locks, contact us and our friendly
                                    dispatchers will assist you.
                                </p>
                            </div>
                            <div className="col ms-5 me-5">
                                <div className="text-center">
                                    <img src={Unlock} alt="Person unlocking a safe with a code" className="aboutPictures" />
                                </div>
                                <h2 className="font-size-20 text-center mt-5 fw-bold">
                                    Safe install and open
                                </h2>
                                <p className="font-size-12 mt-4">
                                    Safes are an essential tool in keeping important documents and valuables safe, which is why Captain Lock and Key provides a safe install and open service as one of our
                                    many residential locksmith services. Our clients find peace of mind knowing their sensitive possessions are well-protected with a high-quality safe. Our locksmiths in
                                    Springville are well-versed in the various safe options available in the market, be it a key or combination safe. We are equipped to provide you with professional
                                    recommendations to ensure you get the right safe for your needs. With our knowledge of safe options and functions, we guarantee to provide a high-quality installation
                                    service to protect your valued possessions.
                                </p>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default ResidentialLocksmithComponent;
