import { useEffect, useState } from "react";
import ContactComponent from "../../components/ContactComponent";
import HoursComponent from "../../components/HoursComponent";
import MetaComponent from "../../components/MetaComponent";


const ContactUsPageComponent = ({ fetchSetting, newMessage }) => {
    const [setting, setSetting] = useState([]);

    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100 mb-5 pb-5">
            <MetaComponent
                title={setting.seoHelmentTitleContact}
                description={setting.seoHelmentDescriptionContact}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeContact} />
            <div className="row">
                <div className="col mb-5">
                    <h1 className="text-center mt-5 pt-5">Contact {setting.seoHelmentName} Today!</h1>
                    <div className="hrWidth"><hr className="hrBorder" /></div>
                </div>
            </div>
            <div className="row">
                <div className="col mb-3 text-center">
                    <h5 className="fw-bold">Phone: <a href="tel:(801) 609-8234">(801) 609-8234</a></h5>
                </div>
            </div>
            <div className="row w-100 justify-content-center">
                <div className="col-md-4 mb-5"></div>
                <div className="col-md-3 mb-5">
                        <ContactComponent newMessage={newMessage} />
                </div>
                <div className="col-md-1 mb-5"></div>
                <div className="col-md-3 mb-5 mt-4">
                    <HoursComponent setting={setting} />
                </div>
            </div>

        </div>
    )
}

export default ContactUsPageComponent;