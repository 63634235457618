import axios from "axios";
import EmergancyLocksmithComponent from "./components/EmergancylocksmithComponent";

const EmergancyLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <EmergancyLocksmithComponent fetchSetting={fetchSetting} />;
};

export default EmergancyLocksmithPage;
