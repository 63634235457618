import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import BestRekey from "../../gallery/best_key.webp";
import PushBar from "../../gallery/Push-bar-install.webp";
import RimRekey from "../../gallery/Rim-Cylinder-Rekey.jpg";
import LeverRekey from "../../gallery/Commercial-Door-Lever-Rekey.jpg";
import MortiseRekey from "../../gallery/Rekey-Mortse-Cylinder.jpg";
import KeyRing from "../../gallery/key_ring.jpg";

const CommercialRekeyComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleRekey}
                description={setting.seoHelmentDescriptionRekey}
                name={setting.seoHelmentName}
                type="webpage" />
            <div className="row CommercialRekeyBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Commercial Rekey Services</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row pt-5 pb-5 justify-content-center bg-light">
                        <div className="col-sm-7">
                            <p className="font-size-16">
                                At Captain Lock and Key in Springville, we are committed to supporting local businesses in our community, which is why we provide high-quality rekey services performed by our friendly
                                and professional locksmith technicians. Understanding how a rekey works requires knowledge of how a lock cylinder operates. Typically, lock cylinders have a set of top pins
                                located inside the housing of the lock, which are equipped with springs above them. The bottom pins are contained within the cylinder itself. When the pins are perfectly
                                aligned, the lock can be turned and either locked or unlocked. The bottom pins have different lengths that correspond with keys that have cuts of varying depths. Our
                                locksmith technicians are highly trained and experienced in the rekeying process, which involves changing the key combination of a lock while retaining the original lock
                                cylinder. This is a cost-effective solution that can help improve the security of your business premises. Contact us today to schedule your rekey service and let us help
                                you protect your business.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={BestRekey} className="img-thumbnail-comm img-thumbnail" alt="Locksmith rekeyed lever after all keys are lost" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Lost all Keys Rekey
                            </h2>
                            <p className="font-size-16">
                                Losing all keys to your property can be a stressful and overwhelming experience. It can also pose a serious security risk, as anyone who finds the keys may have access to
                                your property. In such situations, a lost all keys rekey service can be a quick and affordable solution to enhance your property's security. The rekey process involves
                                changing the internal pins of the lock cylinder, rendering the old keys useless and requiring new keys to be made to match the new lock combination. This means that anyone
                                who previously had a key to your property will no longer have access, providing you with peace of mind and enhanced security. Professional locksmiths are trained to perform
                                lost all keys rekey services efficiently and effectively, with minimal disruption to your daily routine. They have the expertise and tools to rekey a wide range of lock
                                types, including deadbolts, doorknobs, and even high-security locks. If you have lost all keys to your property, contact a professional locksmith today to schedule a rekey
                                service and take control of your property's security.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={MortiseRekey} className="img-thumbnail-comm1 img-thumbnail" alt="locksmith is rekeying a mortise lock" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Mortise Lock Rekey
                            </h2>
                            <p className="font-size-16">
                                Mortise lock rekey is an essential service that can help improve the security of your property. A mortise lock is a type of lock that requires a key to operate a bolt,
                                which secures the door to the frame. Over time, the keys to a mortise lock can become lost or stolen, or a change in tenant may require a lock rekey. In such situations,
                                a mortise lock rekey service can be a quick and affordable solution to enhance your property's security. The rekey process involves disassembling the lock cylinder,
                                replacing the internal pins, and creating new keys that match the new lock combination. This renders the old keys useless, preventing unauthorized access to your property.
                                Professional locksmiths are trained to perform mortise lock rekey services efficiently and effectively, ensuring that the lock functions properly and meets all safety
                                standards. With a properly rekeyed mortise lock, you can have peace of mind knowing that your property is secure and protected. Contact a professional locksmith today to
                                schedule a mortise lock rekey service and take control of your property's security.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={KeyRing} className="img-thumbnail-comm1 img-thumbnail" alt="locksmith rekeying a lock after firing a employee" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Fired Employee Rekey
                            </h2>
                            <p className="font-size-16">
                                When an employee is terminated, rekeying the locks of your business premises is an essential step to maintain security and ensure the safety of your staff and property. A
                                fired employee who had access to your property may pose a security risk, as they may still have the keys to the property and could attempt to gain unauthorized entry.
                                Rekeying the locks involves replacing the internal pins of the lock cylinder, rendering the old keys useless and requiring new keys to be made to match the new lock
                                combination. This ensures that only authorized personnel have access to your business premises. Professional locksmiths can provide a quick and efficient fired employee
                                rekey service, helping you to secure your property and prevent potential security breaches. They have the expertise and tools to rekey a wide range of lock types, including
                                deadbolts, doorknobs, and even high-security locks. With a properly rekeyed lock, you can have peace of mind knowing that your business is secure and protected. If you have
                                recently terminated an employee, contact a professional locksmith today to schedule a rekey service and take control of your business's security.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row justify-content-center bg-light pb-5">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={RimRekey} className="img-thumbnail-auto img-thumbnail" alt="Locksmith rekying a push bar rim cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Rim Cylinder Rekey
                            </h2>
                            <p className="font-size-16">
                                Rekeying a rim cylinder lock is an important security measure that can help protect your property from unauthorized access. A rim cylinder lock is a type of lock that is
                                installed on the surface of the door, with the cylinder on the outside of the door and the bolt on the inside. Over time, keys can become lost or stolen, or a change in
                                tenant may require a lock rekey. In such situations, a rim cylinder rekey service can be a quick and affordable solution to enhance your property's security. The rekey
                                process involves replacing the internal pins of the lock cylinder, rendering the old keys useless and requiring new keys to be made to match the new lock combination. This
                                ensures that only authorized personnel have access to your property. Professional locksmiths can perform a rim cylinder rekey service efficiently and effectively, ensuring
                                that the lock functions properly and meets all safety standards. With a properly rekeyed rim cylinder lock, you can have peace of mind knowing that your property is secure
                                and protected. Contact a professional locksmith today to schedule a rim cylinder rekey service and take control of your property's security.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={PushBar} className="img-thumbnail-comm img-thumbnail" alt="Locksmith is rekeying a pushbar cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Push Bar Rekey
                            </h2>
                            <p className="font-size-16">
                                Rekeying a push bar is an important step to maintaining the security of your property. Push bars, also known as panic bars, are commonly used on commercial doors and provide
                                quick and easy egress in emergency situations. However, when keys to the push bar become lost or stolen, or a change in tenant requires a lock rekey, rekeying the push bar
                                is a necessary security measure. The rekey process involves replacing the internal pins of the lock cylinder, rendering the old keys useless and requiring new keys to be
                                made to match the new lock combination. This ensures that only authorized personnel have access to your property. Professional locksmiths are trained to perform push bar
                                rekey services efficiently and effectively, ensuring that the lock functions properly and meets all safety standards. With a properly rekeyed push bar, you can have peace
                                of mind knowing that your property is secure and protected. Contact a professional locksmith today to schedule a push bar rekey service and take control of your property's
                                security.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={LeverRekey} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith is rekeying a key in knob cylinder from a lever" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Commercial Door Lever Rekey
                            </h2>
                            <p className="font-size-16">
                                Rekeying a commercial door lever lock is an important security measure that can help protect your business from unauthorized access. A door lever lock is a type of lock that
                                is commonly used in commercial buildings and provides easy access for employees and customers. However, over time, keys can become lost or stolen, or a change in tenant may
                                require a lock rekey. In such situations, a commercial door lever rekey service can be a quick and affordable solution to enhance your business's security. The rekey
                                process involves replacing the internal pins of the lock cylinder, rendering the old keys useless and requiring new keys to be made to match the new lock combination.
                                This ensures that only authorized personnel have access to your business. Professional locksmiths are trained to perform commercial door lever rekey services efficiently and
                                effectively, ensuring that the lock functions properly and meets all safety standards. With a properly rekeyed commercial door lever lock, you can have peace of mind
                                knowing that your business is secure and protected. Contact a professional locksmith today to schedule a commercial door lever rekey service and take control of your
                                business's security.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommercialRekeyComponent;
