import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const PushBarComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitlePush}
                description={setting.seoHelmentDescriptionPush}
                name={setting.seoHelmentName}
                type="webpage" />
            <div className="row CommercialPushBarBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Push Bar and Emergency Exit Services</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="row pt-5 pb-5 justify-content-center bg-light">
                        <div className="col-sm-7">
                            <p className="font-size-16">
                                Installing a push bar can be a smart choice for businesses looking to improve the safety and convenience of their property. A push bar is particularly useful for back exits
                                of commercial properties, where employees may need to exit the building with their hands full. For instance, restaurants often need to dispose of trash through the back
                                exit. With a push bar, employees can easily exit the building without the need to turn a handle, even with their hands full.
                            </p>
                            <p className="font-size-16">
                                Moreover, push bars are often a required safety feature in emergency exits, along with alarms that notify others when the emergency exit has been used. By installing push
                                bars, businesses can ensure that their employees and customers have a safe and reliable means of exiting the building in the event of an emergency.
                            </p>
                            <p className="font-size-16">
                                Overall, considering the installation of push bars in your business can be a great way to improve the safety and convenience of your property, both for daily operations and
                                emergency situations.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2 me-5">
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Emergency Exit Door Alarm Installation
                            </h2>
                            <p className="font-size-16">
                                Emergency exit door alarm installation is an important safety measure that can help prevent accidents and save lives in emergency situations. These alarms are typically
                                installed on doors that lead to emergency exits, such as stairwells or fire escapes, and are designed to sound an alarm when the door is opened. The alarm can alert
                                building occupants to the location of the emergency exit and prompt them to evacuate the building quickly and safely. Proper installation of emergency exit door alarms
                                requires careful consideration of the building layout and safety regulations, as well as expertise in electrical and security systems. With the right installation and
                                maintenance, these alarms can provide a crucial layer of protection for building occupants in case of emergency.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pb-5 pt-3">
                        <div className="col-sm-2 me-5">
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Push Bar Key Extraction
                            </h2>
                            <p className="font-size-16">
                                Push bar key extraction refers to the process of removing a broken or stuck key from a push bar, which is a type of door hardware commonly used in commercial and public
                                buildings. When a key breaks inside the push bar, it can render the door unusable and compromise the safety and security of the building. In such situations, push bar key
                                extraction can be a lifesaver. This process requires specialized tools and expertise to safely extract the key without damaging the push bar or the door. Professional
                                locksmiths are trained to perform push bar key extractions efficiently and effectively, ensuring minimal disruption to building occupants and maximum security. With the
                                right tools and expertise, push bar key extraction can be a quick and affordable solution to a potentially costly problem.
                            </p>
                        </div>
                    </div>
                    <div className="row pb-5 pt-5 bg-light">
                        <div className="col-sm-2 me-5">
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Push Bar Exit Device Installation
                            </h2>
                            <p className="font-size-16">
                                Push bar exit device installation involves fitting a door with a device that allows for quick and easy exit in case of an emergency. Push bar exit devices are commonly used
                                in commercial and public buildings, where the safety and security of occupants are of utmost importance. The installation process involves careful consideration of building
                                codes, fire regulations, and accessibility requirements. Professional installers are trained to select the right device for the specific door and ensure it is installed
                                correctly to prevent unauthorized access while allowing for quick exit in case of an emergency. Proper push bar exit device installation can also enhance the aesthetics of
                                the building, improve its accessibility, and increase its value. With the right installation and maintenance, a push bar exit device can provide reliable and effective
                                emergency exit solutions, ensuring the safety and security of the building occupants.
                            </p>
                        </div>
                    </div>
                    <div className="row pb-5 pt-3 bg-light">
                        <div className="col-sm-2 me-5">
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Push Bar Exit Device Repair
                            </h2>
                            <p className="font-size-16">
                                Push bar exit device repair is an essential service that helps maintain the safety and security of buildings. Over time, push bar exit devices can become damaged or
                                malfunction due to wear and tear or other factors, which can compromise their effectiveness in an emergency situation. When this happens, it is important to repair the
                                device as soon as possible to prevent the risk of injury or property damage. Professional locksmiths are trained to diagnose and repair a wide range of push bar exit device
                                issues, such as broken bars, worn-out springs, or damaged hinges. They have the expertise and tools to repair the device quickly and effectively, ensuring that it functions
                                properly and meets all safety standards. With prompt and reliable push bar exit device repair, building occupants can have peace of mind knowing that they have a safe and
                                reliable exit solution in case of emergency.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PushBarComponent;
