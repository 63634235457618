import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const MapletonLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleMapleton}
                description={setting.seoHelmentDescriptionMapleton}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeMapleton} />
            <div className="row MapletonBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Mapleton Locksmith Service</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        Looking for fast and reliable locksmith services in Mapleton? Look no further than Captain Lock and Key. Our team of experienced technicians
                        is equipped with the latest tools and technology to provide top-quality locksmith services for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        We offer a wide range of services, including lock repair, rekeying, and installation for residential properties. For businesses, we provide
                        comprehensive locksmith services, including high-security locks, access control systems, and master key systems. Our expert technicians are
                        also equipped to handle automotive locksmith services, including car key duplication, transponder key programming, and ignition repair.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we understand the importance of security for you and your property. That's why we strive to provide fast and friendly
                        service, so you can have peace of mind knowing that your locks are in good hands. Our commitment to quality and customer satisfaction has made us
                        the trusted locksmith of Mapleton.
                    </p>
                    <p className="font-size-16">
                        Contact us today to schedule an appointment or to receive a free quote for our services. Let us help you secure your property and give you the
                        peace of mind you deserve with our reliable locksmith services in Mapleton.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row MapletonBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Lost All Keys</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Losing all of your keys can be a stressful and overwhelming experience, especially if those keys are for
                                    your home, car, or business. When you lose all of your keys, you are unable to access your property and
                                    may be left feeling vulnerable and exposed. To resolve this issue, it is important to contact a professional
                                    locksmith. A locksmith can provide you with new keys, rekey your locks, or install a new security system,
                                    ensuring that your property is secure once again. Locksmiths have the training and equipment necessary to
                                    quickly and efficiently resolve the issue, and they can often provide a solution the same day. Whether you
                                    have lost your keys or simply need a duplicate set, a professional locksmith is the best solution for resolving
                                    a lost all keys situation. With their commitment to quality and customer service, locksmiths are the trusted
                                    providers of key replacement services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Mapleton Rekey Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Rekey services are a critical component of maintaining the security of your home, business, or vehicle.
                                    Rekeying involves changing the internal mechanism of a lock so that it will work with a different key.
                                    This is often necessary when a set of keys is lost or stolen, or when you want to update the security of
                                    your property. Rekeying is a cost-effective and efficient way to ensure that your property is secure, without having
                                    to replace all of your locks. Professional locksmiths have the expertise and equipment necessary to perform
                                    rekey services quickly and effectively, often within a matter of minutes. Whether you need to rekey a single
                                    lock or multiple locks, a professional locksmith is the best solution for resolving your security concerns.
                                    With their commitment to quality and customer service, locksmiths are the trusted providers of rekey services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Mapleton Commercial Locksmith</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Commercial services refer to a range of security solutions and services designed specifically for businesses and commercial
                                    properties. These services are essential for maintaining the security of a business, protecting its assets, and ensuring the
                                    safety of its employees and customers. Commercial services may include lock installation, rekey services, master key systems,
                                    access control systems, and security camera installation, among others. The specific needs of each business will vary, and a
                                    professional locksmith can help determine the best solutions for your specific needs. With their expertise in commercial security
                                    systems, locksmiths are the trusted providers of commercial services. They are committed to delivering quality services and
                                    products, ensuring that businesses have the security they need to operate effectively. Whether you are looking to improve
                                    the security of your business, or you need help with a lockout emergency, a professional locksmith is the best solution
                                    for your commercial security needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Mapleton Car Lockouts</h2>
                                <p className="font-size-16 text-start ms-5">
                                    A car lockout is a frustrating and potentially dangerous situation that can happen to anyone. It occurs when a driver is unable
                                    to access their vehicle, usually because they have lost their car keys or locked them inside the car. Car lockouts can occur anywhere,
                                    at any time, and can leave drivers feeling helpless and vulnerable. Fortunately, car lockouts are a common issue that can be easily
                                    resolved with the help of a professional locksmith. Locksmiths have the training and equipment necessary to quickly and safely unlock
                                    a car, allowing drivers to get back on the road as soon as possible. Whether you are dealing with a lockout emergency or simply need
                                    a duplicate car key, a professional locksmith is the best solution for resolving a car lockout. With their commitment to customer
                                    service and their expertise in all types of locks, locksmiths are the trusted providers of car lockout services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Mapleton Business Lockout</h2>
                                <p className="font-size-16 text-start ms-5">
                                    A business lockout is a situation in which the employees of a business are unable to access the building or a specific area within the building.
                                    This can happen for a variety of reasons, including lost or stolen keys, broken locks, or a malfunctioning security system. A business
                                    lockout can have a significant impact on the operations of a business, causing loss of productivity, frustration, and potential financial
                                    losses. To prevent and resolve business lockouts, it is important to have a professional locksmith on hand. A locksmith can quickly and
                                    efficiently resolve the issue, allowing employees to get back to work as soon as possible. Whether you need a simple lock repair, a rekey,
                                    or a new security system installed, a professional locksmith is the best solution for resolving a business lockout. With their commitment
                                    to quality and customer service, locksmiths are the trusted providers of business lockout services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Mapleton Residential Locksmith</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Residential services refer to a range of security solutions and services designed specifically for homes and residential properties.
                                    These services are essential for maintaining the safety and security of your family, possessions, and property. Residential services
                                    may include lock installation, rekey services, key duplication, security camera installation, and home safes, among others. A
                                    professional locksmith can help determine the best solutions for your specific needs, taking into account your budget, lifestyle, and
                                    security requirements. With their expertise in residential security systems, locksmiths are the trusted providers of residential services.
                                    They are committed to delivering quality services and products, ensuring that families have the security they need to feel safe and
                                    secure in their homes. Whether you are looking to improve the security of your home, or you need help with a lockout emergency, a professional
                                    locksmith is the best solution for your residential security needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default MapletonLocksmithComponent;
