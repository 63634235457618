import EditSettingsPageComponent from "./components/EditSettingsPageComponent";
import axios from "axios";

const fetchSetting = async (settingsId) => {
    const { data } = await axios.get(`/api/settings/${settingsId}`);
    return data;
}
const updateSettingsApiRequest = async(
    settingsId,
    tax,
    shipping,
    monHoursOp,
    monHoursCl,
    tusHoursOp,
    tusHoursCl,
    wedHoursOp,
    wedHoursCl,
    thuHoursOp,
    thuHoursCl,
    friHoursOp,
    friHoursCl,
    satHoursOp,
    satHoursCl,
    sunHoursOp,
    sunHoursCl,
    seoHelmentName,
    seoHelmentTitle,
    seoHelmentDescription,
    seoHelmentType,
    seoHelmentTitleVehicles,
    seoHelmentDescriptionVehicles,
    seoHelmentTypeVehicles,
    seoHelmentTitleLogin,
    seoHelmentDescriptionLogin,
    seoHelmentTypeLogin,
    seoHelmentTitleRegister,
    seoHelmentDescriptionRegister,
    seoHelmentTypeRegister,
    seoHelmentTitleFound,
    seoHelmentDescriptionFound,
    seoHelmentTypeFound,
    seoHelmentTitleUserProfile,
    seoHelmentDescriptionUserProfile,
    seoHelmentTypeUserProfile,
    seoHelmentTitleAbout,
    seoHelmentDescriptionAbout,
    seoHelmentTypeAbout,
    seoHelmentTitleContact,
    seoHelmentDescriptionContact,
    seoHelmentTypeContact,
    seoHelmentTitleGenola,
    seoHelmentDescriptionGenola,
    seoHelmentTypeGenola,
    seoHelmentTitleMapleton,
    seoHelmentDescriptionMapleton,
    seoHelmentTypeMapleton,
    seoHelmentTitleSpanishFork,
    seoHelmentDescriptionSpanishFork,
    seoHelmentTypeSpanishFork,
    seoHelmentTitlePayson,
    seoHelmentDescriptionPayson,
    seoHelmentTypePayson,
    seoHelmentTitleSalem,
    seoHelmentDescriptionSalem,
    seoHelmentTypeSalem,
    seoHelmentTitleSantaquin,
    seoHelmentDescriptionSantaquin,
    seoHelmentTypeSantaquin,
    seoHelmentTitleOrem,
    seoHelmentDescriptionOrem,
    seoHelmentTypeOrem,
    seoHelmentTitleVineyard,
    seoHelmentDescriptionVineyard,
    seoHelmentTypeVineyard,
    seoHelmentTitleElkRidge,
    seoHelmentDescriptionElkRidge,
    seoHelmentTypeElkRidge,
    seoHelmentTitleAlpine,
    seoHelmentDescriptionAlpine,
    seoHelmentTypeAlpine,
    seoHelmentTitleLehi,
    seoHelmentDescriptionLehi,
    seoHelmentTypeLehi,
    seoHelmentTitleAmericanFork,
    seoHelmentDescriptionAmericanFork,
    seoHelmentTypeAmericanFork,
    seoHelmentTitleLindon,
    seoHelmentDescriptionLindon,
    seoHelmentTypeLindon,
    seoHelmentTitleEagleMountain,
    seoHelmentDescriptionEagleMountain,
    seoHelmentTypeEagleMountain,
    seoHelmentTitleProvo,
    seoHelmentDescriptionProvo,
    seoHelmentTypeProvo,
    seoHelmentTitleBlog,
    seoHelmentDescriptionBlog,
    seoHelmentTypeBlog,
    seoHelmentTitleAuto,
    seoHelmentDescriptionAuto,
    seoHelmentTypeAuto,
    seoHelmentTitleComm,
    seoHelmentDescriptionComm,
    seoHelmentTypeComm,
    seoHelmentTitleEmerg,
    seoHelmentDescriptionEmerg,
    seoHelmentTypeEmerg,
    seoHelmentTitleResid,
    seoHelmentDescriptionResid,
    seoHelmentTypeResid,
    seoHelmentTitleAutoLock,
    seoHelmentDescriptionAutoLock,
    seoHelmentTypeAutoLock,
    seoHelmentTitleCommLock,
    seoHelmentDescriptionCommLock,
    seoHelmentTypeCommLock,
    seoHelmentTitleHomeLock,
    seoHelmentDescriptionHomeLock,
    seoHelmentTypeHomeLock,
    seoHelmentTitlePush,
    seoHelmentDescriptionPush,
    seoHelmentTitleRekey,
    seoHelmentDescriptionRekey,
    seoHelmentTitleInstall,
    seoHelmentDescriptionInstall,
    seoHelmentTitleMasterKey,
    seoHelmentDescriptionMasterKey,
    seoHelmentTitleKeyDup,
    seoHelmentDescriptionKeyDup,
    seoHelmentTitleResInstall,
    seoHelmentDescriptionResInstall,
    seoHelmentTitleResKeyExtract,
    seoHelmentDescriptionResKeyExtract,
    seoHelmentTitleResSafe,
    seoHelmentDescriptionResSafe,
    seoHelmentTitleResRekey,
    seoHelmentDescriptionResRekey,
) => {
    const { data } = await axios.put(`/api/settings/${settingsId}`, {
        tax,
        shipping,
        monHoursOp,
        monHoursCl,
        tusHoursOp,
        tusHoursCl,
        wedHoursOp,
        wedHoursCl,
        thuHoursOp,
        thuHoursCl,
        friHoursOp,
        friHoursCl,
        satHoursOp,
        satHoursCl,
        sunHoursOp,
        sunHoursCl,
        seoHelmentName,
        seoHelmentTitle,
        seoHelmentDescription,
        seoHelmentType,
        seoHelmentTitleVehicles,
        seoHelmentDescriptionVehicles,
        seoHelmentTypeVehicles,
        seoHelmentTitleLogin,
        seoHelmentDescriptionLogin,
        seoHelmentTypeLogin,
        seoHelmentTitleRegister,
        seoHelmentDescriptionRegister,
        seoHelmentTypeRegister,
        seoHelmentTitleFound,
        seoHelmentDescriptionFound,
        seoHelmentTypeFound,
        seoHelmentTitleUserProfile,
        seoHelmentDescriptionUserProfile,
        seoHelmentTypeUserProfile,
        seoHelmentTitleAbout,
        seoHelmentDescriptionAbout,
        seoHelmentTypeAbout,
        seoHelmentTitleContact,
        seoHelmentDescriptionContact,
        seoHelmentTypeContact,
        seoHelmentTitleGenola,
        seoHelmentDescriptionGenola,
        seoHelmentTypeGenola,
        seoHelmentTitleMapleton,
        seoHelmentDescriptionMapleton,
        seoHelmentTypeMapleton,
        seoHelmentTitleSpanishFork,
        seoHelmentDescriptionSpanishFork,
        seoHelmentTypeSpanishFork,
        seoHelmentTitlePayson,
        seoHelmentDescriptionPayson,
        seoHelmentTypePayson,
        seoHelmentTitleSalem,
        seoHelmentDescriptionSalem,
        seoHelmentTypeSalem,
        seoHelmentTitleSantaquin,
        seoHelmentDescriptionSantaquin,
        seoHelmentTypeSantaquin,
        seoHelmentTitleOrem,
        seoHelmentDescriptionOrem,
        seoHelmentTypeOrem,
        seoHelmentTitleVineyard,
        seoHelmentDescriptionVineyard,
        seoHelmentTypeVineyard,
        seoHelmentTitleElkRidge,
        seoHelmentDescriptionElkRidge,
        seoHelmentTypeElkRidge,
        seoHelmentTitleAlpine,
        seoHelmentDescriptionAlpine,
        seoHelmentTypeAlpine,
        seoHelmentTitleLehi,
        seoHelmentDescriptionLehi,
        seoHelmentTypeLehi,
        seoHelmentTitleAmericanFork,
        seoHelmentDescriptionAmericanFork,
        seoHelmentTypeAmericanFork,
        seoHelmentTitleLindon,
        seoHelmentDescriptionLindon,
        seoHelmentTypeLindon,
        seoHelmentTitleEagleMountain,
        seoHelmentDescriptionEagleMountain,
        seoHelmentTypeEagleMountain,
        seoHelmentTitleProvo,
        seoHelmentDescriptionProvo,
        seoHelmentTypeProvo,
        seoHelmentTitleBlog,
        seoHelmentDescriptionBlog,
        seoHelmentTypeBlog,
        seoHelmentTitleAuto,
        seoHelmentDescriptionAuto,
        seoHelmentTypeAuto,
        seoHelmentTitleComm,
        seoHelmentDescriptionComm,
        seoHelmentTypeComm,
        seoHelmentTitleEmerg,
        seoHelmentDescriptionEmerg,
        seoHelmentTypeEmerg,
        seoHelmentTitleResid,
        seoHelmentDescriptionResid,
        seoHelmentTypeResid,
        seoHelmentTitleAutoLock,
        seoHelmentDescriptionAutoLock,
        seoHelmentTypeAutoLock,
        seoHelmentTitleCommLock,
        seoHelmentDescriptionCommLock,
        seoHelmentTypeCommLock,
        seoHelmentTitleHomeLock,
        seoHelmentDescriptionHomeLock,
        seoHelmentTypeHomeLock,
        seoHelmentTitlePush,
        seoHelmentDescriptionPush,
        seoHelmentTitleRekey,
        seoHelmentDescriptionRekey,
        seoHelmentTitleInstall,
        seoHelmentDescriptionInstall,
        seoHelmentTitleMasterKey,
        seoHelmentDescriptionMasterKey,
        seoHelmentTitleKeyDup,
        seoHelmentDescriptionKeyDup,
        seoHelmentTitleResInstall,
        seoHelmentDescriptionResInstall,
        seoHelmentTitleResKeyExtract,
        seoHelmentDescriptionResKeyExtract,
        seoHelmentTitleResSafe,
        seoHelmentDescriptionResSafe,
        seoHelmentTitleResRekey,
        seoHelmentDescriptionResRekey,
    });
    return data;
}

const AdminEditSettingsPage = () => {

    return <EditSettingsPageComponent updateSettingsApiRequest={updateSettingsApiRequest} fetchSetting={fetchSetting} />;
};

export default AdminEditSettingsPage