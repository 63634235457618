import { Link } from "react-router-dom";
import paths from "../router/paths";
import { useEffect, useState } from "react";
import Logo from "../gallery/TransparentLogo.svg";
import { BsFacebook } from 'react-icons/bs';
import { ImGooglePlus3 } from 'react-icons/im';

const FooterComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);

    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);
    return (
        <footer className="w-100 py-4 flex-shrink-0 bg-primary">
            <div className="container-fluid py-4 bg-primary">
                <div className="row gy-4 gx-5 justify-content-center">
                    <div className="col-lg-3 col-md-4 nopadding">
                        <Link to={paths.ROOT}>
                            <img src={Logo} className="img-logo" alt={setting.seoHelmentName} />
                        </Link>
                  </div>
                    <div className="col-lg-2 col-md-2">
                        <h5 className="text-white mb-2">Quick links</h5>
                        <ul className="list-unstyled text-muted ms-3">
                            <li><Link to={paths.ROOT}>Home</Link></li>
                            <li><Link to={paths.ABOUT}>About</Link></li>
                            <li><Link to={paths.BLOG}>Blog</Link></li>
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-2">
                        <h5 className="text-white mb-2">Top Services</h5>
                        <ul className="list-unstyled text-muted ms-3">
                            <li><Link to={paths.AUTOLOCK}>Automotive Locksmith</Link></li>
                            <li><Link to={paths.EMERGLOCK}>Emergency Locksmith</Link></li>
                            <li><Link to={paths.COMMLOCK}>Commercial Locksmith</Link></li>
                            <li><Link to={paths.RESLOCK}>Residential Locksmith</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <h5 className="text-white mb-2">About Us</h5>
                        <ul className="list-unstyled text-light ms-3">
                            <li><Link to={paths.CONTACT}>Contact Us</Link></li>
                          <li>Local:<a href="tel:(801) 609-8234"> (801) 609-8234</a></li>
                          <li>Email: captainlockandkey@gmail.com</li>
                      </ul>
                  </div>
                    <div className="col-lg-2 col-md-3">
                        <h5 className="text-white mb-2">Find Us On</h5>
                        <div className="row ms-3">
                            <div className="col-sm-1 text-light icon-size-logo">
                                <a href="https://www.facebook.com/captainlockandkey/" target="_blank">
                                    <BsFacebook />
                                </a>
                            </div>
                            <div className="ms-2 col-sm-1 text-light icon-size-logo">
                                <a href="https://g.page/r/CQtsPdAwbTUPEAI/review" target="_blank">
                                    <ImGooglePlus3 />
                                 </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center border-top mt-3 pt-3 pb-2 text-light">
                        Content, including images, displayed on this website is protected by copyright laws. Downloading, republication, retransmission or reproduction of content on this website is strictly prohibited.
                       <br/> &copy; Copyrights. All rights reserved. {setting.seoHelmentName} 2023
                    </div>
                </div>
              </div>
    </footer>
  );
};

export default FooterComponent;
