import { Row, Col, Table } from "react-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { useState, useEffect } from "react";
import { BsTrash } from 'react-icons/bs';
import { Button } from "react-bootstrap";
import { GoPencil } from 'react-icons/go';
import { LinkContainer } from "react-router-bootstrap";
import paths from "../../../router/paths";
import { Link } from "react-router-dom";

const PostPageComponent = ({ fetchPosts, deletePost }) => {
    const [post, setPosts] = useState([]);
    const [postDelete, setPostsDeleted] = useState(false);

    const deleteHandler = async (postId) => {
        if (window.confirm("Are you sure?")) {
            const data = await deletePost(postId)
            if (data.message === "post removed") {
                setPostsDeleted(!postDelete);
            }
        }
    };

    useEffect(() => {
        const abctrl = new AbortController();
        fetchPosts(abctrl)
            .then((res) => setPosts(res))
            .catch((er) =>
                setPosts([
                    { name: er.response.data.message ? er.response.data.message : er.response.data }
                ]),
            );
        return () => abctrl.abort();
    }, [postDelete, fetchPosts]);
    (function () {
        'use strict';

        var TableFilter = (function () {
            var Arr = Array.prototype;
            var input;

            function onInputEvent(e) {
                input = e.target;
                var table1 = document.getElementsByClassName(input.getAttribute('data-table'));
                Arr.forEach.call(table1, function (table) {
                    Arr.forEach.call(table.tBodies, function (tbody) {
                        Arr.forEach.call(tbody.rows, filter);
                    });
                });
            }

            function filter(row) {
                var text = row.textContent.toLowerCase();
                //console.log(text);
                var val = input.value.toLowerCase();
                //console.log(val);
                row.style.display = text.indexOf(val) === -1 ? 'none' : 'table-row';
            }

            return {
                init: function () {
                    var inputs = document.getElementsByClassName('table-filter');
                    Arr.forEach.call(inputs, function (input) {
                        input.oninput = onInputEvent;
                    });
                }
            };

        })();

        TableFilter.init();
    })();
    return (
        <Row className="m-5">
            <Col md={2}>
                <AdminLinksComponent />
            </Col>
            <Col md={10}>
                <Row className="mb-3">
                    <Col xs={5}>
                        <h1>
                            Blog Post List
                        </h1>
                    </Col>
                    <Col>
                        <Link className="btn btn-outline-primary" to={paths.ADMINCREATEPOST}>Create Post</Link>
                    </Col>
                    <Col md={3}>
                        <input type="text" className="mt-2 input-group table-filter w-100" data-table="order-table" placeholder="Search Blog Posts.." />
                    </Col>
                </Row>
                <Table striped bordered hover responsive className="order-table table">
                    <thead>
                        <tr className="text-center">
                            <th>#</th>
                            <th>Title</th>
                            <th>Author</th>
                            <th>Content</th>
                            <th>Edit/Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {post.map((item, idx) => (
                            <tr key={idx}>
                                <td className="text-center">{idx + 1}</td>
                                <td className="text-center">{item.title}</td>
                                <td className="text-center">{item.author}</td>
                                <td className="text-center">{item.content}</td>
                                <td className="text-center">
                                    <LinkContainer to={`/admin/edit-post/${item.title}`}>
                                        <Button className="btn-sm" variant="outline-primary">
                                            <GoPencil />
                                        </Button>
                                    </LinkContainer>
                                    <Button
                                        variant="outline-danger"
                                        className="btn-sm ms-3"
                                        onClick={() => deleteHandler(item._id)}
                                    >
                                        <BsTrash />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default PostPageComponent;
