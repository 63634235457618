import {
    Row,
    Col,
    Container,
    Form,
    Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreatePostPageComponent = ({
    createPostApiRequest,
    uploadImagesApiRequest,
    uploadImagesCloudinaryApiRequest,
}) => {
    const [validated, setValidated] = useState(false);
    const [images, setImages] = useState(false);
    const [isCreating, setIsCreating] = useState("");
    const [createPostResponseState, setCreatePostResponseState] = useState({
        message: "",
        error: "",
    });

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const formInputs = {
            title: form.title.value,
            author: form.author.value,
            content: form.content.value,
            
        };
        if (event.currentTarget.checkValidity() === true) {
            if (images.length > 3) {
                setIsCreating("to many files");
                return;
            }
            createPostApiRequest(formInputs)
                .then((data) => {
                    if (images) {
                        if (process.env.NODE_ENV !== "production") {
                            // to do: change to !==
                            uploadImagesApiRequest(images, data.postsId)
                                .then((res) => { })
                                .catch((er) =>
                                    setIsCreating(
                                        er.response.data.message
                                            ? er.response.data.message
                                            : er.response.data
                                    )
                                );
                        } else {
                            uploadImagesCloudinaryApiRequest(images, data.postsId);
                        }
                    }
                    if (data.message === "posts created") navigate("/admin/posts");
                })
                .catch((er) => {
                    setCreatePostResponseState({
                        error: er.response.data.message
                            ? er.response.data.message
                            : er.response.data,
                    });
                });
        }

        setValidated(true);
    };

    const uploadHandler = (images) => {
        setImages(images);
    };

    const checkKeyDown = (e) => {
        if (e.code === "Enter") e.preventDefault();
    }

    return (
        <Container className="mb-5 pb-5">
            <Row className="justify-content-md-center mt-5">
                <Col md={1}>
                    <Link to="/admin/posts" className="btn btn-outline-primary my-3">
                        Go Back
                    </Link>
                </Col>
                <Col md={6}>
                    <h1>Create a new Blog Post</h1>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >
                        <Form.Group className="mb-3" controlId="formBasicTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control name="title" required type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicAuthor">
                            <Form.Label>Author</Form.Label>
                            <Form.Control name="author" required type="text" />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Content</Form.Label>
                            <Form.Control
                                name="content"
                                required
                                as="textarea"
                                rows={3}
                            />
                        </Form.Group>
                        
                        <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
                            <Form.Label>Images</Form.Label>

                            <Form.Control
                                required
                                type="file"
                                multiple
                                onChange={(e) => uploadHandler(e.target.files)}
                            />
                            {isCreating}
                        </Form.Group>
                        <div className="text-center">
                            <Button variant="outline-primary" type="submit">
                                Create
                            </Button>
                        </div>
                        {createPostResponseState.error ?? ""}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default CreatePostPageComponent;