import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import VehicleLogoGallery from "../../components/VehicleLogoGallery";
import paths from "../../router/paths";

const VehiclePageComponent = ({ fetchSetting}) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);
    
    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleVehicles}
                description={setting.seoHelmentDescriptionVehicles}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeVehicles} />
            <div className="row parentBack justify-content-center">
                <div className="col-md-6 text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Expert Automotive Locksmith Services from Captain Lock and Key Utah</h3>
                    <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Vehicles That We Service</h1>
                        </div>
                    </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                    </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <h2 className="fw-bold mt-5 mb-3 font-size-45">AUTOMOTIVE LOCKSMITH IN UTAH</h2>
                    <p className="font-size-16">
                        For fast and reliable support for your vehicle's locks, choose Captain Lock and Key Utah. Our team of experienced technicians is
                        equipped with the latest tools and technology to provide top-quality automotive locksmith services.
                    </p>
                    <p className="font-size-16">
                        Our extensive range of services includes, but is not limited to:
                    </p>
                    <ul className="font-size-16">
                        <li>
                            Car Key Duplication: We can duplicate any type of car key, including standard and transponder keys, with accuracy.
                        </li>
                        <li>
                            Transponder Key Programming: Our technicians can program your transponder key to work seamlessly with your vehicle's ignition system.
                        </li>
                        <li>
                            Ignition Repair: If your vehicle's ignition is damaged or not functioning properly, our technicians can repair it quickly and effectively.
                        </li>
                    </ul>
                    <p className="font-size-16">
                        At Captain Lock and Key Utah, we know that losing or damaging your car key can be stressful.
                        That's why we are dedicated to providing fast and friendly service, so you can get back on the road as soon as possible.
                    </p>
                    <p className="ms-5 font-size-16 pb-3">
                        <Link to={paths.ROOT}>Read More....</Link>
                    </p>
                </div>
            </div>
            <div className="row vehicleBack1 justify-content-center">
                <div className="col-md-10 text-center">
                    <h2 className="text-center mt-5">
                        Automotive Vehicles We Specialize In
                    </h2>
                    <div className="hrWidth"><hr className="hrBorder" /></div>
                    <VehicleLogoGallery />
                </div>
            </div>
            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE AUTOMOTIVE LOCKSMITH IN UTAH?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default VehiclePageComponent;
