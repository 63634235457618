import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const ProvoLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleProvo}
                description={setting.seoHelmentDescriptionProvo}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeProvo} />
            <div className="row provoBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Provo Mobile Locksmith Service</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10">
                    <h2 className="fw-bold mt-5 mb-3 font-size-18">Provo Locksmith Services from Captain Lock and Key</h2>
                    <p className="font-size-16">
                        Looking for a reliable locksmith in Provo? Look no further than Captain Lock and Key! Our team of skilled technicians is equipped with the latest
                        tools and technology to provide high-quality locksmith services for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        Our comprehensive list of services includes:
                    </p>
                    <ul className="font-size-16">
                        <li>
                            Residential Locksmith Services: We offer a complete range of residential locksmith services, including lock repair, rekeying, and installation.
                            Whether you need new locks installed or want to upgrade the security of your home, we've got you covered.
                        </li>
                        <li>
                            Commercial Locksmith Services: Our commercial locksmith services include high-security lock installation, access control systems, and master key systems.
                            We understand that the security of your business is of the utmost importance, which is why we offer customized solutions tailored to your specific needs.
                        </li>
                        <li>
                            Automotive Locksmith Services: Locked out of your car? Our expert technicians can quickly and efficiently get you back on the road with our car key duplication,
                            transponder key programming, and ignition repair services.
                        </li>
                    </ul>
                    <p className="font-size-16">
                        At Captain Lock and Key, we prioritize your security and peace of mind. That's why we strive to provide fast and friendly service, so you can trust that your locks are in good hands.
                        We are proud to serve Provo and the surrounding areas with our trusted locksmith services.
                        <br /><br />
                        Contact us today to schedule an appointment or receive a free quote for our services.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row provoBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Provo Emergency Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you find yourself in an emergency lockout situation in Provo, don't panic. Our locksmith company provides fast and reliable emergency
                                    locksmith services to help you get back to your routine quickly. Whether you're locked out of your home, car, or business, our team of skilled
                                    technicians is available 24/7 to help you out. We use the latest technology and techniques to ensure that we can handle any emergency lockout
                                    situation, including broken keys, lost keys, or keys locked inside the property. Our emergency locksmith services are affordable, fast, and
                                    reliable, and we are committed to providing the best possible customer experience. With our emergency locksmith services, you can have peace
                                    of mind knowing that help is just a phone call away. Contact us today to schedule a consultation and experience the convenience and reliability
                                    of our high-quality emergency locksmith services in Provo. We also provide other locksmith services in Provo, including residential, commercial,
                                    and automotive locksmith services, key duplication, lock repair, and much more. Trust our team of skilled professionals to provide you with
                                    top-quality locksmith services in Provo.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">House Lock Installation & Repair</h2>
                                <p className="font-size-16 text-start ms-5">
                                    At our locksmith company, we understand the importance of keeping your home safe and secure, and that's why we provide high-quality house lock
                                    installation and repair services. Our team of skilled professionals has the expertise and experience to install and repair any type of lock,
                                    including deadbolts, smart locks, and keyless entry systems. We use only the best products and techniques to ensure that your locks are functioning
                                    correctly and providing the maximum level of security for your home. Whether you need a new lock installed, or your existing lock repaired, we are
                                    committed to providing fast, reliable, and affordable services that meet your unique needs. Our house lock installation and repair services are
                                    available 24/7, and we are committed to providing the best possible customer experience. With our house lock installation and repair services,
                                    you can have peace of mind knowing that your home is protected from theft and intrusion. Contact us today to schedule a consultation and experience
                                    the convenience and reliability of our high-quality house lock installation and repair services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Provo Home Lockouts</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you're locked out of your home in Provo, our locksmith company provides fast and reliable house lockout services to help you regain access
                                    quickly. Our team of skilled professionals has the expertise and experience to handle any type of house lockout situation, whether it's a broken
                                    key, lost key, or a lock that won't open. We use the latest technology and techniques to ensure that we can get you back inside your home safely
                                    and efficiently. Our house lockout services are available 24/7, and we are committed to providing fast, reliable, and affordable services that
                                    meet your unique needs. With our house lockout services, you can have peace of mind knowing that help is just a phone call away. Contact us today
                                    to schedule a consultation and experience the convenience and reliability of our high-quality house lockout services in Provo. We also provide
                                    other locksmith services in Provo, including residential, commercial, and automotive locksmith services, key duplication, lock repair, and much
                                    more. Trust our team of skilled professionals to provide you with top-quality locksmith services in Provo.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Lockouts In Provo</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you find yourself locked out of your car in Provo, our locksmith company provides fast and reliable car lockout services to help you get back
                                    on the road quickly. Our team of skilled technicians is available 24/7 to assist you in any car lockout situation, including lost keys, broken
                                    keys, or keys locked inside the car. We use the latest technology and techniques to ensure that we can handle any type of car lockout, including
                                    high-security systems and smart key fobs. Our car lockout services are affordable, fast, and reliable, and we are committed to providing the best
                                    possible customer experience. With our car lockout services, you can have peace of mind knowing that help is just a phone call away. Contact us
                                    today to schedule a consultation and experience the convenience and reliability of our high-quality car lockout services in Provo. We also
                                    provide other automotive locksmith services in Provo, including car key replacement, transponder programming, and much more. Trust our team
                                    of skilled professionals to provide you with top-quality locksmith services in Provo.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Services In Provo</h2>
                                <p className="font-size-16 text-start ms-5">
                                    At our locksmith company in Provo, we offer a full range of automotive locksmith services to help you with any lock or key-related issue you
                                    may encounter. Our team of skilled technicians is available 24/7 to assist you with any automotive locksmith problem, including car key replacement,
                                    transponder programming, and car lockouts. We use the latest technology and techniques to ensure that we can handle any type of car lock or key,
                                    including high-security systems and smart key fobs. Our automotive locksmith services are affordable, fast, and reliable, and we are committed to
                                    providing the best possible customer experience. With our automotive locksmith services, you can have peace of mind knowing that help is just a
                                    phone call away. Contact us today to schedule a consultation and experience the convenience and reliability of our high-quality automotive locksmith
                                    services in Provo. We also provide residential and commercial locksmith services in Provo, including lock installation, repair, and rekeying.
                                    Trust our team of skilled professionals to provide you with top-quality locksmith services in Provo.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Commercial Locksmith Services In Provo</h2>
                                <p className="font-size-16 text-start ms-5">
                                    At our locksmith company in Provo, we provide comprehensive commercial locksmith services to businesses of all sizes. Our team of experienced and
                                    skilled technicians is available 24/7 to assist you with any commercial locksmith problem, including lock installation, repair, and rekeying,
                                    access control systems, and master key systems. We use the latest technology and techniques to ensure that we can handle any type of commercial
                                    lock or key, including high-security systems and smart locks. Our commercial locksmith services are designed to help you secure your business and
                                    protect your assets, with fast and reliable service to minimize any downtime. With our commercial locksmith services, you can have peace of mind
                                    knowing that your business is secure and protected. Contact us today to schedule a consultation and experience the convenience and reliability of
                                    our high-quality commercial locksmith services in Provo. We also provide automotive and residential locksmith services in Provo, including car key
                                    replacement, lockouts, and lock installation and repair for homes. Trust our team of skilled professionals to provide you with top-quality locksmith
                                    services in Provo.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default ProvoLocksmithComponent;
