import axios from "axios";
import {
    uploadImagesApiRequest,
    uploadImagesCloudinaryApiRequest,
} from "./utils/utils";
import { useDispatch } from "react-redux";
import CreatePostPageComponent from "./components/PostCreatePageComponent";

const createPostApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/posts/admin`, { ...formInputs });
    return data;
};

const AdminCreatePostPage = () => {
    const dispatch = useDispatch();

    return (
        <CreatePostPageComponent
            createPostApiRequest={createPostApiRequest}
            uploadImagesApiRequest={uploadImagesApiRequest}
            uploadImagesCloudinaryApiRequest={uploadImagesCloudinaryApiRequest}
            reduxDispatch={dispatch}
        />
    );
};

export default AdminCreatePostPage;