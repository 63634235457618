import axios from "axios";
import LindonLocksmithComponent from "./components/LindonLocksmithComponent";

const LindonLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <LindonLocksmithComponent fetchSetting={fetchSetting} />;
};

export default LindonLocksmithPage;
