import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import BlogDetailsPageComponent from "./components/BlogDetailsPageComponent";

const PostDetailsPage = () => {

    const dispatch = useDispatch()

    const getPostDetails = async (id) => {
        const { data } = await axios.get(`/api/posts/get-one/${id}`);
        return data
    }

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }
    return <BlogDetailsPageComponent fetchSetting={fetchSetting} reduxDispatch={dispatch} getPostDetails={getPostDetails} />;
};

export default PostDetailsPage;