import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const ElkRidgeLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleElkRidge}
                description={setting.seoHelmentDescriptionElkRidge}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeElkRidge} />
            <div className="row elkRidgeBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Elk Ridge City, Utah</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        Welcome to Captain Lock and Key, your trusted Elk Ridge locksmith. We provide fast and reliable locksmith services to the residents and business
                        owners in Elk Ridge and surrounding areas. Our team of experienced technicians is equipped with the latest tools and technology to provide top-quality
                        locksmith services for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        We offer an extensive list of services, including but not limited to:
                    </p>
                    <p className="font-size-16 ms-3">
                        Residential Locksmith Services: Our team offers a full range of residential locksmith services, including lock repair, rekeying, and installation.
                        Whether you're moving into a new home or need to upgrade your current security system, we can help.
                    </p>
                    <p className="font-size-16 ms-3">
                        Commercial Locksmith Services: We provide comprehensive commercial locksmith services, including high-security locks, access control systems, and master
                        key systems. Our technicians are trained to provide the best security solutions for your business.
                    </p>
                    <p className="font-size-16 ms-3">
                        Automotive Locksmith Services: We specialize in automotive locksmith services, including car key duplication, transponder key programming, and ignition
                        repair. Our technicians have the skills and expertise to get you back on the road quickly and safely.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we understand the importance of security for you and your property. That's why we provide fast and friendly service to ensure your
                        locks are in good hands. We take pride in being Elk Ridge's trusted locksmith and strive to offer peace of mind to our clients.
                    </p>
                    <p className="font-size-16">
                        Don't hesitate to contact us today to schedule an appointment or receive a free quote for our services. We are dedicated to providing quality locksmith
                        services to the residents and business owners in Elk Ridge and surrounding areas.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row elkRidgeBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you're in need of a fast and reliable automotive locksmith service, look no further than our team of experts. With years of experience,
                                    we know the ins and outs of every make and model, so you can trust us to get you back on the road in no time. Whether you're locked out
                                    of your car, lost your keys, or need a new set of locks installed, we've got you covered. Our 24/7 availability means you can count on
                                    us to be there when you need us most. Don't risk damaging your vehicle by attempting to handle the problem yourself - call us today and
                                    let us take care of everything for you!
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Lockout Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you find yourself locked out of your car, our car lockout service is just a phone call away. Our team of skilled and experienced locksmiths
                                    understands the urgency of the situation and will arrive at your location quickly, equipped with the tools necessary to get you back into your
                                    vehicle safely and efficiently. We pride ourselves on our prompt and reliable service, and our 24/7 availability ensures that you can count on
                                    us whenever you need us. We know that being locked out of your car can be a frustrating and stressful experience, but we're here to help make
                                    the process as smooth as possible. Trust us to provide a stress-free solution to your car lockout needs. Call us now and let us get you back on
                                    the road in no time.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Master Key Systems</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Looking for a convenient and secure way to manage access to your property? Our master key systems are the perfect solution. Our expert locksmiths
                                    can design and install a custom master key system that meets the unique needs of your property, whether it's a commercial building, apartment
                                    complex, or residential home. With a master key system, you can enjoy the convenience of carrying just one key while still maintaining tight
                                    security. Our master key systems are designed to ensure that only authorized individuals have access to specific areas, giving you peace of
                                    mind knowing that your property is protected. We use high-quality materials and the latest technology to create a master key system that is
                                    durable, reliable, and tailored to your specific needs. Contact us today to learn more about our master key system services and how they can
                                    benefit you.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Fresh Installation Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Are you in need of a fresh installation service for your home or business? Our expert locksmiths are here to help. Whether you're looking to
                                    upgrade your locks for added security, replace old or damaged locks, or simply want to enhance the overall aesthetic of your property, we
                                    can help. Our fresh installation service is fast, efficient, and affordable, and our team has years of experience working with a variety of
                                    lock types and brands. We use only the highest quality materials to ensure that your new locks are durable and reliable. With our fresh
                                    installation service, you can rest assured that your property is secure and that you're getting the best value for your money. Contact us
                                    today to learn more about our fresh installation services and how they can benefit you.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Transponder Key Programming</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you're in need of transponder key programming, our expert locksmiths are here to help. We understand the importance of having a fully
                                    functioning transponder key, which is why we offer fast and reliable programming services. Our team of skilled technicians is equipped with
                                    the latest technology and tools to program your transponder key on the spot, saving you time and hassle. We work with all types of vehicles
                                    and can program keys for a variety of makes and models. With our transponder key programming service, you can enjoy the peace of mind knowing
                                    that your vehicle is protected against theft and unauthorized access. Don't trust just anyone with your transponder key programming needs -
                                    call us today and let us provide you with top-quality service that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Residential Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Protecting your home and family is our top priority, which is why our residential locksmith services are designed to provide you with the best
                                    possible security solutions. Whether you're in need of a lock repair or replacement, key duplication, or a complete security system installation,
                                    our team of skilled technicians is here to help. We understand that every home is unique, which is why we offer custom solutions tailored to your
                                    specific needs and budget. We use only the highest quality materials and the latest technology to ensure that your home is protected against
                                    theft and break-ins. Our 24/7 availability means that you can count on us to be there whenever you need us, day or night. Don't leave the
                                    security of your home to chance - call us today and let us provide you with top-quality residential locksmith services that you can rely on.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default ElkRidgeLocksmithComponent;
