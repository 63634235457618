import axios from "axios";
import ProvoLocksmithComponent from "./components/ProvoLocksmithComponent";

const ProvoLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <ProvoLocksmithComponent fetchSetting={fetchSetting} />;
};

export default ProvoLocksmithPage;
