import { Row, Col } from "react-bootstrap";

const ProductCarouselComponent = () => {

    return (

        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>

            <div className="carousel-inner">
                <>
                    <div className="carouselheight carousel-item background active em"></div>
                    <div className="carouselheight carousel-item background em1"></div>
                    <div className="carouselheight carousel-item background em2"></div>
                    <div className="justify-content-center text-car">
                        <Row className="justify-content-center mb-3">
                            <Col md={12} sm={12} className="m-0 p-0 text-center">
                                <h1 className="fw-bold font-size-45">Springville Locksmith</h1>
                            </Col>
                        </Row>
                        <div className="row justify-content-center mb-3">
                            <div className="col-sm-5">
                        <h2 className="font-size-18 mb-3 text-center">Captain Lock and Key Springville specializes in providing only affordable residential, automotive,
                                commercial and emergency locksmith services to all of Springville and Utah County.</h2>
                            </div>
                            </div>

                        <h3 className="font-size-18 text-center">Contact Us Today For A Free Estimate!</h3>
                    </div>
                    <div className="container1 w-100">
                    </div>
            
                </>
            </div>
        </div>
 )};

export default ProductCarouselComponent;