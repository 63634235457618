import paths from '../router/paths';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { setChatRooms, setSocket, setMessageReceived, removeChatRoom } from "../redux/actions/chatActions";
import Logo from "../gallery/logo.svg";
import { Link } from 'react-router-dom';

function HeaderComponent() {
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.userRegisterLogin);
    
    useEffect(() => {
        if (userInfo.isAdmin) {
            var audio = new Audio("/audio/chat-msg.mp3");
            const socket = socketIOClient();
            socket.emit("admin connected with server", "Admin" + Math.floor(Math.random() * 1000000000000));
            socket.on("server sends message from client to admin", ({ user, message }) => {
                dispatch(setSocket(socket));
                //   let chatRooms = {
                //     fddf54gfgfSocketID: [{ "client": "dsfdf" }, { "client": "dsfdf" }, { "admin": "dsfdf" }],
                //   };
                dispatch(setChatRooms(user, message));
                dispatch(setMessageReceived(true));
                audio.play();
            })
            socket.on("disconnected", ({ reason, socketId }) => {
                //   console.log(socketId, reason)
                dispatch(removeChatRoom(socketId));
            })
            return () => socket.disconnect();
        }
    }, [userInfo.isAdmin, dispatch])

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary ps-5 m-0 p-0 pe-4 w-100">
            <Link className="navbar-brand" to={paths.ROOT}><img src={Logo} className="img-logo1 m-0 p-0" alt="Captain Lock and Key" /></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ms-auto text-light">
                    <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Services
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item dropstart">
                                <Link className="font-size-nav nav-link text-dark" to={paths.COMMLOCK}>
                                    Commercial Locksmith
                                </Link>
                                <ul className="dropdown-menu font-size-nav">
                                    <li><Link className="dropdown-item" to={paths.PUSHBAR}>Push Bar Services</Link></li>
                                    <li><Link className="dropdown-item" to={paths.COMMREKEY}>Commercial Rekey Services</Link></li>
                                    <li><Link className="dropdown-item" to={paths.COMMLOCKINSTALL}>Lock Installation and Repair</Link></li>
                                    <li><Link className="dropdown-item" to={paths.COMMMASTERKEY}>Master Key Service</Link></li>
                                    <li><Link className="dropdown-item" to={paths.COMMKEYDUP}>Key Duplication</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropstart">
                                <Link className="font-size-nav nav-link text-dark" to={paths.RESLOCK}>
                                    Residential Locksmith
                                </Link>
                                <ul className="dropdown-menu font-size-nav">
                                    <li><Link className="dropdown-item" to={paths.RESLOCKINSTALL}>Lock Installation</Link></li>
                                    <li><Link className="dropdown-item" to={paths.RESKEYEXTRACT}>Residential Key Extraction</Link></li>
                                    <li><Link className="dropdown-item" to={paths.RESSAFE}>Safe Install & Open</Link></li>
                                    <li><Link className="dropdown-item" to={paths.RESREKEY}>Residential Rekey</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropstart">
                                <Link className="font-size-nav nav-link text-dark" to={paths.AUTOLOCK}>
                                    Automotive Locksmith
                                </Link>
                               
                            </li>
                            <li className="nav-item dropstart">
                                <Link className="font-size-nav nav-link text-dark" to={paths.EMERGLOCK}>
                                    Emergency Locksmith
                                </Link>
                                <ul className="dropdown-menu font-size-nav">
                                    <li><Link className="dropdown-item" to={paths.BUSINESSLOCK}>Business Lockout</Link></li>
                                    <li><Link className="dropdown-item" to={paths.HOUSELOCK}>House Lockout</Link></li>
                                    <li><Link className="dropdown-item" to={paths.VEHICLELOCK}>Vehicle Lockout</Link></li>
                                 </ul>
                            </li>
                        </ul>
                     </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle text-light" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Service Areas
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link className="dropdown-item" to={paths.GENOLA}>Genola Locksmith</Link>
                            <Link className="dropdown-item" to={paths.MAPLETON}>Mapleton Locksmith</Link>
                            <Link className="dropdown-item" to={paths.SPANISHFORK}>Spanish Fork Locksmith</Link>
                            <Link className="dropdown-item" to={paths.PAYSON}>Payson Locksmith</Link>
                            <Link className="dropdown-item" to={paths.SALEM}>Salem Locksmith</Link>
                            <Link className="dropdown-item" to={paths.SANTAQUIN}>Santaquin Locksmith</Link>
                            <Link className="dropdown-item" to={paths.OREM}>Orem Locksmith</Link>
                            <Link className="dropdown-item" to={paths.VINEYARD}>Vineyard Locksmith</Link>
                            <Link className="dropdown-item" to={paths.ELKRIDGE}>Elk Ridge Locksmith</Link>
                            <Link className="dropdown-item" to={paths.ALPINE}>Alpine Locksmith</Link>
                            <Link className="dropdown-item" to={paths.LEHI}>Lehi Locksmith</Link>
                            <Link className="dropdown-item" to={paths.AMERICANFORK}>American Fork Locksmith</Link>
                            <Link className="dropdown-item" to={paths.LINDON}>Lindon Locksmith</Link>
                            <Link className="dropdown-item" to={paths.EAGLEMOUNTAIN}>Eagle Mountain Locksmith</Link>
                            <Link className="dropdown-item" to={paths.PROVO}>Provo Locksmith</Link>

                        </div>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-light" to={paths.VEHICLES}>Vehicles</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-light" to={paths.BLOG}>Blog</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-light" to={paths.ABOUT}>About</Link>
                    </li>
                    <li className="nav-item me-3">
                        <Link className="nav-link text-light" to={paths.CONTACT}>Contact</Link>
                    </li>
                    
                </ul>
            </div>
        </nav>
    );
}

export default HeaderComponent;