import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const LehiLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleLehi}
                description={setting.seoHelmentDescriptionLehi}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeLehi} />
            <div className="row lehiBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Mobile Lehi Locksmith</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10">
                    <h2 className="fw-bold mt-5 mb-3 font-size-18">Lehi Locksmith - Trusted Locksmith Services from Captain Lock and Key</h2>
                    <p className="font-size-16">
                        When it comes to locksmith services in Lehi, Utah, Captain Lock and Key is your go-to source for reliable, fast, and professional service.
                        Our team of experienced technicians is equipped with the latest tools and technology to provide top-quality locksmith services for your home,
                        business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        We understand the importance of security for you and your property, and that's why we offer a full range of services to ensure that your locks are
                        functioning properly. Our services include, but are not limited to:
                     </p>
                    <p className="font-size-16 ms-3">
                        Residential Locksmith Services: Whether you need a lock repair, rekeying, or installation, our team can assist you with all of your residential
                        locksmith needs.
                    </p>
                    <p className="font-size-16 ms-3">
                        Commercial Locksmith Services: We offer comprehensive locksmith services for businesses, including high-security locks, access control systems, and
                        master key systems to ensure the safety and security of your employees and assets.
                    </p>
                    <p className="font-size-16 ms-3">
                        Automotive Locksmith Services: Our technicians are experts in car key duplication, transponder key programming, and ignition repair. We can get you back
                        on the road quickly and safely.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we are proud to be Lehi's trusted locksmith. We understand the urgency that comes with a lockout or other emergency situations,
                        which is why we offer fast and friendly service around the clock. Our technicians are available 24/7 to assist you with any locksmith needs you may have.
                    </p>
                    <p className="font-size-16">
                        We stand by the quality of our work and offer a satisfaction guarantee on all of our services. Contact us today to schedule an appointment or to receive a
                        free quote for our services. Trust Captain Lock and Key for all your locksmith needs in Lehi, Utah.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row lehiBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    At our automotive locksmith service, we understand how frustrating it can be to find yourself locked out of your vehicle or to lose your
                                    keys. That's why we offer 24/7 emergency services to get you back on the road as quickly as possible. Our team of experienced technicians
                                    is equipped with the latest tools and technology to handle any automotive lock and key issue, from programming transponder keys to
                                    unlocking high-security vehicles. We pride ourselves on our fast response times, professional service, and affordable pricing.
                                    Whether you need a new set of keys or your ignition repaired, we're here to provide you with the highest level of quality and customer
                                    service. Don't let an automotive lock or key issue ruin your day - call us today and let us provide you with top-quality automotive
                                    locksmith services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Master Key Systems</h2>
                                <p className="font-size-16 text-start ms-5">
                                    A master key system is an effective and convenient solution for businesses and homeowners who want to increase their property's security while
                                    maintaining control over access to different areas. With our custom-designed master key systems, you can easily grant or restrict access to
                                    different areas of your property, eliminating the need for multiple keys. Our team of skilled technicians will work with you to design and
                                    install a master key system that meets your unique needs and budget. We use only the highest quality materials and the latest technology to
                                    ensure that your property is protected against theft and break-ins. Our 24/7 availability means that you can count on us to be there whenever
                                    you need us, day or night. Don't leave your property's security to chance - call us today and let us provide you with top-quality master key
                                    system services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car lockouts</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Being locked out of your car can be a frustrating and stressful experience, especially if you're in a hurry or it's late at night.
                                    That's why our car lockout services are designed to provide you with a quick and stress-free solution. Our experienced technicians
                                    have the tools and knowledge to unlock any type of vehicle, whether you have a traditional lock or a high-security one. We pride ourselves
                                    on our fast response times and professional service, ensuring that you get back on the road as quickly as possible. Our 24/7 availability
                                    means that you can count on us to be there whenever you need us, day or night. Don't let a car lockout ruin your day - call us today and
                                    let us provide you with top-quality car lockout services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Commercial Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    As a business owner, the security of your property and assets is of utmost importance. That's why our commercial locksmith services are
                                    designed to provide you with peace of mind knowing that your property is protected against theft and break-ins. Our experienced technicians
                                    have the knowledge and expertise to install and repair a variety of commercial locks, from high-security ones to master key systems. We
                                    use only the highest quality materials and the latest technology to ensure that your property is secure and that you have complete control
                                    over access to different areas. Our 24/7 availability means that you can count on us to be there whenever you need us, day or night. Don't
                                    leave your business's security to chance - call us today and let us provide you with top-quality commercial locksmith services that you
                                    can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Vehicle Transponder Programming</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Vehicle transponder programming is an essential service for anyone who wants to ensure the security of their vehicle. At our locksmith service,
                                    we have a team of skilled technicians who specialize in vehicle transponder programming for all makes and models. With our state-of-the-art
                                    technology and years of experience, we can quickly and efficiently program a new transponder key for your vehicle, ensuring that only you
                                    have access to it. We pride ourselves on our fast response times, professional service, and affordable pricing, making it easier than ever
                                    to get back on the road without breaking the bank. Our 24/7 availability means that you can count on us to be there whenever you need us,
                                    day or night. Don't let a lost or damaged transponder key ruin your day - call us today and let us provide you with top-quality vehicle
                                    transponder programming services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Fresh Installation Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Fresh installation service is a must-have for anyone who wants to improve the security of their property. At our locksmith service, we offer
                                    custom fresh installation services for all types of locks, including deadbolts, padlocks, and more. Our experienced technicians will work with
                                    you to design and install a lock system that meets your unique needs and budget. We use only the highest quality materials and the latest
                                    technology to ensure that your property is protected against theft and break-ins. Our fast response times and professional service ensure
                                    that you get the security you need when you need it, without any hassle. Our 24/7 availability means that you can count on us to be there
                                    whenever you need us, day or night. Don't leave your property's security to chance - call us today and let us provide you with top-quality
                                    fresh installation services that you can rely on.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default LehiLocksmithComponent;
