import { Row, Col, Container, Form, Button, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";

const EditPageSettingsmponent = ({ updateSettingsApiRequest, fetchSetting }) => {
    const [validated, setValidated] = useState(false);
    const [setting, setSetting] = useState([]);
    const [updateSettingsResponseState, setUpdateSettingsResponseState] = useState({ message: "", error: "" });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchSetting(id)
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [id, fetchSetting]);
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget.elements;
        const tax = form.tax.value;
        const shipping = form.shipping.value;
        const monHoursOp = form.monHoursOp.value;
        const monHoursCl = form.monHoursCl.value;
        const tusHoursOp = form.tusHoursOp.value;
        const tusHoursCl = form.tusHoursCl.value;
        const wedHoursOp = form.wedHoursOp.value;
        const wedHoursCl = form.wedHoursCl.value;
        const thuHoursOp = form.thuHoursOp.value;
        const thuHoursCl = form.thuHoursCl.value;
        const friHoursOp = form.friHoursOp.value;
        const friHoursCl = form.friHoursCl.value;
        const satHoursOp = form.satHoursOp.value;
        const satHoursCl = form.satHoursCl.value;
        const sunHoursOp = form.sunHoursOp.value;
        const sunHoursCl = form.sunHoursCl.value;
        const seoHelmentName = form.seoHelmentName.value;
        const seoHelmentTitle = form.seoHelmentTitle.value;
        const seoHelmentDescription = form.seoHelmentDescription.value;
        const seoHelmentType = form.seoHelmentType.value;
        const seoHelmentTitleVehicles = form.seoHelmentTitleVehicles.value;
        const seoHelmentDescriptionVehicles = form.seoHelmentDescriptionVehicles.value;
        const seoHelmentTypeVehicles = form.seoHelmentTypeVehicles.value;
        const seoHelmentTitleLogin = form.seoHelmentTitleLogin.value;
        const seoHelmentDescriptionLogin = form.seoHelmentDescriptionLogin.value;
        const seoHelmentTypeLogin = form.seoHelmentTypeLogin.value;
        const seoHelmentTitleRegister = form.seoHelmentTitleRegister.value;
        const seoHelmentDescriptionRegister = form.seoHelmentDescriptionRegister.value;
        const seoHelmentTypeRegister = form.seoHelmentTypeRegister.value;
        const seoHelmentTitleFound = form.seoHelmentTitleFound.value;
        const seoHelmentDescriptionFound = form.seoHelmentDescriptionFound.value;
        const seoHelmentTypeFound = form.seoHelmentTypeFound.value;
        const seoHelmentTitleUserProfile = form.seoHelmentTitleUserProfile.value;
        const seoHelmentDescriptionUserProfile = form.seoHelmentDescriptionUserProfile.value;
        const seoHelmentTypeUserProfile = form.seoHelmentTypeUserProfile.value;
        const seoHelmentTitleAbout = form.seoHelmentTitleAbout.value;
        const seoHelmentDescriptionAbout = form.seoHelmentDescriptionAbout.value;
        const seoHelmentTypeAbout = form.seoHelmentTypeAbout.value;
        const seoHelmentTitleContact = form.seoHelmentTitleContact.value;
        const seoHelmentDescriptionContact = form.seoHelmentDescriptionContact.value;
        const seoHelmentTypeContact = form.seoHelmentTypeContact.value;
        const seoHelmentTitleGenola = form.seoHelmentTitleGenola.value;
        const seoHelmentDescriptionGenola = form.seoHelmentDescriptionGenola.value;
        const seoHelmentTypeGenola = form.seoHelmentTypeGenola.value;
        const seoHelmentTitleMapleton = form.seoHelmentTitleMapleton.value;
        const seoHelmentDescriptionMapleton = form.seoHelmentDescriptionMapleton.value;
        const seoHelmentTypeMapleton = form.seoHelmentTypeMapleton.value;
        const seoHelmentTitleSpanishFork = form.seoHelmentTitleSpanishFork.value;
        const seoHelmentDescriptionSpanishFork = form.seoHelmentDescriptionSpanishFork.value;
        const seoHelmentTypeSpanishFork = form.seoHelmentTypeSpanishFork.value;
        const seoHelmentTitlePayson = form.seoHelmentTitlePayson.value;
        const seoHelmentDescriptionPayson = form.seoHelmentDescriptionPayson.value;
        const seoHelmentTypePayson = form.seoHelmentTypePayson.value;
        const seoHelmentTitleSalem = form.seoHelmentTitleSalem.value;
        const seoHelmentDescriptionSalem = form.seoHelmentDescriptionSalem.value;
        const seoHelmentTypeSalem = form.seoHelmentTypeSalem.value;
        const seoHelmentTitleSantaquin = form.seoHelmentTitleSantaquin.value;
        const seoHelmentDescriptionSantaquin = form.seoHelmentDescriptionSantaquin.value;
        const seoHelmentTypeSantaquin = form.seoHelmentTypeSantaquin.value;
        const seoHelmentTitleOrem = form.seoHelmentTitleOrem.value;
        const seoHelmentDescriptionOrem = form.seoHelmentDescriptionOrem.value;
        const seoHelmentTypeOrem = form.seoHelmentTypeOrem.value;
        const seoHelmentTitleVineyard = form.seoHelmentTitleVineyard.value;
        const seoHelmentDescriptionVineyard = form.seoHelmentDescriptionVineyard.value;
        const seoHelmentTypeVineyard = form.seoHelmentTypeVineyard.value;
        const seoHelmentTitleElkRidge = form.seoHelmentTitleElkRidge.value;
        const seoHelmentDescriptionElkRidge = form.seoHelmentDescriptionElkRidge.value;
        const seoHelmentTypeElkRidge = form.seoHelmentTypeElkRidge.value;
        const seoHelmentTitleAlpine = form.seoHelmentTitleAlpine.value;
        const seoHelmentDescriptionAlpine = form.seoHelmentDescriptionAlpine.value;
        const seoHelmentTypeAlpine = form.seoHelmentTypeAlpine.value;
        const seoHelmentTitleLehi = form.seoHelmentTitleLehi.value;
        const seoHelmentDescriptionLehi = form.seoHelmentDescriptionLehi.value;
        const seoHelmentTypeLehi = form.seoHelmentTypeLehi.value;
        const seoHelmentTitleAmericanFork = form.seoHelmentTitleAmericanFork.value;
        const seoHelmentDescriptionAmericanFork = form.seoHelmentDescriptionAmericanFork.value;
        const seoHelmentTypeAmericanFork = form.seoHelmentTypeAmericanFork.value;
        const seoHelmentTitleLindon = form.seoHelmentTitleLindon.value;
        const seoHelmentDescriptionLindon = form.seoHelmentDescriptionLindon.value;
        const seoHelmentTypeLindon = form.seoHelmentTypeLindon.value;
        const seoHelmentTitleEagleMountain = form.seoHelmentTitleEagleMountain.value;
        const seoHelmentDescriptionEagleMountain = form.seoHelmentDescriptionEagleMountain.value;
        const seoHelmentTypeEagleMountain = form.seoHelmentTypeEagleMountain.value;
        const seoHelmentTitleProvo = form.seoHelmentTitleProvo.value;
        const seoHelmentDescriptionProvo = form.seoHelmentDescriptionProvo.value;
        const seoHelmentTypeProvo = form.seoHelmentTypeProvo.value;
        const seoHelmentTitleBlog = form.seoHelmentTitleBlog.value;
        const seoHelmentDescriptionBlog = form.seoHelmentDescriptionBlog.value;
        const seoHelmentTypeBlog = form.seoHelmentTypeBlog.value;
        const seoHelmentTitleAuto = form.seoHelmentTitleAuto.value;
        const seoHelmentDescriptionAuto = form.seoHelmentDescriptionAuto.value;
        const seoHelmentTypeAuto = form.seoHelmentTypeAuto.value;
        const seoHelmentTitleComm = form.seoHelmentTitleComm.value;
        const seoHelmentDescriptionComm = form.seoHelmentDescriptionComm.value;
        const seoHelmentTypeComm = form.seoHelmentTypeComm.value;
        const seoHelmentTitleEmerg = form.seoHelmentTitleEmerg.value;
        const seoHelmentDescriptionEmerg = form.seoHelmentDescriptionEmerg.value;
        const seoHelmentTypeEmerg = form.seoHelmentTypeEmerg.value;
        const seoHelmentTitleResid = form.seoHelmentTitleResid.value;
        const seoHelmentDescriptionResid = form.seoHelmentDescriptionResid.value;
        const seoHelmentTypeResid = form.seoHelmentTypeResid.value;
        const seoHelmentTitleAutoLock = form.seoHelmentTitleAutoLock.value;
        const seoHelmentDescriptionAutoLock = form.seoHelmentDescriptionAutoLock.value;
        const seoHelmentTypeAutoLock = form.seoHelmentTypeAutoLock.value;
        const seoHelmentTitleCommLock = form.seoHelmentTitleCommLock.value;
        const seoHelmentDescriptionCommLock = form.seoHelmentDescriptionCommLock.value;
        const seoHelmentTypeCommLock = form.seoHelmentTypeCommLock.value;
        const seoHelmentTitleHomeLock = form.seoHelmentTitleHomeLock.value;
        const seoHelmentDescriptionHomeLock = form.seoHelmentDescriptionHomeLock.value;
        const seoHelmentTypeHomeLock = form.seoHelmentTypeHomeLock.value;
        const seoHelmentTitlePush = form.seoHelmentTitlePush.value;
        const seoHelmentDescriptionPush = form.seoHelmentDescriptionPush.value;
        const seoHelmentTitleRekey = form.seoHelmentTitleRekey.value;
        const seoHelmentDescriptionRekey = form.seoHelmentDescriptionRekey.value;
        const seoHelmentTitleInstall = form.seoHelmentTitleInstall.value;
        const seoHelmentDescriptionInstall = form.seoHelmentDescriptionInstall.value;
        const seoHelmentTitleMasterKey = form.seoHelmentTitleMasterKey.value;
        const seoHelmentDescriptionMasterKey = form.seoHelmentDescriptionMasterKey.value;
        const seoHelmentTitleKeyDup = form.seoHelmentTitleKeyDup.value;
        const seoHelmentDescriptionKeyDup = form.seoHelmentDescriptionKeyDup.value;

        const seoHelmentTitleResInstall = form.seoHelmentTitleResInstall.value;
        const seoHelmentDescriptionResInstall = form.seoHelmentDescriptionResInstall.value;

        const seoHelmentTitleResKeyExtract = form.seoHelmentTitleResKeyExtract.value;
        const seoHelmentDescriptionResKeyExtract = form.seoHelmentDescriptionResKeyExtract.value;

        const seoHelmentTitleResSafe = form.seoHelmentTitleResSafe.value;
        const seoHelmentDescriptionResSafe = form.seoHelmentDescriptionResSafe.value;

        const seoHelmentTitleResRekey = form.seoHelmentTitleResRekey.value;
        const seoHelmentDescriptionResRekey = form.seoHelmentDescriptionResRekey.value;

        if (event.currentTarget.checkValidity() === true) {
            updateSettingsApiRequest(
                id,
                tax,
                shipping,
                monHoursOp,
                monHoursCl,
                tusHoursOp,
                tusHoursCl,
                wedHoursOp,
                wedHoursCl,
                thuHoursOp,
                thuHoursCl,
                friHoursOp,
                friHoursCl,
                satHoursOp,
                satHoursCl,
                sunHoursOp,
                sunHoursCl,
                seoHelmentName,
                seoHelmentTitle,
                seoHelmentDescription,
                seoHelmentType,
                seoHelmentTitleVehicles,
                seoHelmentDescriptionVehicles,
                seoHelmentTypeVehicles,
                seoHelmentTitleLogin,
                seoHelmentDescriptionLogin,
                seoHelmentTypeLogin,
                seoHelmentTitleRegister,
                seoHelmentDescriptionRegister,
                seoHelmentTypeRegister,
                seoHelmentTitleFound,
                seoHelmentDescriptionFound,
                seoHelmentTypeFound,
                seoHelmentTitleUserProfile,
                seoHelmentDescriptionUserProfile,
                seoHelmentTypeUserProfile,
                seoHelmentTitleAbout,
                seoHelmentDescriptionAbout,
                seoHelmentTypeAbout,
                seoHelmentTitleContact,
                seoHelmentDescriptionContact,
                seoHelmentTypeContact,
                seoHelmentTitleGenola,
                seoHelmentDescriptionGenola,
                seoHelmentTypeGenola,
                seoHelmentTitleMapleton,
                seoHelmentDescriptionMapleton,
                seoHelmentTypeMapleton,
                seoHelmentTitleSpanishFork,
                seoHelmentDescriptionSpanishFork,
                seoHelmentTypeSpanishFork,
                seoHelmentTitlePayson,
                seoHelmentDescriptionPayson,
                seoHelmentTypePayson,
                seoHelmentTitleSalem,
                seoHelmentDescriptionSalem,
                seoHelmentTypeSalem,
                seoHelmentTitleSantaquin,
                seoHelmentDescriptionSantaquin,
                seoHelmentTypeSantaquin,
                seoHelmentTitleOrem,
                seoHelmentDescriptionOrem,
                seoHelmentTypeOrem,
                seoHelmentTitleVineyard,
                seoHelmentDescriptionVineyard,
                seoHelmentTypeVineyard,
                seoHelmentTitleElkRidge,
                seoHelmentDescriptionElkRidge,
                seoHelmentTypeElkRidge,
                seoHelmentTitleAlpine,
                seoHelmentDescriptionAlpine,
                seoHelmentTypeAlpine,
                seoHelmentTitleLehi,
                seoHelmentDescriptionLehi,
                seoHelmentTypeLehi,
                seoHelmentTitleAmericanFork,
                seoHelmentDescriptionAmericanFork,
                seoHelmentTypeAmericanFork,
                seoHelmentTitleLindon,
                seoHelmentDescriptionLindon,
                seoHelmentTypeLindon,
                seoHelmentTitleEagleMountain,
                seoHelmentDescriptionEagleMountain,
                seoHelmentTypeEagleMountain,
                seoHelmentTitleProvo,
                seoHelmentDescriptionProvo,
                seoHelmentTypeProvo,
                seoHelmentTitleBlog,
                seoHelmentDescriptionBlog,
                seoHelmentTypeBlog,
                seoHelmentTitleAuto,
                seoHelmentDescriptionAuto,
                seoHelmentTypeAuto,
                seoHelmentTitleComm,
                seoHelmentDescriptionComm,
                seoHelmentTypeComm,
                seoHelmentTitleEmerg,
                seoHelmentDescriptionEmerg,
                seoHelmentTypeEmerg,
                seoHelmentTitleResid,
                seoHelmentDescriptionResid,
                seoHelmentTypeResid,
                seoHelmentTitleAutoLock,
                seoHelmentDescriptionAutoLock,
                seoHelmentTypeAutoLock,
                seoHelmentTitleCommLock,
                seoHelmentDescriptionCommLock,
                seoHelmentTypeCommLock,
                seoHelmentTitleHomeLock,
                seoHelmentDescriptionHomeLock,
                seoHelmentTypeHomeLock,
                seoHelmentTitlePush,
                seoHelmentDescriptionPush,
                seoHelmentTitleRekey,
                seoHelmentDescriptionRekey,
                seoHelmentTitleInstall,
                seoHelmentDescriptionInstall,
                seoHelmentTitleMasterKey,
                seoHelmentDescriptionMasterKey,
                seoHelmentTitleKeyDup,
                seoHelmentDescriptionKeyDup,
                seoHelmentTitleResInstall,
                seoHelmentDescriptionResInstall,
                seoHelmentTitleResKeyExtract,
                seoHelmentDescriptionResKeyExtract,
                seoHelmentTitleResSafe,
                seoHelmentDescriptionResSafe,
                seoHelmentTitleResRekey,
                seoHelmentDescriptionResRekey,
            )
                .then(data => {
                   
                        navigate("/admin/users");
                    
                })
                .catch(er => {
                    setUpdateSettingsResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
                })
        }

        setValidated(true);
    };
  

    return (
        <Container fluid className="mb-5 pb-5 mt-5">
            
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    
                    <Col className="ms-5 me-5" md={2}>
                        <AdminLinksComponent />
                    </Col>
                    <Col md={4}>
                            <h1 className="mb-5">Edit Settings</h1>
                        <h5>Seo Settings</h5>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentName">
                            <Form.Label>Business Name:</Form.Label>
                            <Form.Control
                                name="seoHelmentName"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentName}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitle">
                            <Form.Label>Homepage Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitle"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitle}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescription">
                            <Form.Label>Homepage Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescription"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescription}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentType">
                            <Form.Label>Homepage type:</Form.Label>
                            <Form.Control
                                name="seoHelmentType"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentType}
                            />
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleVehicles">
                            <Form.Label>Vehicles Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleVehicles"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleVehicles}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionVehicles">
                            <Form.Label>Vehicle Page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionVehicles"
                                required
                                type="text"
                                as="textarea"
                                defaultValue={setting.seoHelmentDescriptionVehicles}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeVehicles">
                            <Form.Label>Vehicle Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeVehicles"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeVehicles}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleLogin">
                            <Form.Label>Login-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleLogin"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleLogin}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionLogin">
                            <Form.Label>Login-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionLogin"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionLogin}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeLogin">
                            <Form.Label>Login-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeLogin"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeLogin}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleRegister">
                            <Form.Label>Register-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleRegister"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleRegister}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionRegister">
                            <Form.Label>Register-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionRegister"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionRegister}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeRegister">
                            <Form.Label>Register-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeRegister"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeRegister}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleFound">
                            <Form.Label>404-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleFound"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleFound}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionFound">
                            <Form.Label>404-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionFound"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionFound}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeFound">
                            <Form.Label>404-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeFound"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeFound}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleUserProfile">
                            <Form.Label>User-Profile-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleUserProfile"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleUserProfile}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionUserProfile">
                            <Form.Label>User-Profile-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionUserProfile"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionUserProfile}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeUserProfile">
                            <Form.Label>User-Profile-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeUserProfile"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeUserProfile}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleAbout">
                            <Form.Label>About-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleAbout"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleAbout}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionAbout">
                            <Form.Label>About-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionAbout"
                                required
                                type="text"
                                as="textarea"
                                defaultValue={setting.seoHelmentDescriptionAbout}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeAbout">
                            <Form.Label>About-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeAbout"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeAbout}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleContact">
                            <Form.Label>Contact-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleContact"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleContact}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionContact">
                            <Form.Label>Contact-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionContact"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionContact}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeContact">
                            <Form.Label>Contact-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeContact"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeContact}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleGenola">
                            <Form.Label>Genola-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleGenola"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleGenola}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionGenola">
                            <Form.Label>Genola-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionGenola"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionGenola}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeGenola">
                            <Form.Label>Genola-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeGenola"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeGenola}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleMapleton">
                            <Form.Label>Mapleton-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleMapleton"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleMapleton}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionMapleton">
                            <Form.Label>Mapleton-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionMapleton"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionMapleton}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeMapleton">
                            <Form.Label>Mapleton-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeMapleton"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeMapleton}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleSpanishFork">
                            <Form.Label>Spanish-Fork-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleSpanishFork"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleSpanishFork}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionSpanishFork">
                            <Form.Label>Spanish-Fork-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionSpanishFork"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionSpanishFork}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeSpanishFork">
                            <Form.Label>Spanish-Fork-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeSpanishFork"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeSpanishFork}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitlePayson">
                            <Form.Label>Payson-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitlePayson"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitlePayson}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionPayson">
                            <Form.Label>Payson-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionPayson"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionPayson}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypePayson">
                            <Form.Label>Payson-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypePayson"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypePayson}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleSalem">
                            <Form.Label>Salem-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleSalem"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleSalem}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionSalem">
                            <Form.Label>Salem-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionSalem"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionSalem}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeSalem">
                            <Form.Label>Salem-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeSalem"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeSalem}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleSantaquin">
                            <Form.Label>Santaquin-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleSantaquin"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleSantaquin}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionSantaquin">
                            <Form.Label>Santaquin-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionSantaquin"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionSantaquin}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeSantaquin">
                            <Form.Label>Santaquin-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeSantaquin"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeSantaquin}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleOrem">
                            <Form.Label>Orem-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleOrem"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleOrem}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionOrem">
                            <Form.Label>Orem-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionOrem"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionOrem}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeOrem">
                            <Form.Label>Orem-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeOrem"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeOrem}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleVineyard">
                            <Form.Label>Vineyard-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleVineyard"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleVineyard}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionVineyard">
                            <Form.Label>Vineyard-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionVineyard"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionVineyard}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeVineyard">
                            <Form.Label>Vineyard-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeVineyard"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeVineyard}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleElkRidge">
                            <Form.Label>Elk-Ridge-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleElkRidge"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleElkRidge}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionElkRidge">
                            <Form.Label>Elk-Ridge-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionElkRidge"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionElkRidge}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeElkRidge">
                            <Form.Label>Elk-Ridge-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeElkRidge"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeElkRidge}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleAlpine">
                            <Form.Label>Alpine-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleAlpine"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleAlpine}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionAlpine">
                            <Form.Label>Alpine-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionAlpine"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionAlpine}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeAlpine">
                            <Form.Label>Alpine-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeAlpine"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeAlpine}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleLehi">
                            <Form.Label>Lehi-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleLehi"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleLehi}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionLehi">
                            <Form.Label>Lehi-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionLehi"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionLehi}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeLehi">
                            <Form.Label>Lehi-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeLehi"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeLehi}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleAmericanFork">
                            <Form.Label>American-Fork-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleAmericanFork"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleAmericanFork}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionAmericanFork">
                            <Form.Label>American-Fork-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionAmericanFork"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionAmericanFork}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeAmericanFork">
                            <Form.Label>American-Fork-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeAmericanFork"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeAmericanFork}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleLindon">
                            <Form.Label>Lindon-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleLindon"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleLindon}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionLindon">
                            <Form.Label>Lindon-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionLindon"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionLindon}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeLindon">
                            <Form.Label>Lindon-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeLindon"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeLindon}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleEagleMountain">
                            <Form.Label>Eagle-Mountain-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleEagleMountain"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleEagleMountain}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionEagleMountain">
                            <Form.Label>Eagle-Mountain-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionEagleMountain"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionEagleMountain}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeEagleMountain">
                            <Form.Label>Eagle-Mountain-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeEagleMountain"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeEagleMountain}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleProvo">
                            <Form.Label>Provo-Locksmith-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleProvo"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleProvo}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionProvo">
                            <Form.Label>Provo-Locksmith-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionProvo"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionProvo}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeProvo">
                            <Form.Label>Provo-Locksmith-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeProvo"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeProvo}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleBlog">
                            <Form.Label>Blog-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleBlog"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleBlog}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionBlog">
                            <Form.Label>Blog-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionBlog"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionBlog}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeBlog">
                            <Form.Label>Blog-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeBlog"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeBlog}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleAbout">
                            <Form.Label>About-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleAbout"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleAbout}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionAbout">
                            <Form.Label>About-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionAbout"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionAbout}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeAbout">
                            <Form.Label>About-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeAbout"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeAbout}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleAuto">
                            <Form.Label>Automotive-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleAuto"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleAuto}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionAuto">
                            <Form.Label>Automotive-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionAuto"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionAuto}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeAuto">
                            <Form.Label>Automotive-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeAuto"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeAuto}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleComm">
                            <Form.Label>Commercial-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleComm"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleComm}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionComm">
                            <Form.Label>Commercial-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionComm"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionComm}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeComm">
                            <Form.Label>Commercial-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeComm"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeComm}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleEmerg">
                            <Form.Label>Emergency-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleEmerg"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleEmerg}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionEmerg">
                            <Form.Label>Emergency-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionEmerg"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionEmerg}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeEmerg">
                            <Form.Label>Emergency-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeEmerg"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeEmerg}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleResid">
                            <Form.Label>Residential-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleResid"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleResid}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionResid">
                            <Form.Label>Residential-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionResid"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionResid}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeResid">
                            <Form.Label>Residential-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeResid"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeResid}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleAutoLock">
                            <Form.Label>Vehicle-Lockout-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleAutoLock"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleAutoLock}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionAutoLock">
                            <Form.Label>Vehicle-Lockout-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionAutoLock"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionAutoLock}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeAutoLock">
                            <Form.Label>Vehicle-Lockout-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeAutoLock"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeAutoLock}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleCommLock">
                            <Form.Label>Commercial-Lockout-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleCommLock"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleCommLock}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionCommLock">
                            <Form.Label>Commercial-Lockout-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionCommLock"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionCommLock}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeCommLock">
                            <Form.Label>Commercial-Lockout-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeCommLock"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeCommLock}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleHomeLock">
                            <Form.Label>Home-Lockout-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleHomeLock"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleHomeLock}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionHomeLock">
                            <Form.Label>Home-Lockout-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionHomeLock"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionHomeLock}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTypeHomeLock">
                            <Form.Label>Home-Lockout-Page type:</Form.Label>
                            <Form.Control
                                name="seoHelmentTypeHomeLock"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTypeHomeLock}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitlePush">
                            <Form.Label>Push-Bar-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitlePush"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitlePush}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionPush">
                            <Form.Label>Push-Bar-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionPush"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionPush}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleRekey">
                            <Form.Label>Commercial-Rekey-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleRekey"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleRekey}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionRekey">
                            <Form.Label>Commercial-Rekey-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionRekey"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionRekey}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleInstall">
                            <Form.Label>Commercial-Install-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleInstall"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleInstall}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionInstall">
                            <Form.Label>Commercial-Install-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionInstall"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionInstall}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleMasterKey">
                            <Form.Label>Commercial-Master-Key-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleMasterKey"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleMasterKey}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionMasterKey">
                            <Form.Label>Commercial-Master-Key-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionMasterKey"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionMasterKey}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleKeyDup">
                            <Form.Label>Commercial-Key-Dup-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleKeyDup"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleKeyDup}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionKeyDup">
                            <Form.Label>Commercial-Key-Dup-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionKeyDup"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionKeyDup}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleResInstall">
                            <Form.Label>Residential-Install-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleResInstall"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleResInstall}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionResInstall">
                            <Form.Label>Residential-Install-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionResInstall"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionResInstall}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleResKeyExtract">
                            <Form.Label>Residential-Key-Extract-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleResKeyExtract"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleResKeyExtract}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionResKeyExtract">
                            <Form.Label>Residential-Key-Extract-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionResKeyExtract"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionResKeyExtract}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleResSafe">
                            <Form.Label>Residential-Safe-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleResSafe"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleResSafe}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionResSafe">
                            <Form.Label>Residential-Safe-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionResSafe"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionResSafe}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentTitleResRekey">
                            <Form.Label>Residential-Rekey-Page Title:</Form.Label>
                            <Form.Control
                                name="seoHelmentTitleResRekey"
                                required
                                type="text"
                                defaultValue={setting.seoHelmentTitleResRekey}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicSeoHelmentDescriptionResRekey">
                            <Form.Label>Residential-Rekey-page Description:</Form.Label>
                            <Form.Control
                                name="seoHelmentDescriptionResRekey"
                                required
                                as="textarea"
                                type="text"
                                defaultValue={setting.seoHelmentDescriptionResRekey}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="mt-5 pt-5" md={4}>
                        <Form.Group className="mb-3" controlId="formBasicTax">
                            <Form.Label>Tax:</Form.Label>
                            <Form.Control
                                name="tax"
                                required
                                type="text"
                                defaultValue={setting.tax}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicShipping">
                            <Form.Label>Shipping:</Form.Label>
                            <Form.Control
                                name="shipping"
                                required
                                type="number"
                                defaultValue={setting.shipping}
                            />
                        </Form.Group>
                        <h4>{setting.seoHelmentName} Hours</h4>
                        <Table size="sm">

                            <tbody className="opening-hours">
                                <tr>
                                    <td>Monday</td>
                                    <td></td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicMonOpHours">
                                            <Form.Control
                                                name="monHoursOp"
                                                required
                                                type="text"
                                                defaultValue={setting.monHoursOp}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>-</td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicMonClHours">
                                            <Form.Control
                                                name="monHoursCl"
                                                required
                                                type="text"
                                                defaultValue={setting.monHoursCl}
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tuesday</td>
                                    <td></td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicTusOpHours">
                                            <Form.Control
                                                name="tusHoursOp"
                                                required
                                                type="text"
                                                defaultValue={setting.tusHoursOp}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>-</td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicTusClHours">
                                            <Form.Control
                                                name="tusHoursCl"
                                                required
                                                type="text"
                                                defaultValue={setting.tusHoursCl}
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Wednesday</td>
                                    <td></td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicWedOpHours">
                                            <Form.Control
                                                name="wedHoursOp"
                                                required
                                                type="text"
                                                defaultValue={setting.wedHoursOp}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>-</td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicWedClHours">
                                            <Form.Control
                                                name="wedHoursCl"
                                                required
                                                type="text"
                                                defaultValue={setting.wedHoursCl}
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Thursday</td>
                                    <td></td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicThuOpHours">
                                            <Form.Control
                                                name="thuHoursOp"
                                                required
                                                type="text"
                                                defaultValue={setting.thuHoursOp}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>-</td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicThuClHours">
                                            <Form.Control
                                                name="thuHoursCl"
                                                required
                                                type="text"
                                                defaultValue={setting.thuHoursCl}
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Friday</td>
                                    <td></td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicFriOpHours">
                                            <Form.Control
                                                name="friHoursOp"
                                                required
                                                type="text"
                                                defaultValue={setting.friHoursOp}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>-</td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicFriClHours">
                                            <Form.Control
                                                name="friHoursCl"
                                                required
                                                type="text"
                                                defaultValue={setting.friHoursCl}
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td></td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicSatOpHours">
                                            <Form.Control
                                                name="satHoursOp"
                                                required
                                                type="text"
                                                defaultValue={setting.satHoursOp}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>-</td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicSatClHours">
                                            <Form.Control
                                                name="satHoursCl"
                                                type="text"
                                                defaultValue={setting.satHoursCl}
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sunday</td>
                                    <td></td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicSunOpHours">
                                            <Form.Control
                                                name="sunHoursOp"
                                                
                                                type="text"
                                                defaultValue={setting.sunHoursOp}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>-</td>
                                    <td>
                                        <Form.Group className="mb-2" controlId="formBasicSunClHours">
                                            <Form.Control
                                                name="sunHoursCl"
                                                
                                                type="text"
                                                defaultValue={setting.sunHoursCl}
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                        </Row>
                        <div className="text-center">
                            <Button variant="outline-primary" type="submit">
                                UPDATE
                            </Button>
                        </div>
                        {updateSettingsResponseState.error ?? ""}
                    </Form>
        </Container>
    );
};

export default EditPageSettingsmponent;
