import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const AmericanForkLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleAmericanFork}
                description={setting.seoHelmentDescriptionAmericanFork}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeAmericanFork} />
            <div className="row americanForkBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Mobile Locksmith Services American Fork</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10">
                    <h2 className="fw-bold mt-5 mb-3 font-size-18">Welcome to American Fork Locksmith - Your Trusted Local Locksmith</h2>
                    <p className="font-size-16">
                        If you're looking for a reliable locksmith in American Fork, look no further than American Fork Locksmith. Our team of experienced locksmith technicians
                        is available 24/7 to provide you with fast, efficient, and high-quality locksmith services.
                    </p>
                    <p className="font-size-16">
                        We understand how frustrating it can be to lose your keys or be locked out of your home, business, or vehicle. That's why we offer a comprehensive range
                        of locksmith services to meet all your needs.
                    </p>
                    <p className="font-size-16 ms-3">
                        Residential Locksmith Services
                        Our residential locksmith services include lock repair, replacement, and installation. We also offer rekeying services, so you can ensure that your home
                        is secure and protected.
                    </p>
                    <p className="font-size-16 ms-3">
                        Commercial Locksmith Services
                        We understand the importance of keeping your business secure. That's why we offer a variety of commercial locksmith services, including high-security lock
                        installation, access control systems, and master key systems.
                     </p>
                    <p className="font-size-16 ms-3">
                        Automotive Locksmith Services
                        If you've locked your keys in your car or need a new key made, our team of automotive locksmiths is here to help. We offer car key duplication, transponder
                        key programming, and ignition repair services.
                    </p>
                    <p className="font-size-16 ms-3">
                        Emergency Locksmith Services
                        We understand that emergencies can happen at any time, day or night. That's why we offer 24/7 emergency locksmith services to get you back into your property
                        quickly and safely.
                    </p>
                    <p className="font-size-16">
                        At American Fork Locksmith, we take pride in providing exceptional customer service and high-quality locksmith services. Our technicians are fully licensed,
                        insured, and bonded, so you can have peace of mind knowing that your locks are in good hands.

                        If you need a locksmith in American Fork, don't hesitate to contact us today for a free quote or to schedule an appointment.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row americanForkBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    At our locksmith service, we understand that being locked out of your vehicle can be a stressful and inconvenient experience. That's why
                                    we offer comprehensive automotive locksmith services that are designed to help you get back on the road quickly and safely. Our team
                                    of experienced technicians has the knowledge and expertise to handle a variety of automotive lock issues, from broken keys to transponder
                                    programming. We use only the latest technology and high-quality materials to ensure that your vehicle is safe and secure. Our 24/7
                                    availability means that you can count on us to be there whenever you need us, day or night. Whether you're at home, work, or on the road,
                                    we can come to your location and provide you with fast and reliable service. Don't let a lockout ruin your day - call us today and let us
                                    provide you with top-quality automotive locksmith services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Vehicle Emergency Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Vehicle emergencies can happen at any time, and they can be a major source of stress and inconvenience. At our locksmith service, we understand
                                    the importance of getting you back on the road as quickly and safely as possible. That's why we offer comprehensive vehicle emergency services
                                    that are designed to handle a variety of issues, from lockouts to broken keys and more. Our team of experienced technicians is available 24/7
                                    to provide you with fast and reliable service when you need it most. We use only the latest technology and high-quality materials to ensure
                                    that your vehicle is safe and secure. Our fast response times and professional service ensure that you get the help you need when you need it,
                                    without any hassle. Whether you're stranded on the side of the road or stuck in a parking lot, we can come to your location and provide you with
                                    top-quality vehicle emergency services that you can rely on. Don't let a vehicle emergency ruin your day - call us today and let us provide you
                                    with the peace of mind you deserve.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Business Emergency Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    As a business owner, you know that time is money, and any unexpected delay or interruption can have a major impact on your bottom line. That's why
                                    our locksmith service offers comprehensive business emergency services that are designed to help you get back to business as quickly and safely
                                    as possible. Our team of experienced technicians is available 24/7 to provide you with fast and reliable service when you need it most. We
                                    understand the importance of keeping your business safe and secure, which is why we use only the latest technology and high-quality materials
                                    to ensure that your property is protected against theft and break-ins. Our fast response times and professional service ensure that you get
                                    the help you need when you need it, without any hassle. Whether you're dealing with a lockout, a broken lock, or any other issue, we can come
                                    to your location and provide you with top-quality business emergency services that you can rely on. Don't let a security issue interrupt your
                                    business operations - call us today and let us provide you with the peace of mind you need to keep your business running smoothly.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Master Rekey Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Master rekey service is an essential security measure for any home or business. At our locksmith service, we offer comprehensive master rekey
                                    services that are designed to provide you with complete control over who has access to your property. Whether you need to rekey a single lock
                                    or an entire building, our team of experienced technicians is available to provide you with fast and reliable service when you need it most.
                                    Our master rekey services are affordable and customizable, allowing you to choose the level of security that best meets your needs. We use only
                                    the latest technology and high-quality materials to ensure that your property is protected against theft and break-ins. Our fast response times
                                    and professional service ensure that you get the help you need when you need it, without any hassle. Don't leave your property vulnerable to
                                    unauthorized access - call us today and let us provide you with the peace of mind you need to feel safe and secure in your home or business.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Residential Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    At our locksmith service, we understand how important your home is to you, and we're dedicated to providing comprehensive residential locksmith
                                    services that you can trust. Our team of experienced technicians is available 24/7 to provide you with fast and reliable service when you need
                                    it most. Whether you're dealing with a lockout, a broken lock, or any other issue, we can come to your location and provide you with top-quality
                                    residential locksmith services that you can rely on. We use only the latest technology and high-quality materials to ensure that your property
                                    is protected against theft and break-ins. Our fast response times and professional service ensure that you get the help you need when you need
                                    it, without any hassle. From door lock installations and repairs to key cutting and duplication, we provide a wide range of services to meet
                                    your specific needs. Don't compromise on the safety and security of your home - call us today and let us provide you with the peace of mind you
                                    need to feel safe and secure in your own home.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Commercial Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    As a business owner, the security of your commercial property should be a top priority. That's why our locksmith company offers reliable and
                                    affordable commercial locksmith services that are tailored to meet your specific needs. We specialize in a wide range of commercial locksmith
                                    solutions, including high-security locks, master key systems, access control systems, and more. Our team of expert technicians has the knowledge
                                    and experience to assess your security needs and recommend the best solutions to keep your property safe and secure. We use only the highest
                                    quality products and tools to ensure that your locks and security systems are working at their best. With our commercial locksmith services,
                                    you can have peace of mind knowing that your business is protected from theft and intrusion. Contact us today to schedule a consultation and
                                    take the first step in securing your commercial property.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default AmericanForkLocksmithComponent;
