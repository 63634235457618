import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import BestCyl from "../../gallery/best-keying.jpg";
import LockedOut from "../../gallery/Key-ALX.webp";
import Mortise from "../../gallery/Mortise-cylinder-on-storefront.jpg";
import KIK from "../../gallery/KIK_Cylinder.jpg";
import MortiseChange from "../../gallery/Locksmith-changing-a-mortise-cylinder.jpg";
import BestChange from "../../gallery/best_key.webp";
import Commercial from "../../gallery/Closeup-of-a-commercial-lock-on-business.jpg";

const BusinessLockoutComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleCommLock}
                description={setting.seoHelmentDescriptionCommLock}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeCommLock} />
            <div className="row BusinessLockBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Business Lockout Services</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Commercial} className="img-thumbnail-comm1 img-thumbnail" alt="Close up of a commercial lock on a business" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Commercial Lock Mechanism
                            </h2>
                            <p className="font-size-16">
                                Commercial lock mechanisms are a crucial part of any business's security system. These locks are designed to be strong, durable, and able to withstand heavy use in
                                high-traffic areas. They come in a variety of styles, including mortise locks, deadbolts, and smart locks that can be controlled remotely via a smartphone or computer.
                                One of the key features of commercial lock mechanisms is their ability to be rekeyed easily, which allows businesses to quickly and affordably change their locks if an
                                employee is terminated or if security is compromised. At Captain Lock and Key, our expert locksmiths are experienced in installing, repairing, and maintaining all types of
                                commercial lock mechanisms. Whether you need a new lock system installed, a broken lock fixed, or a set of keys duplicated, we have the expertise and equipment to get
                                the job done right.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={BestCyl} className="img-thumbnail-auto img-thumbnail" alt="IC core installation after a lockout" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                IC Core Lockout
                            </h2>
                            <p className="font-size-16">
                                IC Core lockouts can be a frustrating experience for any business owner or employee. These locks are commonly found on commercial doors and require a special key to unlock
                                them. If the key is lost, stolen, or damaged, it can result in a lockout that can disrupt business operations and cause significant inconvenience. At Captain Lock and Key,
                                we understand the importance of fast and reliable IC Core lockout services. Our experienced locksmiths have the tools and expertise to quickly and safely gain entry to your
                                locked door and get you back to business as usual. We can also provide replacement IC Core keys or rekey the lock if necessary to ensure your security is not compromised.
                                Don't let an IC Core lockout disrupt your business � call Captain Lock and Key for fast and dependable lockout services.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Mortise} className="img-thumbnail-auto img-thumbnail" alt="Locksmith opening a mortise lock on a storefront" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Mortise Lockout
                            </h2>
                            <p className="font-size-16">
                                Mortise locks are commonly found in commercial buildings and are popular due to their reliability and security. However, these locks can also be challenging to open if the
                                key is lost, stolen, or broken, resulting in a frustrating lockout. At Captain Lock and Key, we specialize in providing fast and reliable Mortise lockout services to help
                                you regain access to your property. Our expert locksmiths have the necessary skills and experience to unlock Mortise locks quickly and safely without causing any damage to
                                your doors or locks. We also carry a range of replacement Mortise lock parts to ensure that your lock is functioning correctly after we have gained entry. Our goal is to
                                get you back to business as soon as possible without causing any additional disruptions. If you're experiencing a Mortise lockout, call Captain Lock and Key for prompt and
                                professional assistance.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={LockedOut} className="img-thumbnail-comm2 img-thumbnail" alt="Commercial door lever lockout" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Commercial Door Lever Lockout
                            </h2>
                            <p className="font-size-16">
                                Commercial Door Lever locks are common in businesses, and they provide an efficient way to secure a property. However, when the key is lost, broken, or stolen, it can lead
                                to an inconvenient lockout that can halt business operations. At Captain Lock and Key, we understand that time is of the essence, and we have the experience and knowledge
                                to get you back to business as soon as possible. Our team of expert locksmiths has the necessary tools and expertise to quickly and safely open Commercial Door Lever locks
                                without causing any damage to the doors or locks. We carry a wide range of commercial door lever lock parts, and we can replace the lock if necessary to ensure that your
                                property is secure. Whether you're experiencing a lockout due to a lost key, broken key, or any other reason, we're here to help. At Captain Lock and Key, we take your
                                security and convenience seriously, and we're committed to providing you with the best possible service. Contact us today for reliable Commercial Door Lever lockout
                                services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row AutomotiveBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5 mb-5">
                        <div className="row mb-5 mt-5">
                            <div className="borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2 pb-4">Affordable Business Lockout Services In Springville?</h2>
                                <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <h2 className="fw-bolder mt-5 mb-3 font-size-45 text-center">Lost Keys</h2>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={KIK} className="img-thumbnail-auto img-thumbnail" alt="Rekeying a commercial key in knob cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Rekey services
                            </h2>
                            <p className="font-size-16">
                                Rekeying is a process of changing the key combination of a lock without replacing the entire lock. At Captain Lock and Key, we offer comprehensive rekey services for
                                residential and commercial properties. Whether you have recently moved into a new home or office, have lost your keys, or have concerns about the security of your
                                property, rekeying can be a cost-effective solution. Our team of skilled locksmiths can quickly and efficiently rekey your locks, creating a new key combination that
                                will prevent anyone with an old key from gaining access to your property. We use high-quality pins and tumblers to ensure that your locks are secure and that your property
                                is protected. We can rekey all types of locks, including deadbolts, padlocks, and high-security locks. Rekeying is an affordable way to upgrade your security, and our
                                experts at Captain Lock and Key are here to help you with all of your rekeying needs. Contact us today to schedule an appointment and enjoy the peace of mind that comes
                                with a properly secured property.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={MortiseChange} className="img-thumbnail-auto img-thumbnail" alt="Locksmith installing and changing a mortise cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Mortise lock change
                            </h2>
                            <p className="font-size-16">
                                Mortise locks are a type of locking mechanism that is commonly used in commercial buildings and high-security properties. If you need to upgrade the security of your
                                property, a mortise lock change may be the best option for you. At Captain Lock and Key, we offer comprehensive mortise lock change services to provide our customers
                                with peace of mind and maximum security. Our experienced locksmiths have the knowledge and tools to remove and replace your existing mortise locks with new, high-quality
                                locks that are resistant to tampering, picking, and drilling. We use only the best quality mortise locks to ensure that your property is fully secured. Our experts will
                                assess your property to recommend the best type of mortise lock to meet your specific security needs. Whether you need a mortise lock change for a single door or an entire
                                building, we have the expertise to get the job done efficiently and effectively. Contact us today to learn more about our mortise lock change services and schedule an
                                appointment.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={BestChange} className="img-thumbnail-comm2 img-thumbnail" alt="Locksmith changing a Best IC cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                IC core lock change
                            </h2>
                            <p className="font-size-16">
                                IC core locks are a type of high-security lock that use interchangeable core cylinders to provide flexibility and convenience for business owners. If you need to change
                                the IC core lock in your commercial property, Captain Lock and Key has you covered. Our experienced locksmiths have the knowledge and expertise to provide you with a
                                seamless IC core lock change. We understand the importance of keeping your property safe and secure, and that's why we use only the best quality IC core locks to provide
                                you with peace of mind. Our team will assess your property to recommend the best type of IC core lock that will meet your specific security needs. We will remove your old
                                IC core lock and install a new one, ensuring that it is properly aligned, and functioning correctly. Our locksmiths are equipped with the latest tools and technology to
                                get the job done quickly and efficiently. We pride ourselves on providing exceptional customer service, and we guarantee your complete satisfaction with our IC core lock
                                change services. Contact us today to learn more and schedule an appointment.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessLockoutComponent;
