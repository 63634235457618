import ProductCarouselComponent from "../../components/ProductCarouselComponent";
import { useEffect, useState } from "react";
import MetaComponent from "../../components/MetaComponent";
import AlxCylinder from "../../gallery/Alx_Skrew.jpg";
import hondaKey from "../../gallery/honda_Key.webp";
import Keys from "../../gallery/keys.jpg";
import KeyCutting from "../../gallery/KeyCutting.webp";
import { Link } from "react-router-dom";
import paths from "../../router/paths";

const HomePageComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);

    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);
      
  return (
    <div className="w-100">
          <MetaComponent
              title='Springville Locksmith - Local And Trustworthy Services'
              description="Springville Locksmith: Reliable local services you can trust. From lock repairs to key replacements, we ensure prompt, professional security solutions."
              name={setting.seoHelmentName}
              type={setting.seoHelmentType} />
          <div>
              <ProductCarouselComponent />
          </div>
          <div>
              <h2 className="fw-bold text-center text-size-big">Get The Highest Quality Service</h2>
              <div className="hrWidth"><hr className="hrBorder" /></div>
              <div>
                  <div className="row justify-content-center">
                  <div className="col-md-6 col-lg-8 col-sm-4 mt-4">
              <p className="fontSize-med ms-3 me-3">
                  Captain Lock and Key has all the experience and specialized equipment to serve all of your locksmith needs. Our services include commercial,
                  residential and automotive. We are an expert team of locksmiths that you can trust to program, generate keys or install quality lock hardware.
                  If you are looking for the highest quality locksmith service call (801) 609-8234 for a free estimate!
               </p>
                      </div>
                  </div>
              </div>
              <div className="row mt-5">
                  <div className="col m-0 p-0">
                      <img src={hondaKey} alt="Honda Key" className="img-fluid m-0 p-0 w-100 img-width-sm" />
                  </div>
                  <div className="col m-0 p-0">
                      <img src={AlxCylinder} alt="Honda Key" className="img-fluid m-0 p-0 img-width-sm" />
                  </div>
                  <div className="col m-0 p-0">
                      <img src={Keys} alt="Honda Key" className="img-fluid m-0 p-0 img-width-sm" />
                  </div>
                  <div className="col m-0 p-0">
                      <img src={KeyCutting} alt="Honda Key" className="img-fluid m-0 p-0 img-width-sm" />
                  </div>
                  </div>
          </div>
          <div className="row em3 m-0 p-0">
              <div className="col background">
                  <h2 className="zIndex text-center font-size-45 mt-4 text-light fw-bold">Captain Lock and Key in Springville, Utah</h2>
                  <div className="hrWidth"><hr className="hrBorder" /></div>
                  <div className="row zIndex justify-content-center mt-5 pt-5 mb-5">
                      <div className="col-md-3 zIndex mb-4">
                          <div className="card">
                              <div className="image-overly">
                              </div>
                              <div className="card-body text-center">
                                  <h5 className="card-title font-size-title mt-2 mb-3 text-light text-center">Automotive Locksmith Service</h5>
                                  <p className="card-text text-light font-size-text mt-2 mb-3 text-center">
                                      Did you lose your keys? We can cut and program a new key on site. You might still have the key but the case broke.
                                      We are equipped to replace almost every make and model transponder or fob keys. Give us a call for a free estimate!
                                  </p>
                                  <Link to={paths.AUTOLOCK} className="text-light">Learn More</Link>
                              </div>
                        </div>
                      </div>
                      <div className="col-md-3 zIndex mb-4">
                          <div className="card">
                              <div className="image-overly1">
                              </div>
                              <div className="card-body text-center">
                                  <h5 className="card-title font-size-title mt-2 mb-3 text-light text-center">Commercial Locksmith Service</h5>
                                  <p className="card-text text-light font-size-text mt-2 mb-3 text-center">
                                      Our technicians are skilled and licensed to properly handle all of your locksmith needs. Some of those services
                                      include master key systems, install and repair hardware, and high security solutions. Call us today for a free estimate!
                                  </p>
                                  <Link to={paths.COMMLOCK} className="text-light">Learn More</Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-3 zIndex mb-4">
                          <div className="card">
                              <div className="image-overly2">
                              </div>
                              <div className="card-body text-center">
                                  <h5 className="card-title font-size-title mt-2 mb-3 text-light text-center">Residential Locksmith Service</h5>
                                  <p className="card-text text-light font-size-text mt-2 mb-3 text-center">
                                      Maybe you just moved into a new home and are looking for it to be rekeyed. You might have a lock that is having a problem.
                                      Our technicians are skilled to give you the best possible service. Give us a call for a free estimate!
                                  </p>
                                  <Link to={paths.RESLOCK} className="text-light">Learn More</Link>
                              </div>
                          </div>
                      </div>
                      </div>
                  <div className="row zIndex justify-content-center mt-5 pt-5 mb-5">
                      <div className="col-md-3 zIndex mb-4">
                          <div className="card">
                              <div className="image-overly3">
                              </div>
                              <div className="card-body text-center">
                                  <h5 className="card-title font-size-title mt-2 mb-3 text-light text-center">Lock Change & Lock Repairs</h5>
                                  <p className="card-text text-light font-size-text mt-2 mb-3 text-center">
                                      Do you need a lock repaired or even replaced? Our technicians are skilled to diagnose and find the best option to fix the issue.
                                      Our lock installation and repair service is something you can depend on! Call us today for more info!
                                  </p>
                                  <Link to={paths.COMMLOCKINSTALL} className="text-light">Learn More</Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-3 zIndex mb-4">
                          <div className="card">
                              <div className="image-overly4">
                              </div>
                              <div className="card-body text-center">
                                  <h5 className="card-title font-size-title mt-2 mb-3 text-light text-center">Emergency Lockout Service</h5>
                                  <p className="card-text text-light font-size-text mt-2 mb-3 text-center">
                                      Have you locked yourself out of your home, business or vehicle?
                                      Whatever the situation is you can appreciate our extremely fast response times. Give us a call to get back on schedule!
                                  </p>
                                  <Link to={paths.EMERGLOCK} className="text-light">Learn More</Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-3 zIndex mb-4">
                          <div className="card">
                              <div className="image-overly5">
                              </div>
                              <div className="card-body text-center">
                                  <h5 className="card-title font-size-title mt-2 mb-3 text-light text-center">Lock Rekey Service</h5>
                                  <p className="card-text text-light font-size-text mt-2 mb-3 text-center">
                                      It doesn't matter if you need a basic rekey or need a highly advanced master key system.
                                      We are trained and properly licensed to handle all of your needs. Give us a call for a free estimate!
                                  </p>
                                  <Link to={paths.COMMREKEY} className="text-light">learn More</Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="row zIndex justify-content-center pt-5 mb-5 pb-5">
                      <div className="col-md-4 zIndex mb-4">
                          <div className="card">
                              <div className="image-overly6">
                              </div>
                              <div className="card-body text-center">
                                  <h5 className="card-title font-size-title mt-2 mb-3 text-light text-center">Access Control Solutions</h5>
                                  <p className="card-text text-light font-size-text mt-2 mb-3 text-center">
                                      Maybe you want to update your system to electronic keys. Maybe a mechanical key system is not right for you.
                                      Either way we have the experience to help you accomplish your goals! Give us a call today to schedule a walk through.
                                  </p>
                                  <Link to={paths.COMMLOCK} className="text-light">Learn More</Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-4 zIndex mb-4">
                          <div className="card">
                              <div className="image-overly7">
                              </div>
                              <div className="card-body text-center">
                                  <h5 className="card-title font-size-title mt-2 mb-3 text-light text-center">Key Extraction Service</h5>
                                  <p className="card-text text-light font-size-text mt-2 mb-3 text-center">
                                      Have you broken your key in your commercial or residential locks? How about breaking it into your vehicle door or ignition?
                                      No matter what the situation we have all the necessary tools and skills to help you! Give us a call today to schedule an appointment.
                                  </p>
                                  <Link to={paths.RESKEYEXTRACT} className="text-light">Learn More</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </div>
  );
};

export default HomePageComponent;
