import { Table } from "react-bootstrap";
import $ from 'jquery';

const HoursComponent = ({ setting }) => {
    $(document).ready(function () {
        $('.opening-hours tr').eq(new Date().getDay() - 1).addClass('today');
    });
    $(document).ready(function () {
        $('.opening-hours1 tr').eq(new Date().getDay() - 1).addClass('today');
    });
    $(document).ready(function () {
        $('.opening-hours2 tr').eq(new Date().getDay()).addClass('today');
    });
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h4>Mobile Service Hours</h4>
                    <Table className="table1" size="sm">

                        <tbody className="opening-hours">
                            <tr>
                                <td>Monday</td>
                                <td>{setting.monHoursOp} - {setting.monHoursCl}</td>

                            </tr>
                            <tr>
                                <td>Tuesday</td>
                                <td>{setting.tusHoursOp} - {setting.tusHoursCl}</td>
                            </tr>
                            <tr>
                                <td>Wednesday</td>
                                <td>{setting.wedHoursOp} - {setting.wedHoursCl}</td>
                            </tr>
                            <tr>
                                <td>Thursday</td>
                                <td>{setting.thuHoursOp} - {setting.thuHoursCl}</td>
                            </tr>
                            <tr>
                                <td>Friday</td>
                                <td>{setting.friHoursOp} - {setting.friHoursCl}</td>
                            </tr>
                            {setting.satHoursOp ==='Closed' || setting.satHoursCl === "Closed" ? (
                                <tr>
                                    <td>Saterday</td>
                                    <td>{setting.satHoursOp}</td>
                                </tr>
                            ) : (
                                <tr>
                                <td>Saterday</td>
                                <td>{setting.satHoursOp} - {setting.satHoursCl}</td>
                            </tr>)}
                            {setting.sunHoursOp === 'Closed' || setting.sunHoursCl === "Closed" ? (
                                <tr>
                                    <td>Sunday </td>
                                    <td>{setting.sunHoursOp}</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td>Sunday </td>
                                    <td>{setting.sunHoursOp} - {setting.sunHoursCl}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
        )
}
export default HoursComponent;