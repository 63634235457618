import { useEffect, useState } from "react";
import { AiFillStar, AiFillUnlock, AiFillWarning, AiOutlineWarning } from 'react-icons/ai';
import { CgDanger } from 'react-icons/cg';
import { MdOutlineDangerous } from 'react-icons/md';
import { RiToolsFill } from 'react-icons/ri';
import { GiCarKey, GiGears } from "react-icons/gi";
import { BiLock } from "react-icons/bi";
import { HiKey } from "react-icons/hi";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import { BsFillGearFill } from "react-icons/bs";

const AboutUsPageComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);

    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleAbout}
                description={setting.seoHelmentDescriptionAbout}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeAbout} />
            <div className="row aboutBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sm-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">About Us</h1>
                            </div>
                        </div>
                        <div className="row text-center mb-3 justify-content-center">
                            <div className="col-sm-6 text-center text-light">
                                <p className="font-size-16 text-light mb-3 text-center">
                                    At Captain Lock and Key, we are passionate about providing exceptional locksmith services to the communities we serve. As a family-owned and operated
                                    business, we understand the importance of safety and security, and we take pride in helping our clients feel protected in their homes, businesses, and
                                    vehicles.
                                </p>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row mt-5 pt-4 justify-content-center">
            
                <div className="col-sm-9">
                    <h2 className="text-center font-size-45 fw-bold">
                        Why should you Hire Captain Lock and Key?
                    </h2>
                    <p className="font-size-16 mt-4 text-center">
                        If you're looking for a reliable locksmith with years of experience, then Captain Lock and Key is the right choice for you. Our team of expert technicians
                        is equipped with the latest tools and technology to provide top-quality locksmith services for your home, business, and vehicle. We prioritize your safety
                        and security, and we always strive to provide fast and friendly service to make sure you can have peace of mind knowing that your locks are in good hands.
                        When you hire Captain Lock and Key, you can be confident that you'll receive the highest level of service and professionalism, ensuring that all your locksmith
                        needs are met with the utmost care and attention to detail.
                    </p>
                </div>
            </div>
            <div className="row justify-content-center mt-5 pt-5">
                <div className="col-sm-8">
                    <h2 className="font-size-45 fw-bold">
                        Automotive Locksmith Services
                    </h2>
                    <p className="font-size-16 mt-4">
                        As a leading provider of locksmith services, we have extensive experience in assisting our customers with their automotive needs. Automotive locksmith services
                        are among the most frequently requested services, typically involving gaining access to locked vehicles or addressing issues such as keys that won't fit in
                        the ignition. Our team of skilled technicians is well-equipped to handle a wide range of automotive locksmith tasks, from lockouts to ignition repair and
                        replacement, as well as transponder key programming. We understand the urgency of automotive locksmith needs, which is why we make every effort to respond
                        quickly to our customers' requests. Take a look at some of the automotive services we offer:
                    </p>
                </div>
                <div className="col-sm-1">
                </div>
            </div>
            <div className="container mt-5">
            <div className="row justify-content-center">
                    <div className="col text-center pe-5">
                        <GiCarKey className="aboutIcons"/>
                        <h2 className="font-size-20 text-center mt-5 fw-bold">
                            Car Lock Rekeying
                        </h2>
                        <p className="font-size-12 mt-4">
                            In certain situations, our customers may require a specific task to be performed. This task becomes necessary when a customer loses their keys or they are
                            stolen, or they are facing issues with a faulty ignition switch. At Captain Lock and Key, we provide a comprehensive solution to this problem by installing
                            new lock cylinders, which saves the customer from having to replace the entire lock. Our skilled technicians can expertly install these new cylinders,
                            giving you peace of mind that your vehicle is secure and accessible.
                        </p>
                    </div>
                    <div className="col ps-5 text-center">
                        <AiOutlineWarning className="aboutIcons"/>
                        <h2 className="text-center font-size-20 mt-5 fw-bold">
                            Car Lockout
                        </h2>
                        <p className="font-size-12 mt-4">
                            At Captain Lock and Key, we understand how frustrating it can be to accidentally lock your car keys inside your vehicle. That's why we offer fast and
                            reliable car lockout services in Springville. Our licensed locksmiths can quickly and efficiently pop a lock in just a few minutes and have you back inside
                            your car, so you can get back to your day without any further delay. Don't hesitate to contact us for all your emergency lockout needs.
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col text-center pe-5">
                        <RiToolsFill className="aboutIcons" />
                        <h2 className="font-size-20 text-center mt-5 fw-bold">
                            Car Key Extraction
                        </h2>
                        <p className="font-size-12 mt-4">
                            At Captain Lock and Key, we understand the frustration that comes with a broken car key stuck in your ignition or car door. Our experienced professionals
                            are equipped with the latest tools and techniques to extract the key quickly and efficiently. Whether your key has broken off inside the ignition or car
                            door, we can help you get back on the road in no time. Contact us today for fast and reliable broken key extraction services.
                        </p>
                    </div>
                    <div className="col ps-5 text-center">
                        <GiGears className="aboutIcons" />
                        <h2 className="text-center font-size-20 mt-5 fw-bold">
                            Key Replacement
                        </h2>
                        <p className="font-size-12 mt-4">
                            Losing your car keys can be a frustrating and stressful experience, but don't worry, Captain Lock and Key is here to help. Our experienced locksmiths
                            specialize in cutting new car keys on-site, so you don't have to wait long to get back on the road. Whether you need a spare key or a replacement for
                            a lost or stolen key, our team can quickly and accurately cut a new key for your vehicle. We use the latest technology and tools to ensure that your
                            new key fits perfectly and works seamlessly with your car's locks and ignition. Contact us today for fast and reliable car key cutting services.
                        </p>
                    </div>
                </div>


            </div>
            <div className="row justify-content-center mt-5 pt-5">
                <div className="col-sm-9">
                    <h2 className="font-size-45 fw-bold">
                        Residential Locksmith Services
                    </h2>
                    <p className="font-size-16 mt-4">
                        At Captain Lock and Key, we understand that the security of your home is paramount. That's why we offer a wide range of residential locksmith services to keep
                        your home secure. Our experienced technicians are equipped with the latest tools and technology to provide top-quality locksmith services for your home. We
                        can handle lock installation, repair, and emergency lock services for virtually any type of lock, including both electronic and traditional locks. Our
                        knowledgeable technicians can repair all makes of locks, ensuring that you can keep using the lock you currently own with the reassurance of it operating
                        properly. Contact us today to schedule an appointment or to receive a free quote for our residential locksmith services.
                    </p>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col text-center pe-5">
                        <BiLock className="aboutIcons" />
                        <h2 className="font-size-20 text-center mt-5 fw-bold">
                            Door Lock
                        </h2>
                        <p className="font-size-12 mt-4">
                            Rekeying your door locks is a common service that our experienced locksmiths at Captain Lock and Key offer. If your keys are lost or stolen, or if your
                            front door lock has been damaged due to a break-in, our technicians can rekey the lock to ensure that it can only be opened with a new key. Rekeying a
                            lock is a cost-effective way to enhance the security of your home, especially if you are unsure of who may have access to your old keys. Our team can
                            quickly rekey your locks so that you can have peace of mind knowing that your home is secure.
                        </p>
                    </div>
                    <div className="col ps-5 text-center">
                        <CgDanger className="aboutIcons" />
                        <h2 className="text-center font-size-20 mt-5 fw-bold">
                            Burglary Damage Repair
                        </h2>
                        <p className="font-size-12 mt-4">
                            At Captain Lock and Key, our team of skilled locksmiths is equipped to handle emergency repairs in the unfortunate event of a burglary. We understand
                            that a forced entry can cause significant damage to the locking mechanism, leaving your property vulnerable and unsafe. That's why we offer prompt and
                            efficient emergency repair services to restore your peace of mind. Our experienced technicians will work quickly to assess the damage and provide a
                            reliable solution to secure your home or business. With our expertise in repairing all types of locks, you can trust us to restore the safety and security
                            of your property.
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col text-center pe-5">
                        <MdOutlineDangerous className="aboutIcons" />
                        <h2 className="font-size-20 text-center mt-5 fw-bold">
                            Stolen House Keys
                        </h2>
                        <p className="font-size-12 mt-4">
                            Protecting your home and family is of utmost importance. If you've lost your house keys or they have been stolen, it's crucial to act quickly to ensure
                            your safety. Our team of expert locksmiths can help by rendering those stolen keys unusable. We can either change the locks or rekey your existing locks,
                            ensuring that no one can gain access to your home with those stolen keys. With our prompt and professional service, you can have peace of mind knowing
                            that your home is secure.
                        </p>
                    </div>
                    <div className="col ps-5 text-center">
                        <AiFillStar className="aboutIcons" />
                        <h2 className="text-center font-size-20 mt-5 fw-bold">
                            New Lock Installation
                        </h2>
                        <p className="font-size-12 mt-4">
                            If you're relocating to a new home and looking to enhance your security, our professional locksmiths can replace all your door locks with brand new units.
                            This means that only you will have the keys and the peace of mind that comes with it. With our extensive range of high-quality locks, you can rest assured
                            that your home will be fully secure against any unauthorized entry. Whether you're looking to upgrade your existing locks or just need a completely new set
                            of locks installed, our experienced technicians are here to help.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5 pt-5">
                <div className="col-sm-8">
                    <h2 className="font-size-45 fw-bold">
                        Commercial Locksmith Services
                    </h2>
                    <p className="font-size-16 mt-4">
                        We also offer services for commercial establishments in Springville and the surrounding area. Some of these services include:
                    </p>
                </div>

            </div>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col text-center pe-5">
                        <HiKey className="aboutIcons" />
                        <h2 className="font-size-20 text-center mt-5 fw-bold">
                            Office Door Rekey
                        </h2>
                        <p className="font-size-12 mt-4">
                            If a commercial client loses or has their office keys stolen, they may choose to have the door locks rekeyed instead of completely replaced. This
                            service allows our expert locksmiths to modify the existing locks so that they can only be opened with new keys, rendering the old ones useless.
                            This option is more cost-effective than replacing all the locks and can be completed quickly to restore the security of the commercial property.
                            Our technicians have experience working with a variety of commercial locks, including high-security options, and can offer guidance on the best
                            solutions to meet the client's needs.
                        </p>
                    </div>
                    <div className="col ps-5 text-center">
                        <AiFillUnlock className="aboutIcons" />
                        <h2 className="text-center font-size-20 mt-5 fw-bold">
                            Lock Replacement
                        </h2>
                        <p className="font-size-12 mt-4">
                            If a commercial customer has a damaged lock that cannot be repaired or rekeyed, or they simply prefer to replace the lock altogether, our team of
                            technicians can provide a variety of options to suit their needs. We offer a wide selection of high-quality commercial locks, including electronic
                            and keyless entry options, that can be installed quickly and efficiently to keep your business secure. Our professionals can also offer expert advice
                            and recommendations to ensure that you are getting the best lock for your particular needs. Contact us today to schedule an appointment and get started
                            on improving the security of your business.
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center mt-5 mb-5">
              
                    <div className="col text-center pe-5">
                        <AiFillWarning className="aboutIcons" />
                        <h2 className="font-size-20 text-center mt-5 fw-bold">
                            Office Lockout
                        </h2>
                        <p className="font-size-12 mt-4">
                            Our professional commercial locksmiths understand the urgency of an office lockout and are trained to respond promptly to provide excellent service.
                            We offer a range of solutions to get you back inside your office quickly, including lock picking, lock replacement, and rekeying. In addition, we can
                            cut new keys for you on-site to ensure that you have access to your office at all times. With years of experience in the locksmith industry, we are
                            equipped to handle any type of commercial lock and key issue. Contact us today for fast and reliable service.
                        </p>
                    </div>
                    <div className="col ps-5 text-center">
                        <BsFillGearFill className="aboutIcons" />
                        <h2 className="text-center font-size-20 mt-5 fw-bold">
                            Repair Burglary Damage
                        </h2>
                        <p className="font-size-12 mt-4">
                            If you find that your office has been subject to a break-in and your locks are damaged or keys have been stolen, our expert commercial locksmiths can
                            swiftly repair or replace your broken locks and provide new keys for you and your team. We understand that time is of the essence in such a situation,
                            which is why we prioritize your call and aim to arrive at your location as soon as possible. Our experienced technicians can not only address the immediate
                            security concerns but also provide valuable advice on ways to enhance your office's security system to prevent any future breaches.
                        </p>
                    </div>
                </div>


            </div>
            <div className="row justify-content-center mt-5 pt-5">
                <div className="col-sm-8">
                    <p className="mt-4 font-size-16">
                        Don't hesitate to get in touch with us for any of your commercial lock repair or installation needs. Our services include installation and repair of various
                        types of locks, such as panic hardware, automatic door closers, access control systems and more. We are proud to use only the best brands in the industry to
                        ensure that you receive top-notch service. Contact us today to learn more about how we can assist you with your commercial lock needs.
                    </p>
                    <p className=" mt-4 font-size-16">
                        At our locksmith company, we take pride in offering exceptional commercial locksmith services that are considered some of the best in the industry. We
                        understand that business owners have a lot on their plate, which is why we work efficiently and professionally to quickly address any locksmithing issues
                        they may encounter. Our goal is to get them back to their business concerns as quickly as possible, with peace of mind knowing their property is secure.
                    </p>
                </div>
            </div>
            <div className="row justify-content-center mt-5 mb-5 pb-5">
                <div className="col-sm-8">
                    <h2 className="font-size-45 text-center fw-bold">
                        Why Choose Us?
                    </h2>
                    <p className="font-size-16 mt-4">
                        A significant number of our clients have selected our services due to our highly skilled and knowledgeable staff in all aspects of the locksmith trade. We take
                        pride in providing prompt and courteous assistance that is greatly valued by our customers. Our commitment to offering high-quality services has earned us the
                        loyalty of our clients, who continue to choose us for all their locksmithing requirements.
                    </p>
                    <p className="font-size-16 mt-4">
                        As a top-rated Springville locksmith, our proven track record speaks for itself. With our state-of-the-art tools and skilled technicians, we're committed to providing
                        exceptional service on the first visit. Whether you need a reliable and fully-insured locksmith for your residence, vehicle or enterprise, we're here to help
                        you enhance the security of your assets. Contact us today and let us provide you with the peace of mind you deserve.
                    </p>
                </div>
            </div>
            <div className="mt-3 pt-5 pb-5 col-md-12 text-dark bg-light text-center">
                <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
            </div>
 </div>
        )
}

export default AboutUsPageComponent;