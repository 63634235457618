import axios from "axios";
import AboutUsPageComponent from "./components/AboutUsPageComponent";

const AboutUsPage = () => {
    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }


    return <AboutUsPageComponent fetchSetting={fetchSetting}/>;
};

export default AboutUsPage;
