import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const SalemLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleSalem}
                description={setting.seoHelmentDescriptionSalem}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeSalem} />
            <div className="row salemBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Salem, Utah</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        If you need trusted and reliable locksmith services in Salem, look no further than Captain Lock and Key. Our team of experienced technicians are equipped
                        with state-of-the-art tools and technology to provide you with top-quality locksmith services for your home, business, and vehicle.
                        </p>
                    <p className="font-size-16">
                        Our comprehensive list of services includes the following and more:
                    </p>
                    <ul className="font-size-16">
                        <li>
                            Residential Locksmith Services: We offer a complete range of locksmith services for your home, including lock repair, rekeying, and installation.
                        </li>
                        <li>
                            Commercial Locksmith Services: We provide comprehensive locksmith services for your business, including high-security locks, access control systems, and master key systems.
                        </li>
                        <li>
                            Automotive Locksmith Services: Our technicians are experts in car key duplication, transponder key programming, and ignition repair.
                        </li>
                    </ul>
                    <p className="font-size-16">
                        At Captain Lock and Key, we value your safety and understand the importance of security for you and your property.
                        That's why we provide fast and friendly service, ensuring that your locks are in good hands.
                        <br /><br />
                        We take pride in being Salem's trusted locksmith. Contact us today to schedule an appointment or to receive a free quote for any of our services.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row salemBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you've ever been locked out of your car, you know how stressful and frustrating the situation can be. That's why it's essential to have
                                    a reliable Automotive Locksmith Service on hand. A professional Automotive Locksmith Service can provide a range of solutions to address your
                                    vehicle's locking and unlocking needs, from duplicating keys and repairing locks to programming transponder keys and fobs. With their knowledge
                                    and expertise, an Automotive Locksmith Service can quickly diagnose and address any lock-related issue, providing efficient and effective
                                    solutions to get you back on the road in no time. And with 24/7 availability, an Automotive Locksmith Service can assist you at any time and
                                    any location, ensuring the safety and security of your vehicle. Additionally, a reputable Automotive Locksmith Service can also offer valuable
                                    advice on how to enhance your vehicle's security measures to protect against theft and unauthorized access. So don't let a lock-related issue
                                    disrupt your day. Contact a trusted Automotive Locksmith Service today to ensure your vehicle is always secure and operational.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Commercial Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    As a business owner or manager, ensuring the security of your business and assets is of utmost importance. That's where a reliable Commercial
                                    Locksmith Service comes in. A professional Commercial Locksmith Service can provide a range of services to address your business's security
                                    needs, from installing high-security locks and electronic access control systems to repairing and replacing broken or damaged locks. With
                                    their expertise and advanced tools and equipment, a Commercial Locksmith Service can help you safeguard your business and prevent unauthorized
                                    access. They can also provide emergency lockout services to quickly restore access to your business in the event of a lockout situation.
                                    Additionally, a trusted Commercial Locksmith Service can offer valuable insights and advice on how to enhance your business's security measures
                                    to protect against potential threats. So don't take any chances when it comes to your business's security. Contact a reputable Commercial
                                    Locksmith Service today to ensure your business is always safe and secure.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Business Lockout Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you're a business owner or manager, you know that unexpected lockout situations can be frustrating, disruptive, and potentially costly.
                                    That's why it's essential to have a reliable Business Lockout Service on speed dial. A professional Business Lockout Service can provide prompt
                                    and efficient solutions to get your business back up and running in no time. Their expert technicians possess the skills and tools needed to
                                    quickly address issues such as lost keys, broken locks, or malfunctioning security systems. With 24/7 availability, a Business Lockout Service
                                    can provide emergency lock picking, lock rekeying, or new lock installation services to restore access and get your business back to operating
                                    normally. Additionally, a reliable Business Lockout Service can help you prevent future lockout situations by providing high-security locks and
                                    advanced security systems to safeguard your business and assets. So don't let a lockout situation disrupt your business. Contact a trusted Business
                                    Lockout Service today to ensure your business is always secure and operational.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Residential Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    The security of your home and family is of utmost importance, and a reliable Residential Locksmith Service can help you achieve peace of
                                    mind. A professional Residential Locksmith Service can provide a range of solutions to address your home's security needs, from installing
                                    high-security locks and deadbolts to repairing and replacing broken or damaged locks. With their expertise and advanced tools and equipment,
                                    a Residential Locksmith Service can help you safeguard your home and prevent unauthorized access. They can also provide emergency lockout
                                    services to quickly restore access to your home in the event of a lockout situation. Additionally, a trusted Residential Locksmith Service
                                    can offer valuable insights and advice on how to enhance your home's security measures to protect against potential threats. So don't take
                                    any chances when it comes to your home's security. Contact a reputable Residential Locksmith Service today to ensure your home is always safe
                                    and secure. With 24/7 availability, you can rely on a Residential Locksmith Service to provide prompt and efficient solutions whenever you need
                                    them.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Emergency Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    When you're on the road, unexpected lock-related issues can disrupt your day and cause stress and frustration. That's why it's essential to have
                                    a reliable Automotive Emergency Service on hand. A professional Automotive Emergency Service can provide a range of emergency solutions to address
                                    your vehicle's lock and key issues, from unlocking your car door and replacing lost or broken keys to repairing or replacing damaged or
                                    malfunctioning locks. With their advanced tools and expertise, an Automotive Emergency Service can provide quick and efficient solutions to get you
                                    back on the road in no time. And with 24/7 availability, you can rely on an Automotive Emergency Service to assist you at any time and any location,
                                    ensuring the safety and security of you and your vehicle. Additionally, a reputable Automotive Emergency Service can also offer valuable advice on
                                    how to enhance your vehicle's security measures to protect against theft and unauthorized access. So don't let a lock-related issue disrupt your day.
                                    Contact a trusted Automotive Emergency Service today to ensure your vehicle is always secure and operational, no matter where your travels take you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default SalemLocksmithComponent;
