import axios from "axios";
import AutomotiveLocksmithComponent from "./components/AutomotiveLocksmithComponent";

const AutomotiveLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <AutomotiveLocksmithComponent fetchSetting={fetchSetting} />;
};

export default AutomotiveLocksmithPage;
