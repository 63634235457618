import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import SUV from "../../gallery/SUV-being-locked.jpg";
import Truck from "../../gallery/Locksmith-opening-locked-truck.jpg";
import CarLock from "../../gallery/person-locked-out-of-car.jpg";
import Wheeler from "../../gallery/18-wheeler-lockout.jpg";

const VehicleLockoutComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleAutoLock}
                description={setting.seoHelmentDescriptionAutoLock}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeAutoLock} />
            <div className="row VehicleLockBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Vehicle Lockout</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={CarLock} className="img-thumbnail-auto img-thumbnail" alt="Person locked out of a vehicle" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Car Lockout
                            </h2>
                            <p className="font-size-16">
                                Getting locked out of your car can be a frustrating and stressful experience, but a car lockout service can provide a quick and efficient solution. Car lockout services are
                                designed to help individuals who have accidentally locked their keys inside their vehicle or lost them altogether. A professional locksmith can quickly gain access to your
                                vehicle using specialized tools and techniques without causing any damage to your car. These services are available 24/7, and most locksmiths can arrive at your location
                                within minutes of your call. Whether you are stuck in a remote area or in the middle of rush-hour traffic, a car lockout service can help you get back on the road in no
                                time. It's always a good idea to keep the number of a reliable car lockout service on hand in case of emergencies.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={SUV} className="img-thumbnail-comm1 img-thumbnail" alt="Customer being locked out of an SUV" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                SUV Lockout
                            </h2>
                            <p className="font-size-16">
                                An SUV lockout can be a frustrating and stressful situation. Whether you've lost your keys or accidentally locked them inside your vehicle, being unable to access your SUV
                                can disrupt your plans and cause a significant inconvenience. In such a situation, it's important to stay calm and call a professional locksmith who specializes in SUV
                                lockouts. A skilled locksmith can quickly and efficiently unlock your SUV without causing any damage to the vehicle, allowing you to get back on the road as soon as possible.
                                It's always a good idea to have the number of a reliable and trustworthy locksmith saved in your phone, just in case you find yourself in need of their services.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Wheeler} className="img-thumbnail-auto img-thumbnail" alt="Person locked out of an 18 wheeler" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                18 wheeler lockout
                            </h2>
                            <p className="font-size-16">
                                An 18-wheeler lockout can be a daunting experience, especially for those who depend on their truck for their livelihood. Being locked out of an 18-wheeler can cause
                                significant delays and disrupt scheduled deliveries, leading to significant financial losses. Fortunately, there are experienced locksmiths who specialize in handling
                                18-wheeler lockouts and can help get drivers back on the road in no time. These professionals have the right tools and expertise to unlock even the most advanced locking
                                systems used in modern trucks. With their fast response times and efficient services, they can quickly resolve any lockout situation, enabling drivers to get back to their
                                work and meet their delivery schedules. Overall, dealing with an 18-wheeler lockout can be stressful, but with the right help, it's possible to overcome this challenging
                                situation and get back to the job at hand.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="row AutomotiveBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5 mb-5">
                        <div className="row mb-5 mt-5">
                            <div className="borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2 pb-4">Affordable Automotive Locksmith In Springville?</h2>
                                <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Truck} className="img-thumbnail-auto img-thumbnail" alt="Locksmith opening a locked truck" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Truck Lockout
                            </h2>
                            <p className="font-size-16">
                                Getting locked out of a truck can be a frustrating and stressful experience, especially when it happens on a busy day or far from home. In such cases, it's essential to
                                contact a reliable and experienced truck locksmith who can help you quickly and efficiently. A professional truck locksmith can handle various types of truck lockouts, from
                                semi-trucks to heavy-duty pickups, and can offer a range of services, including key duplication, lock rekeying, and lock replacement. They also have the necessary tools and
                                expertise to handle sophisticated truck locking systems and ensure that your vehicle's integrity remains intact while getting you back on the road as soon as possible. So,
                                if you ever find yourself in a truck lockout situation, don't hesitate to contact a trusted truck locksmith to get the help you need.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Trunk Lockout
                            </h2>
                            <p className="font-size-16">
                                A trunk lockout can be a frustrating and stressful experience, especially when you have important items locked inside. Whether you accidentally locked your keys in the
                                trunk or the lock is malfunctioning, a professional locksmith can help you regain access. With specialized tools and techniques, they can safely and quickly unlock your
                                trunk without causing any damage to your vehicle. It's important to avoid attempting to force open the trunk yourself as it may cause damage to the lock or surrounding area,
                                leading to costly repairs. Instead, contact a reliable and experienced locksmith to assist you in resolving the issue in a timely and efficient manner.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default VehicleLockoutComponent;
