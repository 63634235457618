import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const AlpineLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleAlpine}
                description={setting.seoHelmentDescriptionAlpine}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeAlpine} />
            <div className="row alpineBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Mobile Locksmith Alpine, Utah</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        Looking for a reliable locksmith in Alpine? Look no further than Captain Lock and Key! Our team of expert technicians is equipped with the latest tools and
                        technology to provide top-quality locksmith services for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        We offer a comprehensive list of services, including:
                    </p>
                    <p className="font-size-16 ms-3">
                        Residential Locksmith Services: We provide a range of residential locksmith services, including lock installation, repair, and rekeying. Our technicians can also
                        help you with key duplication, mailbox locks, and more.
                    </p>
                    <p className="font-size-16 ms-3">
                        Commercial Locksmith Services: Our commercial locksmith services include high-security lock installation, access control systems, and master key systems. We can
                        help you secure your business and protect your assets.
                    </p>
                    <p className="font-size-16 ms-3">
                        Automotive Locksmith Services: Our automotive locksmith services include car key duplication, transponder key programming, and ignition repair. We can get you
                        back on the road in no time!
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we understand that security is important to you. That's why we provide fast and friendly service, so you can have peace of mind knowing
                        that your locks are in good hands.
                    </p>
                    <p className="font-size-16">
                        We are proud to be Alpine's trusted locksmith. Contact us today to schedule an appointment or to receive a free quote for our services. We're available 24/7 to
                        handle any locksmith emergency.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row alpineBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    When it comes to your vehicle, you need a locksmith that you can trust. Our automotive locksmith services provide fast, reliable solutions
                                    to any lock and key issue you may be experiencing. Our team of highly trained technicians has the expertise to work on all types of vehicles,
                                    whether it's a car, truck, motorcycle or RV. Whether you've lost your keys, locked them in your vehicle, or need new keys made, we've got you
                                    covered. We use state-of-the-art equipment to ensure that we can handle any automotive lock and key situation, quickly and efficiently. Our
                                    24/7 availability means that you can count on us to be there whenever you need us, day or night. Don't let a lockout or lost key ruin your day
                                    - call us now and let us provide you with top-quality automotive locksmith services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Lockout</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you've ever been locked out of your car, you know how frustrating and stressful it can be. Fortunately, our car lockout services are
                                    designed to provide you with fast and reliable solutions to get you back on the road in no time. Our team of experienced locksmiths has
                                    the expertise to handle any type of car lockout situation, whether you've lost your keys, locked them in your car, or have a malfunctioning
                                    lock. We use state-of-the-art equipment to ensure that we can open your car quickly and without causing any damage. Our 24/7 availability
                                    means that you can count on us to be there whenever you need us, day or night. Don't let a car lockout ruin your day - call us now and let us
                                    provide you with top-quality car lockout services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Residential Locksmith</h2>
                                <p className="font-size-16 text-start ms-5">
                                    As a homeowner, the safety and security of your family and belongings is your top priority. That's why our residential locksmith services are
                                    designed to provide you with the peace of mind you need. Whether you need new locks installed, locks repaired, or keys duplicated, our team of
                                    skilled technicians is here to help. We understand that every home is unique, which is why we offer custom solutions tailored to your specific
                                    needs and budget. We use only the highest quality materials and the latest technology to ensure that your home is protected against theft and
                                    break-ins. Our 24/7 availability means that you can count on us to be there whenever you need us, day or night. Don't leave the security of
                                    your home to chance - call us today and let us provide you with top-quality residential locksmith services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Fresh Installation Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    When it comes to your home or business security, a fresh installation is one of the most important steps you can take. Our fresh installation
                                    services are designed to provide you with the highest level of security possible. Whether you're looking to install new locks, upgrade your
                                    security system, or replace old and worn-out hardware, our team of skilled technicians is here to help. We use only the highest quality
                                    materials and the latest technology to ensure that your property is protected against theft and break-ins. Our custom solutions are tailored
                                    to your specific needs and budget, ensuring that you get the best possible protection for your investment. Our 24/7 availability means that
                                    you can count on us to be there whenever you need us, day or night. Don't leave the security of your property to chance - call us today and
                                    let us provide you with top-quality fresh installation services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Master Key Systems</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Master key systems are an effective and convenient way to increase the security of your property while allowing you to maintain control
                                    over who has access to certain areas. With our master key systems, you can easily grant or restrict access to different areas of your home
                                    or business, while eliminating the need for multiple keys. Our team of experienced technicians can design and install a custom master key
                                    system that meets your specific needs and budget. We use only the highest quality materials and state-of-the-art technology to ensure that
                                    your property is protected against theft and break-ins. Our 24/7 availability means that you can count on us to be there whenever you need
                                    us, day or night. Don't leave the security of your property to chance - call us today and let us provide you with top-quality master key
                                    system services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Commercial Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    As a business owner, the security and safety of your property, employees, and assets are of utmost importance. Our commercial locksmith
                                    services provide the highest level of protection for your business. We understand that every business is unique, which is why we offer
                                    custom solutions tailored to your specific needs and budget. Whether you need new locks installed, locks rekeyed, or a full security
                                    system installed, our team of skilled technicians has the expertise to provide you with the best possible solutions. We use only the
                                    highest quality materials and state-of-the-art technology to ensure that your business is protected against theft and break-ins. Our
                                    24/7 availability means that you can count on us to be there whenever you need us, day or night. Don't leave the security of your
                                    business to chance - call us today and let us provide you with top-quality commercial locksmith services that you can rely on.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Transponder Key Programming</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Transponder keys are a convenient and secure way to keep your vehicle safe from theft. Our transponder key programming services are
                                    designed to provide you with the best possible protection for your vehicle. Whether you need a new transponder key programmed or an
                                    existing one reprogrammed, our team of skilled technicians has the expertise to get the job done quickly and efficiently. We use only
                                    the highest quality equipment and technology to ensure that your transponder key is programmed correctly and works flawlessly. Our
                                    24/7 availability means that you can count on us to be there whenever you need us, day or night. Don't take chances with your vehicle's
                                    security - call us today and let us provide you with top-quality transponder key programming services that you can rely on.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default AlpineLocksmithComponent;
