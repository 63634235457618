import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import ALX from "../../gallery/Key-ALX.webp";
import MortiseRepair from "../../gallery/Mortise-mechanism-repair.webp";
import BestInstall from "../../gallery/best-keying.jpg";
import MortiseRekey from "../../gallery/Rekey-Mortse-Cylinder.jpg";
import MortiseReplace from "../../gallery/Locksmith-changing-a-mortise-cylinder.jpg";
import BestKeys from "../../gallery/best_key.webp";
import KeyRing from "../../gallery/key_ring.jpg";

const CommercialInsatallComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleInstall}
                description={setting.seoHelmentDescriptionInstall}
                name={setting.seoHelmentName}
                type="webpage" />
            <div className="row CommercialLockInstallBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Commercial Lock Installation and Repair</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3 justify-content-center">
                        <div className="col-sm-7">
                            <p className="font-size-16">
                                Commercial lock installation and repair is an essential service for any business that values its security. As a business owner, you need to ensure that your property is
                                well-protected, and commercial locks play a crucial role in keeping your business safe from unauthorized access. Professional locksmiths are trained to install and repair
                                a wide range of commercial locks, including deadbolts, padlocks, mortise locks, and more. They can assess your business's security needs and recommend the best locks to
                                meet those needs. In addition to installation, professional locksmiths also offer repair services to ensure that your locks are functioning properly. They can replace
                                damaged parts, rekey locks, or upgrade the locks to higher security options, depending on your needs. With a properly installed and maintained commercial lock, you can have
                                peace of mind knowing that your business is secure and protected. Contact a professional locksmith today to schedule a commercial lock installation or repair service and
                                take control of your business's security.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5 pb-5 bg-light justify-content-center">
                        <div className="col-sm-8">
                            <h2 className="fw-bolder font-size-20">
                                New Business Lock Installation
                            </h2>
                            <p className="font-size-16">
                                New business lock installation is an important security measure that can help protect your property and assets. When starting a new business, it is crucial to ensure that
                                the premises are secure and that only authorized personnel have access. Professional locksmiths are trained to install a wide range of commercial locks, including deadbolts,
                                padlocks, mortise locks, and more. They can assess your business's security needs and recommend the best locks to meet those needs. A professional locksmith can also offer
                                advice on security measures such as access control systems, security cameras, and alarms. With a properly installed commercial lock, you can have peace of mind knowing that
                                your business is secure and protected. The installation process is quick and efficient, and professional locksmiths can also provide additional services such as key
                                duplication and rekeying, ensuring that you have complete control over access to your property. Contact a professional locksmith today to schedule a new business lock
                                installation service and take the first step towards securing your business.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={MortiseRekey} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith installing a mortise cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Mortise Lock Installation
                            </h2>
                            <p className="font-size-16">
                                Mortise lock installation is an important security measure that can help protect your property and assets. Mortise locks are a type of lock commonly used in commercial and
                                high-security applications due to their durability and reliability. Professional locksmiths are trained to install mortise locks quickly and efficiently, ensuring that your
                                property is secured with minimal disruption to your daily operations. The installation process involves cutting a pocket, or mortise, into the door and frame to accommodate
                                the lock mechanism, which includes a deadbolt and latch. Mortise locks are also available in a variety of finishes, making it easy to match the lock to your existing door
                                hardware. A professional locksmith can also offer advice on security measures such as access control systems, security cameras, and alarms. With a properly installed mortise
                                lock, you can have peace of mind knowing that your property is secure and protected. Contact a professional locksmith today to schedule a mortise lock installation service
                                and take the first step towards securing your property.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={BestInstall} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith is installing a Best IC cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                IC core Installation
                            </h2>
                            <p className="font-size-16">
                                IC core installation is a type of locking system that is commonly used in commercial and high-security applications. An IC core lock consists of a removable core that can
                                be easily changed or replaced without having to replace the entire lock. This makes it an ideal choice for businesses that need to frequently change access to their property
                                or facilities. IC core installation is a quick and efficient process that involves inserting the core into the lock mechanism and securing it in place. Professional
                                locksmiths are trained to install IC core locks and can offer advice on security measures such as access control systems, security cameras, and alarms. With a properly
                                installed IC core lock, you can have peace of mind knowing that your property is secure and protected. IC core locks are also available in a variety of finishes, making
                                it easy to match the lock to your existing door hardware. Contact a professional locksmith today to schedule an IC core installation service and take the first step towards
                                securing your property.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={ALX} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith is installing lever on commercial door" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Commercial Door Lever Installation
                            </h2>
                            <p className="font-size-16">
                                Commercial door lever installation is an essential aspect of ensuring the security and accessibility of commercial buildings. A door lever is a crucial part of a commercial
                                door system that allows easy and convenient access for employees and customers while maintaining the security of the property. Proper installation of a commercial door
                                lever ensures that it functions correctly and meets safety regulations. Installation of a commercial door lever requires careful consideration of the door type, hardware
                                compatibility, and ADA compliance. Professional installation services can help ensure that the door lever is installed correctly and performs well for many years. Overall,
                                commercial door lever installation is an important part of maintaining the safety and accessibility of a commercial building.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row CommercialInstallationBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5 mb-5">
                        <div className="row mb-5 mt-5">
                            <div className="borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2 pb-4">Affordable Commercial Locksmith In Springville?</h2>
                                <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row pt-5 pb-5 bg-light justify-content-center">
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Business Lock Repair
                            </h2>
                            <p className="font-size-16">
                                Business lock repair is a critical aspect of maintaining the security of a commercial property. A damaged or malfunctioning lock can compromise the safety and integrity of
                                a business and put its assets and employees at risk. Professional lock repair services can quickly identify and fix issues with locks, ensuring that they are working
                                correctly and providing the necessary security for the business. Repairing a lock is often more cost-effective than replacing it entirely, which is why it is essential to
                                address any issues with locks as soon as they arise. Lock repair services can also help identify any potential vulnerabilities in a business's security system and provide
                                recommendations to strengthen it further. Overall, business lock repair is an important investment in maintaining the safety and security of a commercial property.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={MortiseRepair} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith is fixing a mortise cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Mortise Mechanism Repair
                            </h2>
                            <p className="font-size-16">
                                Mortise mechanism repair is an essential service for those who have doors with a mortise lock. The mortise lock mechanism is a more complex lock than the standard lock and
                                requires a professional repair technician to ensure it works effectively. A damaged or worn-out mortise lock mechanism can cause the door to become difficult to open or
                                close, compromising the security of a building. The repair process may include disassembling the lock mechanism, repairing or replacing worn-out parts, and reassembling the
                                lock. Professional repair technicians have the necessary tools and knowledge to diagnose and repair any problems with the mortise mechanism effectively. Mortise mechanism
                                repair services can also identify any potential issues with the lock that may compromise the safety of the property and make recommendations for improvements. In summary,
                                if you have a mortise lock that is not functioning correctly, it is crucial to seek the help of a professional repair technician to ensure the safety and security of your
                                property.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={MortiseReplace} className="img-thumbnail-auto img-thumbnail" alt="Locksmith is replacing a mortise cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Mortise Mechanism Replacement
                            </h2>
                            <p className="font-size-16">
                                Mortise mechanism replacement is a necessary service for those who have a damaged or outdated mortise lock mechanism. Mortise locks are often found in older buildings and
                                can wear out over time or become damaged due to heavy use or attempted break-ins. A malfunctioning mortise lock mechanism can compromise the safety and security of a
                                building, making it essential to replace it with a new, functioning mechanism. The replacement process may include removing the old lock, replacing it with a new one, and
                                ensuring that the new lock is correctly installed and working correctly. A professional replacement service can help identify the right mortise lock mechanism for a building
                                and ensure that it is installed correctly to ensure the safety and security of the property. Mortise mechanism replacement can also provide the opportunity to upgrade a
                                building's security system with a more advanced lock mechanism. In summary, if you have a damaged or outdated mortise lock mechanism, it is crucial to seek the help of a
                                professional replacement service to ensure the safety and security of your property.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row mt-5 pt-5 pb-5 justify-content-center bg-light">
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Business Key Extraction
                            </h2>
                            <p className="font-size-16">
                                Business key extraction is the process of identifying the essential elements that define a business, allowing for more efficient and effective decision-making. This
                                involves analyzing the various components of a business, including its products and services, organizational structure, customers and target audience, and competitive
                                landscape. By extracting the key factors that contribute to a business's success or failure, stakeholders can better understand how to allocate resources, identify areas
                                for improvement, and develop strategies to achieve long-term growth. Business key extraction can be a complex and iterative process, requiring a deep understanding of both
                                the internal and external factors that impact a business. However, with the right tools and expertise, it can be a powerful tool for driving business success.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={BestKeys} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith removed a broken key then replaced it with a new one" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                IC core Key Extraction
                            </h2>
                            <p className="font-size-16">
                                IC core key extraction is a specialized technique used to extract and decode the keys used in Interchangeable Core (IC) lock systems. IC core locks are commonly used in
                                commercial and industrial settings, offering increased security and flexibility through the ability to quickly replace a lock core without changing the entire lock. However,
                                when a key is lost or damaged, it can be difficult to extract and decode the IC core to create a replacement key. Skilled locksmiths use specialized tools and techniques to
                                remove the IC core, decode the pinning system, and create a new key. This process requires precision and expertise, as even small errors in the decoding process can render
                                the new key ineffective. IC core key extraction is an essential service for businesses and organizations that rely on IC core locks, allowing them to quickly and effectively
                                regain access to secure areas.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={KeyRing} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith removed a broken key from a mortise cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Mortise Lock Key Extraction
                            </h2>
                            <p className="font-size-16">
                                Mortise lock key extraction is a specialized locksmith technique used to remove broken or stuck keys from mortise locks. Mortise locks are commonly used in residential and
                                commercial settings, and are known for their reliability and durability. However, like all locks, they can be susceptible to key breakage or getting stuck, which can be a
                                frustrating and potentially costly issue. Skilled locksmiths use specialized tools to remove the broken or stuck key from the mortise lock, while carefully avoiding damage
                                to the lock mechanism itself. This process requires precision and expertise, as even minor damage to the lock can compromise its security and functionality. Mortise lock
                                key extraction is an essential service for property owners and managers, allowing them to quickly and effectively resolve issues with their mortise locks and maintain the
                                security of their property.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommercialInsatallComponent;
