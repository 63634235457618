import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const GenolaLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleGenola}
                description={setting.seoHelmentDescriptionGenola}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeGenola} />
            <div className="row springvilleBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Genola Locksmith Services</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5 pt-3">
                    <h2 className="fw-bold mt-5 mb-3 font-size-18">Welcome to Captain Lock and Key - Your Trusted Genola Locksmith</h2>
                    <p className="font-size-16">
                        Looking for a reliable locksmith in Genola? Look no further than Captain Lock and Key. Our team of experienced technicians is equipped with the latest tools
                        and technology to provide top-quality locksmith services for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        We offer a full range of residential, commercial, and automotive locksmith services, including lock repair, rekeying, and installation, high-security locks,
                        access control systems, and car key duplication, transponder key programming, and ignition repair.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we understand the importance of security for you and your property. That's why we strive to provide fast and friendly service, so
                        you can have peace of mind knowing that your locks are in good hands. We take pride in being Genola's trusted locksmith.
                    </p>
                    <p className="font-size-16">
                        Contact us today to schedule an appointment or to receive a free quote for our services. Don't trust your security to just anyone - trust the experts at
                        Captain Lock and Key.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row springvilleBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Master Rekey Service</h2>
                                    <p className="font-size-16 text-start ms-5">
                                        Master Rekey Service is a process that allows you to change the locks on all doors in a building with a single key.
                                        This service is typically used by property managers or owners who want to have control over access to their building.
                                        A Master Rekey Service involves rekeying all of the locks in a building so that they work with one key, making it easier
                                        to manage access and ensure security. This process is typically performed by a locksmith and can be done on all types of locks,
                                        including deadbolts, knob locks, and lever locks. The Master Rekey Service ensures that all locks in the building are secure,
                                        and that access to the building can be controlled by a single key. This service is cost-effective, efficient, and provides
                                        peace of mind for property managers and owners.
                                    </p>
                             </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Quality Commercial Hardware</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Quality Commercial Hardware refers to durable, high-performance hardware products designed for use
                                    in commercial buildings. These products are engineered to meet strict standards for security, reliability,
                                    and performance in commercial environments. Quality Commercial Hardware includes items such as door locks,
                                    hinges, door closers, and other related products that are critical to the security and functionality of
                                    commercial buildings. These products are made with premium materials and are built to withstand heavy use,
                                    making them ideal for use in high-traffic areas. In addition, quality commercial hardware is designed with
                                    features such as corrosion resistance and weather-tight seals, making it suitable for use in a wide range of
                                    commercial environments. Overall, Quality Commercial Hardware is an essential component of any commercial building
                                    and is a key factor in ensuring the safety and security of its occupants.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Fresh Installation</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Fresh Installation locksmith services refer to the installation of new locks in a building or property.
                                    This service is typically performed when the existing locks are outdated, damaged, or have become unreliable.
                                    A Fresh Installation locksmith will remove the old locks and replace them with new, high-quality locks that
                                    are designed to provide increased security and reliability. The locksmith will ensure that the new locks are
                                    installed correctly and work properly, and will also provide the customer with new keys. Fresh Installation
                                    locksmith services can include the installation of deadbolts, knob locks, lever locks, and other types of locks,
                                    depending on the customer's needs and preferences. This service is ideal for property managers, homeowners,
                                    and businesses who want to ensure that their property is secure and that access is controlled. With a Fresh
                                    Installation locksmith service, customers can enjoy peace of mind knowing that their locks have been installed by a professional.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Lockout</h2>
                                <p className="font-size-16 text-start ms-5">
                                    A car lockout refers to a situation in which a person is unable to access their car because they have
                                    lost or locked their keys inside. This can be a frustrating and stressful experience, especially if
                                    it occurs at an inconvenient time or place. A car lockout can be resolved by a locksmith who
                                    specializes in car lockouts. The locksmith will use specialized tools to open the car without
                                    causing damage to the vehicle. In some cases, the locksmith may be able to create a new key on
                                    the spot, allowing the driver to regain access to their car immediately. If the keys have been lost,
                                    the locksmith can also make a new set of keys, allowing the driver to get back on the road.
                                    Car lockouts are a common problem, but with the help of a professional locksmith, they can be resolved quickly and efficiently.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Business Lockout</h2>
                                <p className="font-size-16 text-start ms-5">
                                    A business lockout refers to a situation in which a business owner or employee is unable to access their building
                                    or commercial property because they have lost or locked their keys inside. This can be a major inconvenience and can
                                    prevent the business from operating normally, potentially leading to lost profits and productivity. A business lockout
                                    can be resolved by a locksmith who specializes in commercial lockouts. The locksmith will use specialized tools to open
                                    the building without causing damage to the door or lock. In some cases, the locksmith may be able to create a new key on
                                    the spot, allowing the business to resume operation immediately. If the keys have been lost, the locksmith can also rekey
                                    the locks or install new locks to ensure that the business is secure. Business lockouts are a common problem, but with the
                                    help of a professional locksmith, they can be resolved quickly and efficiently, minimizing the impact on the business and its employees.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Key Programming</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Car Key Programming is the process of programming a new car key or a replacement key to work with a specific make and model
                                    of a vehicle. This process is necessary because modern car keys often contain a transponder chip that must be programmed to
                                    communicate with the vehicle's onboard computer. Car Key Programming requires specialized tools and expertise, and is typically
                                    performed by a professional locksmith or a dealership. The process involves connecting the new key to the vehicle's computer, inputting
                                    the key's unique code, and then testing the key to ensure that it works properly. Car Key Programming can also involve programming
                                    a remote key fob, which allows the driver to unlock and start the car from a distance. With Car Key Programming, drivers can
                                    enjoy the convenience and security of having a working key that is specifically designed to work with their vehicle.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10">
                    <h2 className="fw-bold mt-5 mb-3 font-size-20 text-center">Genola Residential Locksmith</h2>
                    <p className="font-size-16 text-center">
                        If you are looking for top-quality residential locksmith services in Genola, look no further than our locksmith company.
                        Our team of skilled and experienced technicians is available 24/7 to help you with any residential lock or key problem,
                        including lock installation, repair, and rekeying, as well as emergency lockout services. We use the latest technology
                        and techniques to ensure that we can handle any type of residential lock or key, including high-security systems and
                        smart locks. Our residential locksmith services are designed to help you keep your home safe and secure, with fast and
                        reliable service to minimize any inconvenience. With our residential locksmith services, you can have peace of mind
                        knowing that your home is protected by the best in the business. Contact us today to schedule a consultation and experience
                        the convenience and reliability of our high-quality residential locksmith services in Genola. We also provide commercial and
                        automotive locksmith services in Genola, including access control systems, master key systems, and car key replacement. Trust
                        our team of skilled professionals to provide you with top-quality locksmith services in Genola.
                    </p>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10">
                    <h2 className="fw-bold mb-3 font-size-20 text-center">Genola Automotive Locksmith</h2>
                    <p className="font-size-16 text-center">
                        If you need automotive locksmith services in Genola, our locksmith company is here to help. Our team of skilled and experienced
                        technicians is available 24/7 to assist you with any automotive lock or key problem, including car key replacement, transponder
                        key programming, and emergency lockout services. We use the latest technology and techniques to ensure that we can handle any type
                        of automotive lock or key, including high-security systems and smart locks. Our automotive locksmith services are designed to help
                        you get back on the road quickly and safely, with fast and reliable service to minimize any inconvenience. With our automotive
                        locksmith services, you can have peace of mind knowing that your vehicle is protected by the best in the business. Contact us
                        today to schedule a consultation and experience the convenience and reliability of our high-quality automotive locksmith services
                        in Genola. We also provide residential and commercial locksmith services in Genola, including lock installation, repair, and
                        rekeying for homes and businesses. Trust our team of skilled professionals to provide you with top-quality locksmith services in Genola.
                    </p>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10">
                    <h2 className="fw-bold mb-3 font-size-20 text-center">Genola Commercial Locksmith</h2>
                    <p className="font-size-16 text-center">
                        If you are a business owner in Genola, our locksmith company is here to provide you with top-quality commercial locksmith services to help you
                        protect your assets and secure your property. Our team of skilled and experienced technicians is available 24/7 to help you with any commercial
                        lock or key problem, including lock installation, repair, and rekeying, as well as access control systems and master key systems. We use the
                        latest technology and techniques to ensure that we can handle any type of commercial lock or key, including high-security systems and smart locks.
                        Our commercial locksmith services are designed to help you keep your business safe and secure, with fast and reliable service to minimize any
                        downtime or lost productivity. With our commercial locksmith services, you can have peace of mind knowing that your business is protected by the
                        best in the business. Contact us today to schedule a consultation and experience the convenience and reliability of our high-quality commercial
                        locksmith services in Genola. We also provide residential and automotive locksmith services in Genola, including emergency lockout services, car
                        key replacement, and more. Trust our team of skilled professionals to provide you with top-quality locksmith services in Genola.
                    </p>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10">
                    <h2 className="fw-bold mb-3 font-size-20 text-center">Contact A Local Locksmith In Genola</h2>
                    <p className="font-size-16 text-center">
                        If you are looking for a trusted and reliable local locksmith in Genola, look no further than our locksmith company. Our team of skilled and experienced
                        technicians is available 24/7 to help you with any lock or key problem, whether it's residential, commercial, or automotive. We use the latest technology
                        and techniques to ensure that we can handle any type of lock or key, including high-security systems and smart locks. Our locksmith services are designed
                        to provide you with fast, reliable, and convenient solutions to any lock or key problem, with a focus on customer satisfaction and quality service. We
                        pride ourselves on providing top-quality locksmith services at affordable prices, with upfront and transparent pricing so you know exactly what you are
                        paying for. Contact us today to schedule a consultation and experience the convenience and reliability of our high-quality locksmith services in Genola.
                        Whether you need emergency lockout services, lock repair or installation, or key duplication, our team of skilled professionals is here to provide you with
                        top-quality locksmith services in Genola. Trust our team of skilled professionals to provide you with the best locksmith services in Genola.
                    </p>
                </div>
            </div>
            <div className="mt-3 pt-5 pb-5 col-md-12 text-dark bg-light text-center">
                <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
            </div>
        </div>
    );
};

export default GenolaLocksmithComponent;
