import axios from "axios";
import AmericanForkLocksmithComponent from "./components/AmericanForkLocksmithComponent";

const AmericanForkLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <AmericanForkLocksmithComponent fetchSetting={fetchSetting} />;
};

export default AmericanForkLocksmithPage;
