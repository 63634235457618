import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const SpanishForkLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleSpanishFork}
                description={setting.seoHelmentDescriptionSpanishFork}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeSpanishFork} />
            <div className="row spanishForkBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Spanish Fork Locksmith Services</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        When you need professional locksmith services in Spanish Fork, trust Captain Lock and Key to provide top-notch solutions. Our team of skilled
                        technicians is equipped with the latest tools and technology to handle all your locksmith needs for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        We offer a comprehensive range of services, including lock repair, rekeying, and installation for residential properties. For businesses, we provide
                        a full range of commercial locksmith services, including high-security locks, access control systems, and master key systems. Our expert technicians
                        are also equipped to handle automotive locksmith services, including car key duplication, transponder key programming, and ignition repair.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we understand the importance of security for you and your property. That's why we work hard to provide fast and friendly
                        service, so you can have peace of mind knowing that your locks are in good hands. Our commitment to quality and customer satisfaction has made us
                        the trusted locksmith of Spanish Fork.
                     </p>
                    <p className="font-size-16">
                        Contact us today to schedule an appointment or to receive a free quote for our services. Let us help you secure your property and give you the peace
                        of mind you deserve with our professional locksmith services in Spanish Fork.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row spanishForkBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Spanish Fork Automotive Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Automotive services refer to a range of locksmith services specifically designed for vehicles.
                                    These services are essential for individuals who have lost their car keys, locked themselves out of
                                    their vehicle, or have broken keys. A professional automotive locksmith can offer a variety of services,
                                    including car lockout services, car key duplication, car key programming, and ignition repair. They have
                                    the necessary tools, knowledge, and experience to quickly and efficiently solve any automotive locksmith
                                    issue without damaging your vehicle. With their mobile services, automotive locksmiths can reach you anywhere,
                                    at any time, to provide fast and reliable assistance. Whether you need a duplicate key for your car, have locked
                                    yourself out of your vehicle, or need to repair your ignition, a professional automotive locksmith is always
                                    available to assist you with all your automotive locksmith needs. Trusting an experienced and licensed locksmith
                                    is the best way to ensure that your vehicle is in good hands and that your locksmith needs are met efficiently and effectively.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Spanish Fork Rekey Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Rekey services refer to the process of changing the internal mechanism of a lock so that it can be opened with a different key.
                                    This process is often used as an alternative to replacing a lock, especially when keys have been lost or stolen, or when a
                                    change in occupancy or security needs has occurred. A professional locksmith can perform rekey services on a variety of locks,
                                    including residential locks, commercial locks, and car locks. They have the necessary tools, knowledge, and experience to quickly
                                    and efficiently rekey locks without damaging the lock or the surrounding area. With their mobile services, locksmiths can reach
                                    you anywhere, at any time, to provide fast and reliable rekey services. Rekeying is a cost-effective solution for ensuring the
                                    security of your property and can give you peace of mind knowing that your locks have been changed and that previous keys are no
                                    longer functional. Whether you need to rekey your locks for security purposes or as part of a larger security upgrade, a professional
                                    locksmith is always available to assist you with all your rekey services needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Spanish Fork Commercial Locksmith</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Commercial locksmith services refer to a range of security solutions and services specifically designed for businesses and
                                    commercial properties. These services are crucial in ensuring the safety and security of employees, customers, and company
                                    assets. A professional commercial locksmith can offer a variety of services, including lock installation, rekey services, key
                                    duplication, security camera installation, and access control systems. They can help assess your specific security needs and
                                    recommend the most appropriate solution for your budget, business type, and security requirements. With their extensive knowledge
                                    and experience in commercial security systems, locksmiths are the trusted providers of quality commercial locksmith services.
                                    They are committed to providing reliable and efficient services, ensuring that businesses have peace of mind when it comes to
                                    the security of their properties. Whether you need to improve your business's security or are locked out of your office, a
                                    professional commercial locksmith is always available to assist you with all your security needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Spanish Fork Car Lockouts</h2>
                                <p className="font-size-16 text-start ms-5">
                                    A car lockout refers to a situation where a person is unable to gain access to their vehicle due to a lost or broken key,
                                    a locked door, or a malfunctioning lock. This can be a frustrating and stressful situation, especially if you are in a hurry
                                    or it occurs in a public place. In these cases, it's essential to have the contact information of a professional locksmith
                                    who specializes in car lockouts. A car locksmith has the tools, expertise, and experience to quickly and efficiently open
                                    your car without causing damage to the vehicle. They can also help with other car lock-related problems, such as broken keys
                                    or locks, key duplication, and car key programming. With their mobile services, a car locksmith can reach you anywhere, at
                                    any time, to provide prompt and reliable assistance. Whether you're locked out of your car, need a spare key, or need to
                                    replace a broken key, a professional car locksmith is the solution for your car lockout needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Spanish Fork Business Lockouts</h2>
                                <p className="font-size-16 text-start ms-5">
                                    A business lockout refers to a situation where a person is unable to gain access to their commercial property due to a lost or
                                    broken key, a locked door, or a malfunctioning lock. This can be a significant inconvenience, especially if it occurs during
                                    business hours or if you have important appointments or deadlines. In these cases, it's essential to have the contact information
                                    of a professional locksmith who specializes in business lockouts. A commercial locksmith has the tools, expertise, and experience
                                    to quickly and efficiently open your business without causing damage to the property. They can also help with other business lock-related
                                    problems, such as broken keys or locks, key duplication, and lock rekey services. With their 24/7 services, a commercial locksmith can
                                    reach you at any time, to provide prompt and reliable assistance. Whether you're locked out of your business, need a spare key, or need
                                    to replace a broken lock, a professional commercial locksmith is the solution for your business lockout needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Spanish Fork Residential Locksmiths</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Residential locksmith services refer to a range of security solutions and services specifically designed for homes
                                    and residential properties. These services are crucial in ensuring the safety and security of families, their possessions,
                                    and property. A professional residential locksmith can offer a variety of services, including lock installation, rekey
                                    services, key duplication, security camera installation, and home safes. They can help assess your specific security
                                    needs and recommend the most appropriate solution for your budget, lifestyle, and security requirements. With their
                                    extensive knowledge and experience in residential security systems, locksmiths are the trusted providers of quality
                                    residential locksmith services. They are committed to providing reliable and efficient services, ensuring that families
                                    have peace of mind when it comes to the security of their homes. Whether you need to improve your home's security or are
                                    locked out of your house, a professional residential locksmith is always available to assist you with all your security needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default SpanishForkLocksmithComponent;
