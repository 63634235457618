import axios from 'axios'
import { useDispatch } from "react-redux";
import { uploadImagesApiRequest, uploadImagesCloudinaryApiRequest } from "./utils/utils";
import EditPostPageComponent from "./components/PostEditPageComponent";

const fetchPosts = async (postId) => {
    const { data } = await axios.get(`/api/posts/get-one/${postId}`);
    return data;
}

const updatePostApiRequest = async (postId, formInputs) => {
    const { data } = await axios.put(`/api/posts/admin/${postId}`, { ...formInputs });
    return data;
}



const AdminEditPostPage = () => {

    const reduxDispatch = useDispatch();

    const imageDeleteHandler = async (imagePath, postId) => {
        let encoded = encodeURIComponent(imagePath)
        if (process.env.NODE_ENV !== "production") {
            // to do: change to !==
            await axios.delete(`/api/posts/admin/image/${encoded}/${postId}`);
        } else {
            await axios.delete(`/api/posts/admin/image/${encoded}/${postId}?cloudinary=true`);
        }
    }

    return <EditPostPageComponent fetchPosts={fetchPosts} updatePostApiRequest={updatePostApiRequest} reduxDispatch={reduxDispatch} imageDeleteHandler={imageDeleteHandler} uploadImagesApiRequest={uploadImagesApiRequest} uploadImagesCloudinaryApiRequest={uploadImagesCloudinaryApiRequest} />;
};

export default AdminEditPostPage;