import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import PushBar from "../../gallery/Push-bar-install.webp";
import Card from "../../gallery/Card-logos.png";
import KIK from "../../gallery/KIK_Cylinder.jpg";
import Install from "../../gallery/best-keying.jpg";
import MasterKey from "../../gallery/Schlage-master-key.jpg";
import Access from "../../gallery/Access-control-solutions.jpg";

const CommercialLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100 m-0 p-0">
            <MetaComponent
                title={setting.seoHelmentTitleComm}
                description={setting.seoHelmentDescriptionComm}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeComm} />
            <div className="row CommercialBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Commercial Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Springville Commercial Locksmith Services</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5 pt-5 bg-light">
                <div className="col-md-7">
                    <p className="font-size-16">
                        At Captain Lock and Key, we offer top-notch commercial locksmith services in Springville and the surrounding areas. Our team of skilled locksmith technicians
                        is dedicated to providing comprehensive locksmith services to local businesses, big and small. We have the expertise, equipment, and experience to handle
                        any commercial locksmith job, from rekeying locks to installing high-tech access control systems.
                    </p>
                    <p className="font-size-16">
                        We understand that the safety and security of your business is crucial, which is why we offer 24/7 emergency commercial locksmith services. Whether you have
                        lost your keys, need to rekey your locks, or want to upgrade your security system, our team is always ready to assist you.
                    </p>
                    <p className="font-size-16">
                        Our commercial locksmith services in Springville include, but are not limited to:
                    </p>
                    <ul className="font-size-16">
                        <li>Master key systems</li>
                        <li>Access control systems</li>
                        <li>High-security locks and keys</li>
                        <li>Rekeying services</li>
                        <li>Lock repair and replacement</li>
                        <li>Key duplication</li>
                        <li>Safe installation and repair</li>
                        <li>Panic bars and exit devices</li>
                        <li>Lockout assistance</li>
                    </ul>
                    <p className="font-size-16">
                        We work with businesses in various industries, including offices, schools, medical facilities, retail stores, restaurants, and more. We use only the
                        best quality products and equipment to ensure the security and safety of your business. Our skilled technicians are insured, and bonded, giving
                        you peace of mind knowing your business is in good hands.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we are committed to providing reliable, fast, and affordable commercial locksmith services in Springville. Contact us today for all
                        your commercial locksmith needs.
                    </p>
                    <div className="text-center">
                        <Link to={paths.CONTACT} className="mb-4 btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">
                        
                        <div className="col-sm-4 me-5 ps-5 ms-5">
                            <img src={KIK} className="img-thumbnail" alt="Locksmith rekeying a key in knob cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Commercial Rekey Services
                            </h2>
                            <p className="font-size-16">
                                As a business owner or manager, the security of your commercial property is of utmost importance. If you're renting a new office or retail space or
                                have recently purchased a commercial building, one of the first things you need to do is ensure that the locks on your new location are secure.
                                In some cases, you may need to change access levels when key employees leave your organization. However, changing the entire lock hardware can
                                be time-consuming and expensive. That's where our commercial rekeying services come in. We can quickly and efficiently rekey your existing door
                                hardware to create new keys and render all other outstanding keys ineffective. This can save you time and money while ensuring the security of
                                your business. Additionally, we can provide a master key system to enhance your security even further. Our experienced and skilled technicians
                                have the knowledge and tools necessary to provide you with a fast and reliable rekeying service that meets your specific needs. Contact us today
                                to learn more about our commercial rekeying services in Utah county and take the first step towards securing your commercial property.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5 pb-5 bg-light">
                        <div className="col-sm-4 me-5 ps-5 ms-5">
                            <img src={MasterKey} className="img-thumbnail-comm img-thumbnail" alt="Key bitting for master key system" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Master Key Systems
                            </h2>
                            <p className="font-size-16">
                                Master key systems are an effective solution for businesses of all kinds. These systems provide a convenient way for a single key to access multiple
                                locks while limiting other keys from unlocking specific doors. There are two types of keys used in a master key system: the master key and the change
                                keys. The master key can open every lock within the system, while the change keys can only open the specific lock they are assigned to.
                            </p>
                            <p className="font-size-16">
                                One industry that can greatly benefit from the use of master key systems is property management. Property managers can use a master key to easily
                                access multiple doors and units without carrying separate keys for each unit. This can save time and streamline the process of accessing units for
                                maintenance, cleaning, and inspections. However, master key systems are not limited to property management, as they can be used in both commercial
                                and residential buildings.
                            </p>
                            <p className="font-size-16">
                                At our locksmith company, we specialize in installing and maintaining master key systems for businesses of all kinds. Our skilled technicians can
                                work with you to design and implement a customized master key system that meets your specific needs. Contact us today to learn more about our master
                                key system services and take the first step towards a more efficient and secure access solution for your business.
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="row lindonBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-5 mt-5">
                            <div className="col h-100 borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2">AFFORDABLE COMMERCIAL LOCKSMITH IN SPRINGVILLE?</h2>
                                <p className="font-size-16 text-center">We Accecpt All Major Credit Cards</p>
                                <p className="font-size-16 text-center"><img src={Card} className="img-thumbnail image-comm" alt="Credit Cards accepted by Captain Lock and Key" /></p>
                                <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">

                        <div className="col-sm-3 me-5 ps-5 ms-5">
                            <img src={Install} className="img-thumbnail img-thumbnail-comm" alt="Installing a Best cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Lock Installation and Repair
                            </h2>
                            <p className="font-size-16">
                                Broken or malfunctioning locks can disrupt your business operations and pose a potential security risk. At Captain Lock and Key, we offer comprehensive
                                commercial lock repair services that cover all types of lock hardware and commercial doors. Our experienced locksmiths can quickly diagnose and fix
                                any lock issue to ensure that your business is secure and operational. In addition to repair services, we also provide lock installation services,
                                including high-security locks, access control systems, and push bar systems. Contact us today at (801) 602-8234 to discuss your commercial lock
                                needs and receive professional installation or repair services.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5 pb-5 bg-light">
                        <div className="col-sm-4 me-5 ps-5 ms-5">
                            <img src={PushBar} className="img-thumbnail-comm1 img-thumbnail" alt="Install a push bar on a commercial building" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Push Bar Services and Installation
                            </h2>
                            <p className="font-size-16">
                                A push bar door system is a type of door hardware commonly used in commercial buildings for both standard exit doors and emergency doors. It is designed to ensure safety and security by preventing access to unauthorized areas of the building while keeping the outside locked. When you push the bar from the inside, it releases the lock allowing you to exit the building without the need for a key. Panic doors with push bar systems can activate security or fire alarms in case of emergencies.

                                Adding a push bar door system to your business can offer several advantages. For instance, you'll have fewer doors to lock and unlock each day,
                                eliminating the risk of your employees forgetting to check and lock every door after closing. This system works with all types of doors and door
                                hardware, including wood, metal, aluminum, and glass doors. Furthermore, it can help you save money on commercial insurance by integrating with
                                your security system. A comprehensive security system with a push bar system is likely to lead to lower premiums. Contact a commercial locksmith
                                to install push bar systems and other door hardware to enhance your building's security.
                            </p>
                            
                        </div>
                    </div>
                    <div className="row mt-5 pt-3 pb-5">

                        <div className="col-sm-3 me-5 ps-5 ms-5">
                            <img src={Access} className="img-thumbnail img-thumbnail-comm" alt="Access control prox pad installed on brick building" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Access Control Solutions In Springville
                            </h2>
                            <p className="font-size-16">
                                Access control solutions have become a crucial element in ensuring the security of businesses and residential areas in Springville. Captain Lock and Key provides
                                top-of-the-line access control solutions that will keep your property secure. We offer a wide range of options, including biometric, proximity cards, and keypad
                                access control systems, to fit your unique needs. Our skilled technicians have years of experience in designing and installing access control systems, ensuring that
                                every aspect of your security is covered. Whether you need a new system installed or an existing one upgraded, we provide reliable and cost-effective solutions.
                                We understand that each property has unique security needs, which is why we work with our clients to create customized access control solutions. Contact Captain
                                Lock and Key today to ensure the safety and security of your property in Springville.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommercialLocksmithComponent;
