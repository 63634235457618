import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import FB from "../../gallery/fb.png";
import Angi from "../../gallery/angieslist.png";
import Google from "../../gallery/google.png";

const ResidentialKeyExtractionComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleResKeyExtract}
                description={setting.seoHelmentDescriptionResKeyExtract}
                name={setting.seoHelmentName}
                type="webpage" />
            <div className="row ResidentialKeyExtractBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Residential Key Extraction</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row pb-5 bg-light justify-content-center pt-5">
                        <div className="col-sm-7">
                            <p className="font-size-16">
                                Residential key extraction refers to the process of removing a broken or stuck key from a residential lock. This is a delicate procedure that requires precision and
                                expertise to avoid damaging the lock or causing any harm to the property. Professional locksmiths are trained to use specialized tools to extract keys that are jammed
                                inside a lock without causing any further damage. They will carefully analyze the situation and choose the appropriate tool to remove the key safely and efficiently.
                                After the extraction process is complete, the locksmith will then inspect the lock to ensure that it is still functioning properly and recommend any necessary repairs
                                or replacements. Overall, residential key extraction is an essential service that ensures the security and safety of residential properties.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>

                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Residential Key Extraction
                            </h2>
                            <p className="font-size-16">
                                Residential key extraction is a crucial service that is often required in emergency situations. When a key breaks off inside a residential lock, it can be frustrating and
                                even dangerous. Attempting to extract the key without professional assistance can lead to further damage to the lock and even cause harm to the person attempting to remove
                                the key. That's where a professional locksmith comes in. These experts have the tools and knowledge to safely and efficiently extract the key without causing damage to the
                                lock. They can also provide other services such as rekeying, key duplication, and lock replacement if necessary. Residential key extraction is a valuable service that
                                ensures the security and safety of residential properties and their occupants.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>

                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Lubricating Lock Cylinder
                            </h2>
                            <p className="font-size-16">
                                Lubricating lock cylinders is an important aspect of lock maintenance that should not be overlooked. Over time, lock cylinders can become stiff and difficult to turn,
                                making it challenging to unlock doors. This can be due to a buildup of dirt, dust, and debris inside the cylinder, as well as the natural wear and tear of the lock
                                mechanism. Lubricating the cylinder can help to prevent this issue by reducing friction and allowing the lock to function smoothly. It's important to use a lubricant
                                specifically designed for locks and to apply it sparingly, as too much can attract even more dirt and debris. A professional locksmith can help to determine the best
                                lubricant for your lock and provide guidance on how to properly apply it. By regularly lubricating your lock cylinder, you can ensure that your lock remains in good
                                working condition and provide added security to your home or business.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>

                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Spring and Pin Replacement
                            </h2>
                            <p className="font-size-16">
                                Spring and pin replacement is a common service provided by locksmiths to ensure the proper functioning of locks. Springs and pins are critical components of a lock mechanism
                                that can wear out over time or become damaged due to misuse or tampering. If these components fail, the lock may become difficult to open or even completely non-functional.
                                A locksmith can diagnose the issue and determine whether spring and pin replacement is necessary. They will carefully remove the old parts and replace them with new ones,
                                ensuring that the lock mechanism operates smoothly and securely. It's important to have this service performed by a professional locksmith to avoid causing further damage
                                to the lock or compromising the security of your home or business. Spring and pin replacement is a simple yet important service that can extend the lifespan of your lock
                                and provide added peace of mind.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center pt-5">
                <div className="col-sm-12 text-center">
                            <div className="borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2 pb-4">Places To Review Captain Lock and Key</h2>
                                <div className="row justify-content-center">
                                    <div className="col-3">
                                        <img src={FB} className="img-thumbnail img-background" alt="Facebook Logo" />
                                    </div>
                                    <div className="col-3">
                                        <img src={Angi} className="img-thumbnail img-background" alt="Angis Leads Logo" />
                                    </div>
                                    <div className="col-3">
                                        <img src={Google} className="img-thumbnail img-background" alt="Google Logo" />
                                    </div>
                                </div>
                                <Link to={paths.CONTACT} className="mt-3 btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                </div>
            </div>
        </div>
    );
};

export default ResidentialKeyExtractionComponent;
