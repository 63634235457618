import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { logout } from "../../redux/actions/userActions";
import { useDispatch } from "react-redux";
import paths from "../../router/paths";

const AdminLinksComponent = () => {
    const dispatch = useDispatch()
  return (
    <Navbar bg="light" variant="light">
          <Nav className="flex-column text-dark">
              
              <LinkContainer to={paths.ADMINUSERS}>
                  <Nav.Link className="text-dark">Users</Nav.Link>
              </LinkContainer>
              <LinkContainer to={paths.ADMINPOSTS}>
                  <Nav.Link className="text-dark">Blog Post</Nav.Link>
              </LinkContainer>
              <LinkContainer to={paths.ADMINCHATS}>
                  <Nav.Link className="text-dark">Chats</Nav.Link>
              </LinkContainer>
              <LinkContainer to={paths.ADMINMESSAGE}>
                  <Nav.Link className="text-dark">Messages</Nav.Link>
              </LinkContainer>
         
              <LinkContainer to="/admin/settings/63e46fdf4cfb5c8a65636952">
                  <Nav.Link className="text-dark">Settings</Nav.Link>
              </LinkContainer>
              <Nav.Link className="text-dark" onClick={() => dispatch(logout())}>Logout</Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default AdminLinksComponent;
