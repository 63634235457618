import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import Destroyed from "../../gallery/Destroyed-Cylinder.jpg";
import Card from "../../gallery/Card-logos.png";
import Lishi from "../../gallery/lishi_Volk.jpg";
import DeadboltPick from "../../gallery/Deadbolt-pick.jpg";
import Storage from "../../gallery/Storage-lockout-springville.jpg";

const EmergancyLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100 m-0 p-0">
            <MetaComponent
                title={setting.seoHelmentTitleEmerg}
                description={setting.seoHelmentDescriptionEmerg}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeEmerg} />
            <div className="row EmergancyBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Emergency Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Emergency Locksmith Services in Springville</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5 pb-5 pt-5 bg-light">
                <div className="col-md-7">
                    <p className="font-size-16">
                        Experiencing a lockout situation, whether it's from your car or home, can be a frustrating inconvenience. However, with Captain Lock and Key, a reputable locksmith
                        in Utah County, help is just a phone call away.

                        In addition to providing house lockout services in Springville, we offer a range of solutions to meet your needs, including roadside assistance, car lockout services,
                        business lockout services, and assistance with high-security buildings or vehicles. Our services also extend to fixing broken or stuck keys in door locks or
                        vehicle ignitions. Don't let a lockout ruin your day - contact Captain Lock and Key for prompt and reliable assistance.
                    </p>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">

                        <div className="col-sm-3 me-5 ps-5 ms-5">
                            <img src={DeadboltPick} className="img-thumbnail img-thumbnail-comm" alt="locksmith opening a locked door" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                House Lockout in Springville: Assistance from a Trained Locksmith
                            </h2>
                            <p className="font-size-16">
                                Captain Lock and Key's team of experts is equipped to handle a variety of lock and key situations. From stubborn locks to complex repairs, we've seen
                                it all. We understand that being locked out of your home can be a stressful and time-sensitive situation. Whether you have pets waiting inside or
                                just want to unwind after a long day at work, we prioritize getting you back inside as quickly and efficiently as possible. You can trust our
                                experienced team to make your needs our top priority.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5  bg-light pb-5 pt-5">
                        <div className="col-sm-3 me-5 ps-5 ms-5">
                            <img src={Lishi} className="w-100 img-thumbnail-comm img-thumbnail" alt="Locksmith opening a locked car door" />
                        </div>
                        <div className="col-sm-8">
                            <h2 className="fw-bolder font-size-20">
                                Car Lockout in Springville
                            </h2>
                            <p className="font-size-16">
                                Dealing with a car lockout in Springville can be a major inconvenience, especially when it happens at the most inconvenient times. Perhaps you're leaving
                                work and realize you've locked your keys in the car, or you're on a road trip and don't know anyone in the area to turn to. But don't worry - our
                                experienced locksmiths are familiar with all areas of Springville County and can quickly come to your location.
                            </p>
                            <p className="font-size-16">
                                We understand that being locked out, especially at night, can be an unsettling experience, which is why our technicians wear uniforms and operate
                                clearly marked service vehicles. We want you to feel safe and comfortable when we arrive to assist you with your lockout situation. At Captain Lock and Key,
                                we're committed to providing reliable and trustworthy service to help ease your car lockout headache.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row EmergancyBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-5 mt-5">
                            <div className="col h-100 borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2">AFFORDABLE EMERGENCY LOCKSMITH IN SPRINGVILLE?</h2>
                                <p className="font-size-16 text-center">We Accecpt All Major Credit Cards</p>
                                <p className="font-size-16 text-center"><img src={Card} className="img-thumbnail image-comm" alt="Credit Cards accepted by Captain Lock and Key" /></p>
                                <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center pb-4">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-3 ps-5 ms-5">
                            <img src={Destroyed} className="img-thumbnail img-thumbnail-comm" alt="Someone attemted broke into a business" />
                        </div>
                        <div className="col-sm-8">
                            <h2 className="fw-bolder font-size-20">
                                Business Lockout in Springville: Our Technicians will Keep Your business Up and Running
                            </h2>
                            <p className="font-size-16">
                                A business lockout can be a significant disruption, particularly when compared to a residential lockout. Your business relies on a
                                well-orchestrated schedule, and every minute you spend locked out can have a direct impact on your profits and your employees' productivity.
                                As an employee, you may even fear for your job security as a result of a simple mistake.
                            </p>
                            <p className="font-size-16">
                                At Captain Lock and Key, we recognize the pressing nature of business lockouts, which is why our skilled team is trained to respond quickly and
                                with urgency. We will assess the situation and determine the best course of action to resolve your lockout and get your operations back to
                                normal as soon as possible.
                            </p>
                            <p className="font-size-16">
                                In addition to responding to business lockouts, we also offer lock installation services to meet the needs of small business owners. If you've
                                terminated an employee and feel uneasy about keeping the same locks, or if you suspect that keys have been duplicated and your inventory is at risk,
                                changing your locks regularly is a smart and practical decision. Contact Captain Lock and Key to discuss your options for new lock installations.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5 pb-5 bg-light">
                        <div className="col-sm-2 me-5 ps-5 ms-5">
                            <img src={Storage} className="img-thumbnail img-thumbnail-comm" alt="Locksmith opening a storage unit" />
                        </div>
                        <div className="col-sm-8">
                            <h2 className="fw-bolder font-size-20">
                                Storage Unit Lockouts in Springville
                            </h2>
                            <p className="font-size-16">
                                If you find yourself in a storage unit lockout situation in Springville or the surrounding areas, Captain Lock and Key can provide quick and efficient service. Our team of skilled locksmiths
                                is equipped with the latest tools and technologies to handle any lockout emergency. We understand that storage units often contain valuable items, which is why we
                                prioritize the security and safety of your belongings. With our 24/7 emergency service, we can assist you at any time, day or night. Whether you lost your key or the
                                lock is jammed, our experts will get you back into your storage unit in no time. Don't hesitate to call Captain Lock and Key for all your lockout needs in Springville or the surrounding areas.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3 pb-5 justify-content-center">
                        <div className="col-sm-9">
                            <h2 className="fw-bolder font-size-20">
                                The Help You Need: Contact Your Utah County Locksmith Today
                            </h2>
                            <p className="font-size-16">
                                While there are some things in life that we can manage ourselves, a stuck or broken lock is not one of them. Trying to remove an object from a
                                lock yourself can often make the situation worse, making it more difficult for a professional locksmith to resolve the issue.
                            </p>
                            <p className="font-size-16">
                                When it comes to installing new locks, it's crucial not to cut corners. Locks provide the security and protection for your home or business,
                                and it's essential to have a professional handle the installation to ensure your assets are well-protected.
                            </p>
                            <p className="font-size-16">
                                At Captain Lock and Key, we understand the importance of quick and efficient service, which is why our team is committed to resolving your lock and
                                key issues as swiftly as possible. Our mobile service is available seven days a week from 7:00 a.m. to 11:00 p.m. Contact us today if you
                                need assistance from a trusted Springville locksmith.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmergancyLocksmithComponent;
