import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import Garage from "../../gallery/Garage-door-lockout.jpg";
import LockedOut from "../../gallery/Locksmith-picking-a-locked-knob.jpg";
import DoorKnob from "../../gallery/Locked-out-of-door-knob.jpg";
import SmartLock from "../../gallery/Locked-out-of-smart-lock.jpg";
import LockedDeadbolt from "../../gallery/Deadbolt-locked-on-residential.jpg";

const HouseLockoutComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleHomeLock}
                description={setting.seoHelmentDescriptionHomeLock}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeHomeLock} />
            <div className="row HomeLockBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">House Lockout Services</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Garage} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith opening a locked garage door" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Garage Door Lockout
                            </h2>
                            <p className="font-size-16">
                                It can be a frustrating experience to come back home and find that you are locked out of your garage, especially if you only have your garage door opener with you. This
                                situation can arise due to various reasons, such as a power outage in your home or a dead battery in your garage door opener. However, you don't need to panic because
                               Captain Lock and Key in Utah County has got you covered. Our skilled technicians can help you get back into your garage by using special air wedges to create enough space
                                between the door and the frame. They can then utilize a specialized tool to pull the string hanging from your garage door opener, which will manually unlock your garage
                                door, allowing you to open it up. With our expert locksmith services, you can be sure that you'll be able to access your garage and get back into your home quickly and
                                easily.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={LockedOut} className="img-thumbnail-auto img-thumbnail" alt="Locksmith opening a locked knob" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Lock Picking a Doorknob
                            </h2>
                            <p className="font-size-16">
                                If you ever find yourself locked out of your home, don't despair as a professional locksmith can help you regain access. One of the methods a locksmith might use is to pick
                                the lock on your door. Locks are composed of pins and springs that must be properly aligned for the cylinder to turn and the door to be unlocked. With their specialized
                                tools, locksmiths can use a tension tool to apply pressure to the lock while using other picking tools, such as a rake tool, to move the pins into the correct position.
                                This technique allows the locksmith to manipulate the lock and get the cylinder to turn, thereby unlocking the door. A skilled locksmith can often open a locked door
                                quickly and without causing any damage to the lock or door, saving you time and money. So, if you find yourself locked out of your home, don't hesitate to contact a
                                trusted locksmith to help you regain entry.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={LockedDeadbolt} className="img-thumbnail-comm2 img-thumbnail" alt="Locksmith opening a locked deadbolt on a door" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Deadbolt Locked
                            </h2>
                            <p className="font-size-16">
                                Imagine finding yourself in a situation where you've locked your deadbolt and lost your house keys, leaving you locked out of your home. This can be a stressful experience,
                                but you can rely on Captain Lock and Key in Utah County to help you get back inside your house. Most deadbolts work using a series of pins and springs, with bottom pins
                                inside the cylinder and top pins with springs that push down on them. The right position between the cylinder and housing allows the cylinder to turn and the deadbolt to
                                unlock. A skilled locksmith can pick a deadbolt by getting all of the pins to align using special tools and a tension tool to turn the cylinder. Don't hesitate to call
                                Captain Lock and Key if you find yourself locked out of your home, as we are always here to help.
                            </p>
                        </div>
                    </div>
                    
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={DoorKnob} className="img-thumbnail-comm2 img-thumbnail" alt="Customer relizing that the door knob is locked" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Doorknob Lockout
                            </h2>
                            <p className="font-size-16">
                                One common mistake that homeowners make is locking their doorknob while leaving their home, only to realize later that they have forgotten their keys inside. Since
                                doorknobs can be locked without using a key, it's an easy mistake to make, and it can leave you feeling locked out and helpless. However, if you find yourself in this
                                situation, there's no need to panic. Instead, you can call on the services of a trained professional who can help you get back into your home. A skilled locksmith can use
                                various techniques, such as lock picking or using specialized tools, to open your doorknob and allow you to regain entry to your home. With their expertise, a professional
                                locksmith can quickly and efficiently solve the problem, saving you time and frustration. So, the next time you find yourself locked out of your home, remember that a
                                professional locksmith is just a phone call away.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row AutomotiveBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5 mb-5">
                        <div className="row mb-5 mt-5">
                            <div className="borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2 pb-4">Affordable Home Lockout Services In Springville?</h2>
                                <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <h2 className="fw-bolder mt-5 mb-3 font-size-45 text-center">Lost Keys</h2>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={SmartLock} className="img-thumbnail-auto img-thumbnail" alt="locked out of a smart lock on residential door" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Smart Locks
                            </h2>
                            <p className="font-size-16">
                                If you have a smart lock on your door, you may think that you are safe from break-ins or lockouts. However, smart locks work differently from standard deadbolts, and they
                                can be difficult to pick or bypass. Fortunately, a trained locksmith has the expertise and tools to get you back into your home, even if you have a smart lock. One method
                                that a locksmith can use is drilling the lock off of the door. It's important not to drill the lock in random places, as this can cause damage to the latch and make it
                                harder to remove or unlock the door. The locksmith will drill in a specific spot, usually where the bolts are, so that the deadbolt falls off of the door. Then, the
                                locksmith can use a flathead screwdriver as a tailpiece to unlock the latch and allow you to gain entry to your home. With the help of a professional locksmith, you can
                                get back into your home quickly and safely, even if you have a smart lock that is difficult to bypass.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Hidden Deadbolt Lockout
                            </h2>
                            <p className="font-size-16">
                                A hidden deadbolt can be a challenging situation for homeowners who find themselves locked out of their homes. However, our team of expert Captain Lock and Key technicians in
                                Utah County are well-equipped to handle such situations. In some cases, the locksmith may need to drill a hole in the door to reach the thumb latch of the hidden deadbolt
                                and turn it with a specialized tool. Alternatively, the technician may locate the latch of the hidden deadbolt and drill a small hole through the wood to expose it and turn
                                it to unlock the door. After the lockout is resolved, a protective plate can be installed to cover the hole without affecting the functionality of the hidden deadbolt. Our
                                trained professionals will ensure that you can gain access to your home quickly and efficiently.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Airbag Method
                            </h2>
                            <p className="font-size-16">
                                In some instances, even the most skilled locksmith may find it challenging to pick a doorknob. Standard doorknobs typically have five pins that must be aligned to unlock
                                the door, but some high-security doorknobs may have more than five pins, making them much harder to pick. Additionally, some doorknobs may be advanced smart locks that are
                                virtually impossible to pick. In such cases, a locksmith may use a different method to get you back inside your home. For example, the locksmith may wedge airbags in between
                                your door to create enough space between the door and the frame. By doing this, the latch of the doorknob may become exposed, and the locksmith can use a specialized tool
                                to push the latch in and pop your door open. With their expertise and tools, a professional locksmith can assist you in getting back into your home quickly and efficiently,
                                regardless of the type of doorknob or lock you have.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HouseLockoutComponent;
