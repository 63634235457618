import axios from "axios";
import VehicleLockoutComponent from "./components/vehicleLockoutComponent";

const VehicleLockoutPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <VehicleLockoutComponent fetchSetting={fetchSetting} />;
};

export default VehicleLockoutPage;
