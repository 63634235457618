import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const VineyardLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleVineyard}
                description={setting.seoHelmentDescriptionVineyard}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeVineyard} />
            <div className="row VineyardBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Vineyard Mobile Locksmith</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10">
                    <h2 className="fw-bold mt-5 mb-3 font-size-18">Vineyard Locksmith - Trusted Locksmith Services from Captain Lock and Key</h2>
                    <p className="font-size-16">
                        Looking for a reliable locksmith in Vineyard? Look no further than Captain Lock and Key. Our team of experienced technicians is equipped with the
                        latest tools and technology to provide top-quality locksmith services for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        We offer a full range of services, including but not limited to:
                    </p>
                    <ul className="font-size-16">
                        <li>
                            Residential Locksmith Services: Whether you need a lock repaired, rekeyed, or installed, we've got you covered.
                        </li>
                        <li>
                            Commercial Locksmith Services: We provide comprehensive locksmith services for your business, including high-security locks, access control systems, and master key systems.
                        </li>
                        <li>
                            Automotive Locksmith Services: Our technicians are experts in car key duplication, transponder key programming, and ignition repair.
                        </li>
                    </ul>
                    <p className="font-size-16">
                        At Captain Lock and Key, we understand that security is of utmost importance for you and your property. That's why we are committed to providing fast and
                        friendly service so you can have peace of mind knowing that your locks are in good hands.
                        <br /><br />
                        We are proud to be Vineyard's trusted locksmith. Contact us today to schedule an appointment or to receive a free quote for our services.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row vineyardBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Our Automotive Locksmith Service offers fast and reliable solutions for any automotive lock or key-related issue. Our team of expert
                                    technicians is equipped with the latest tools and technology to provide efficient and effective services, including car key replacement
                                    , ignition repair, and emergency car lockout services. We understand the importance of security for your vehicle, which is why we prioritize
                                    customer satisfaction and provide customized solutions to meet your unique needs. At our Automotive Locksmith Service, we use only high-quality
                                    materials to ensure that your car's locks and keys are durable and long-lasting. Contact us today for all your automotive locksmith needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Commercial Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Our Commercial Locksmith Service is dedicated to providing top-quality services to businesses of all sizes. Our team of experienced technicians
                                    is equipped with the latest tools and technology to provide efficient and effective services, including lock repair, installation, and key
                                    duplication. We understand the importance of security for your business, which is why we provide customized solutions to meet your unique needs.
                                    Our commercial locksmith services are available 24/7 for your convenience, and we use only high-quality materials to ensure that your locks are
                                    durable and long-lasting. Contact us today to schedule an appointment or to learn more about our services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Lockout Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Our Car Lockout Service is here to provide fast and reliable solutions when you find yourself locked out of your vehicle. Our team of experienced
                                    technicians is equipped with the latest tools and technology to diagnose and address any lock or key-related issue quickly and efficiently. We
                                    understand how stressful and inconvenient it can be to be locked out of your car, which is why we provide 24/7 emergency car lockout services to
                                    get you back on the road in no time. Our priority is your satisfaction, which is why we provide customized solutions to meet your unique needs.
                                    Contact us today for all your car lockout needs.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Master Key Systems</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Our Master Key Systems are designed to provide convenience and security for businesses of all sizes. Our team of experienced technicians
                                    will work with you to develop a customized master key system that meets your specific needs. With a master key system, you can simplify
                                    access to your business while maintaining security. We use only the highest quality materials to ensure that your master key system is
                                    durable and long-lasting. Our priority is your satisfaction, which is why we provide top-quality service at a fair price. Contact us
                                    today to learn more about our master key systems and how they can benefit your business.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Fresh Installation Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Our Fresh Installation Service is designed to provide top-quality lock installation solutions for your home or business. Our team of
                                    experienced technicians is equipped with the latest tools and technology to provide efficient and effective installation services. We
                                    understand the importance of security for your property, which is why we use only high-quality materials to ensure that your locks are
                                    durable and long-lasting. Our priority is your satisfaction, which is why we provide customized solutions to meet your unique needs.
                                    Whether you need new locks installed for your home or business, you can rely on our Fresh Installation Service to get the job done right.
                                    Contact us today to schedule an appointment or to learn more about our services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Transponder Key Programming</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Our Transponder Key Programming service provides fast and reliable solutions for your vehicle's key programming needs. Our team of experienced
                                    technicians is equipped with the latest tools and technology to program transponder keys for a wide range of makes and models. We understand
                                    the importance of having a functional key for your vehicle, which is why we provide efficient and effective services to get you back on the
                                    road in no time. We use only high-quality materials and cutting-edge technology to ensure that your transponder keys are programmed accurately
                                    and efficiently. Contact us today for all your transponder key programming needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default VineyardLocksmithComponent;
