import axios from "axios";
import VehiclePageComponent from "./components/VehiclePageComponent";


const VehiclesPage = () => {
   
    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <VehiclePageComponent fetchSetting={fetchSetting} />;
};

export default VehiclesPage;
