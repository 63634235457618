import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const SantaquinLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleSantaquin}
                description={setting.seoHelmentDescriptionSantaquin}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeSantaquin} />
            <div className="row santaquinBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3 justify-content-center">
                            <div className="col-sm-8 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Santaquin Mobile Locksmith</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        Looking for a fast and reliable locksmith service in Santaquin? Look no further than Captain Lock and Key. Our team of experienced technicians are equipped with
                        the latest tools and technology to provide top-quality locksmith services for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        Our comprehensive list of services includes lock repair, rekeying, and installation for your residential needs. We also offer commercial locksmith services such
                        as high-security locks, access control systems, and master key systems to ensure that your business is secure. Our automotive locksmith services include car key
                        duplication, transponder key programming, and ignition repair.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we understand the importance of security for you and your property. That's why we strive to provide fast and friendly service, giving you
                        peace of mind knowing that your locks are in good hands.
                    </p>
                    <p className="font-size-16">
                        We are proud to be Santaquin's trusted locksmith, and we're ready to help you with your lock and key needs. Contact us today to schedule an appointment or receive
                        a free quote for our services.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row spanishForkBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you've ever found yourself locked out of your vehicle, you know how stressful and frustrating the situation can be. That's where a
                                    reliable automotive locksmith service comes in. At our Automotive Locksmith Service, we offer a range of solutions to address your vehicle's
                                    locking and unlocking needs, from repairing broken or damaged locks to programming transponder keys and fobs. Our team of experienced
                                    technicians has the knowledge and expertise to quickly diagnose and address any lock-related issue, providing efficient and effective
                                    solutions to get you back on the road in no time.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    We understand that lock-related issues can occur at any time, which is why we offer 24/7 emergency services. Whether you need a new set
                                    of keys or are dealing with a malfunctioning lock, our expert technicians are always ready to assist you. We also offer valuable advice
                                    on how to enhance your vehicle's security measures to protect against theft and unauthorized access.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    At our Automotive Locksmith Service, we prioritize customer satisfaction, and our rates are competitive and affordable. With the latest
                                    tools and technology, we provide efficient and effective solutions to ensure your vehicle is always secure and operational. So if you're
                                    looking for a trusted and reliable automotive locksmith service, look no further than our Automotive Locksmith Service. Contact us today
                                    to schedule an appointment or to learn more about our services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Residential Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    When it comes to the security of your home and family, you can't take any chances. That's where a trusted Residential Locksmith Service comes in.
                                    Our Residential Locksmith Service offers a range of solutions to address your home's locking and unlocking needs, from installing high-security
                                    locks and deadbolts to repairing and replacing broken or damaged locks. With years of experience and a team of expert technicians, we have the
                                    knowledge and expertise to ensure your home is always safe and secure.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    We understand that lock-related issues can occur at any time, which is why we offer 24/7 emergency services. Whether you need a lock repaired
                                    or are dealing with an urgent lockout situation, our expert technicians are always ready to assist you. We also offer valuable advice on how to
                                    enhance your home's security measures to protect against potential threats.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    At our Residential Locksmith Service, we prioritize customer satisfaction, and our rates are competitive and affordable. We use the latest tools
                                    and technology to provide efficient and effective solutions, ensuring that your home is always protected. So if you're looking for a trusted and
                                    reliable Residential Locksmith Service, look no further than our Residential Locksmith Service. Contact us today to schedule an appointment or to
                                    learn more about our services.
                                </p>
                            </div>
                        </div>
                        
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Vehicle Emergency Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you've ever been in a vehicle emergency, you know how important it is to have a reliable and trustworthy service provider to assist you.
                                    That's where our Vehicle Emergency Service comes in. We offer 24/7 emergency services for a range of vehicle-related issues, from lockouts
                                    and lost keys to battery jump starts and tire changes. Our team of experienced technicians is equipped with the knowledge and tools to diagnose
                                    and address any emergency situation quickly and efficiently.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    At our Vehicle Emergency Service, we understand that emergencies can happen at any time, which is why we're always ready to respond to your call.
                                    Our goal is to get you back on the road as quickly as possible, with minimal disruption to your day. We also provide valuable advice on how to
                                    maintain your vehicle's safety and prevent future emergencies from occurring.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    We prioritize customer satisfaction, and our rates are competitive and affordable. We use the latest tools and technology to provide efficient
                                    and effective solutions, ensuring that your vehicle is always in good hands. So if you're in need of a trusted and reliable Vehicle Emergency
                                    Service, look no further than our Vehicle Emergency Service. Contact us today to schedule an appointment or to learn more about our services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Business Lockouts</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Business lockouts can be a major inconvenience for any business owner or employee. If you find yourself locked out of your business, it's
                                    important to have a reliable and efficient service provider to call. That's where our Business Lockout Service comes in. We offer 24/7 emergency
                                    services for a range of business lockout situations, from lost keys to malfunctioning locks.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    Our team of experienced technicians is equipped with the knowledge and tools to diagnose and address any lockout situation quickly and efficiently.
                                    We understand the importance of getting your business back up and running as soon as possible, which is why we prioritize fast response times and
                                    effective solutions.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    At our Business Lockout Service, we prioritize customer satisfaction, and our rates are competitive and affordable. We use the latest tools and
                                    technology to provide efficient and effective solutions, ensuring that your business is always in good hands. We also offer valuable advice on how
                                    to enhance your business's security measures to prevent future lockout situations.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    So if you're in need of a trusted and reliable Business Lockout Service, look no further than our Business Lockout Service. Contact us today to
                                    schedule an appointment or to learn more about our services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Rekey Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Rekeying your locks can be a cost-effective and efficient way to enhance your home or business's security. That's where our Rekey Service comes in.
                                    We offer a range of rekeying services to address your locking and unlocking needs, from installing new keys to existing locks to changing the key
                                    configuration of your locks entirely.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    Our team of expert technicians has years of experience and the knowledge to provide customized solutions that meet your unique needs. We use the
                                    latest tools and technology to provide efficient and effective rekeying services, ensuring that your property is always secure.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    At our Rekey Service, we prioritize customer satisfaction, and our rates are competitive and affordable. We use only high-quality materials to
                                    ensure that your locks are durable and long-lasting. We also provide valuable advice on how to enhance your home or business's security measures
                                    to prevent potential threats.
                                </p>
                                <p className="font-size-16 text-start ms-5">
                                    So if you're looking for a trusted and reliable Rekey Service, look no further than our Rekey Service. Contact us today to schedule an appointment
                                    or to learn more about our services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default SantaquinLocksmithComponent;
