import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import Machine from "../../gallery/key_machine.jpg";
import Push from "../../gallery/push-to-start-button.jpg";
import Program from "../../gallery/Autel-Program-reset.jpg";
import Pincode from "../../gallery/Autel-European-Program.webp";
import Audi from "../../gallery/Autel-Spare-key.jpg";

const CommercialKeyDupComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleKeyDup}
                description={setting.seoHelmentDescriptionKeyDup}
                name={setting.seoHelmentName}
                type="webpage" />
            <div className="row CommercialKeyDupBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Key Duplication</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row pb-5 bg-light justify-content-center pt-5">
                        <div className="col-sm-7">
                            <p className="font-size-16">
                                Key duplication refers to the process of creating a new copy of an existing key. This can be done for a variety of reasons, such as creating a spare key for emergencies,
                                providing copies to family members or roommates, or replacing lost or damaged keys. Key duplication can be done at a locksmith shop or hardware store, or with the use of
                                a key duplication machine. However, it's important to note that not all keys can be easily duplicated, as some may require specialized equipment or techniques. It's also
                                important to ensure that the key duplication process is done by a reputable and trustworthy individual or business to ensure the security of your property.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Machine} className="img-thumbnail-auto img-thumbnail" alt="Locksmith generating a new key" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Affordable Spare Key
                            </h2>
                            <p className="font-size-16">
                                An affordable spare key is a valuable investment for anyone who wants to ensure that they can access their property in the event that they lose their primary key. Spare
                                keys are an essential backup, allowing property owners to quickly regain access to their property without incurring the costs associated with lockouts or key replacement.
                                The cost of a spare key can vary depending on the type of lock and the complexity of the key, but many locksmiths offer affordable options that are both reliable and
                                cost-effective. In addition to providing peace of mind, affordable spare keys can also save property owners time and money, as they can help avoid the inconvenience and
                                expense of being locked out of a property. Skilled locksmiths are able to provide a range of affordable spare key options, including basic keys, transponder keys, and smart
                                keys, tailored to meet the needs of individual customers. Investing in an affordable spare key is a wise decision for anyone who values convenience, security, and
                                cost-effectiveness.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Program} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith programming a new key to vehicle" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Car Key Programming
                            </h2>
                            <p className="font-size-16">
                                Car key programming is the process of reprogramming or creating a new key for a vehicle's electronic control unit (ECU). Modern cars are equipped with complex electronic
                                systems that rely on microchipped keys to start the engine and operate various features. When a key is lost, damaged, or stolen, it can be a frustrating and expensive issue.
                                Skilled locksmiths and auto technicians are able to use specialized equipment and software to reprogram the car's ECU and create a new key. This process requires precision
                                and expertise, as errors can lead to a non-functional key or a damaged ECU. Car key programming is an essential service for anyone who has lost their keys, had them stolen
                                or damaged. It is also useful for those who want to have an extra key as a backup or for a new driver. By investing in car key programming, car owners can regain access to
                                their vehicles and enjoy the convenience and security of having a working key.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Pincode} className="img-thumbnail-comm1 img-thumbnail" alt="Locksmith pulling a pin code to program a key" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Pin Code Programming and Bypass
                            </h2>
                            <p className="font-size-16">
                                Pin code programming and bypass is a specialized service that allows vehicle owners to regain access to their car or truck in the event that they have lost their keys or
                                forgotten their pin code. Modern cars and trucks are equipped with sophisticated anti-theft systems that rely on pin codes and other security features to prevent
                                unauthorized access. When a pin code is forgotten or lost, it can be a frustrating and potentially costly issue. Skilled auto technicians and locksmiths are able to use
                                specialized equipment and techniques to bypass the pin code and reprogram the car's computer system. This process requires expertise and precision, as any errors can cause
                                damage to the car's electronic systems. Pin code programming and bypass is an essential service for anyone who has lost their pin code or wants to regain access to their
                                vehicle quickly and cost-effectively. By investing in pin code programming and bypass, car owners can avoid the inconvenience and expense of being locked out of their
                                vehicle, and enjoy the convenience and security of having a working pin code.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row CommercialKeyDupBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5 mb-5">
                        <div className="row mb-5 mt-5">
                            <div className="borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2 pb-4">Affordable Commercial Locksmith In Springville?</h2>
                                <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Push} className="img-thumbnail-auto img-thumbnail" alt="Locksmith programming a push to start fob" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Push to Start Key Programming
                            </h2>
                            <p className="font-size-16">
                                Push to start key programming is a specialized service that allows vehicle owners to program or replace their push to start keys. Push to start keys are a popular feature
                                in modern cars and trucks, offering convenience and security to drivers. When a push to start key is lost or damaged, it can be a frustrating and potentially expensive
                                issue. Skilled auto technicians and locksmiths are able to use specialized equipment and software to program or replace push to start keys. This process requires precision
                                and expertise, as any errors can cause damage to the car's electronic systems. Push to start key programming is an essential service for anyone who has lost their push to
                                start key, wants a backup key, or has recently purchased a used vehicle with missing keys. By investing in push to start key programming, car owners can avoid the
                                inconvenience and expense of being locked out of their vehicle, and enjoy the convenience and security of having a working push to start key.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Audi} className="img-thumbnail-comm img-thumbnail" alt="Locksmith programming a push to start fob" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Emergency Key Duplication
                            </h2>
                            <p className="font-size-16">
                                Emergency key duplication is a valuable service that allows property owners to quickly and easily obtain a duplicate key in the event of an emergency. Whether it's a lost
                                key, a damaged key, or a broken key that's stuck in the lock, an emergency key duplication service can help. Skilled locksmiths and key cutters are able to provide emergency
                                key duplication for a variety of locks, including residential, commercial, and automotive locks. This process requires specialized equipment and expertise, as any errors
                                can cause damage to the lock or the key. Emergency key duplication is an essential service for anyone who needs immediate access to their property or vehicle. It can help
                                avoid the inconvenience and expense of being locked out, and provide peace of mind knowing that a backup key is readily available. By investing in emergency key duplication,
                                property owners can be prepared for any unexpected lock or key issues, and enjoy the convenience and security of having a reliable backup key.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default CommercialKeyDupComponent;
