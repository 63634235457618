import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import Master from "../../gallery/Schlage-master-key.jpg";

const CommercialMasterKeyComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleMasterKey}
                description={setting.seoHelmentDescriptionMasterKey}
                name={setting.seoHelmentName}
                type="webpage" />
            <div className="row CommercialMasterRekeyBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Commercial Master Rekey</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row pb-5 pt-5 justify-content-center bg-light">
                        <div className="col-sm-7">
                            <p className="font-size-16">
                                Commercial master rekey is a process used to change the keying configuration of a commercial property, allowing for greater security and access control. Master rekeying
                                involves changing the combination of pins within a lock cylinder, so that a new key can be used to open the lock, while the old key is rendered ineffective. This allows
                                property owners to maintain tight control over who has access to different areas of the property, without the need to change the entire lock. Commercial master rekeying
                                is particularly useful for businesses that have high turnover of employees or frequent changes in access requirements, as it allows for a rapid and cost-effective update to
                                the property's security. Skilled locksmiths are able to perform commercial master rekeying quickly and efficiently, with minimal disruption to daily operations. Commercial
                                master rekeying is an essential service for any business that places a high priority on security and access control.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Master} className="img-thumbnail-auto img-thumbnail" alt="Locksmith generating a master key system" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Master Key System
                            </h2>
                            <p className="font-size-16">
                                A master key system is a customized locking solution that allows for hierarchical access control, allowing different levels of access for different individuals or groups.
                                Master key systems are commonly used in commercial and institutional settings, where strict access control is critical for maintaining security and privacy. The system is
                                designed to work by allowing certain keys to open multiple locks, while other keys can only open a specific lock. The key hierarchy is carefully designed to ensure that
                                individuals or groups are only granted access to the areas of the property that they need, while also allowing for a master key to provide access to all areas of the
                                property. Master key systems are highly customizable, allowing for a variety of key hierarchies to be created to meet the unique needs of a property. Skilled locksmiths
                                are able to design, install and maintain master key systems, ensuring that the property's security is optimized and that access control is both efficient and effective.
                                A master key system is an essential security solution for any commercial or institutional property that requires strict access control, allowing for greater flexibility,
                                convenience, and security.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommercialMasterKeyComponent;
