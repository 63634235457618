import axios from "axios";
import CommercialMasterKeyComponent from "./components/CommercialMasterKeyComponent";

const ResidentialRekeyPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <CommercialMasterKeyComponent fetchSetting={fetchSetting} />;
};

export default ResidentialRekeyPage;
