import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import FB from "../../gallery/fb.png";
import Angi from "../../gallery/angieslist.png";
import Google from "../../gallery/google.png";

const ResidentialLockInstallComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleResInstall}
                description={setting.seoHelmentDescriptionResInstall}
                name={setting.seoHelmentName}
                type="webpage" />
            <div className="row ResidentialLockInstallBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Springville Lock Installation</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row pb-5 bg-light justify-content-center pt-5">
                        <div className="col-sm-7">
                            <p className="font-size-16">
                                Springville Lock Installation provides expert installation of all types of locks for residential and commercial properties. Our skilled technicians are trained to ensure
                                that all locks meet the necessary safety codes and regulations, providing a secure environment for your home or business. We offer comprehensive lock installation services,
                                from traditional deadbolts to smart locks, to meet all of your security needs. Our team has the expertise and equipment to get the job done quickly and efficiently,
                                minimizing any disruptions to your daily routine. Trust us to keep your property secure with our Springville Lock Installation services. With our services, you can have
                                peace of mind knowing that your property is protected against unauthorized access. Contact us today to schedule an appointment and upgrade your property's security with our
                                expert lock installation services.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Residential Lock Installation
                            </h2>
                            <p className="font-size-16">
                                Residential Lock Installation provides expert installation of all types of locks for homes and apartments. Our skilled technicians are trained to ensure that all locks meet
                                the necessary safety codes and regulations, providing a secure environment for you and your family. We offer a comprehensive range of lock installation services, from
                                traditional deadbolts to smart locks, to meet all of your security needs. Our team has the expertise and equipment to get the job done quickly and efficiently, minimizing
                                any disruptions to your daily routine. Trust us to keep your home secure with our Residential Lock Installation services. With our services, you can have peace of mind
                                knowing that your property is protected against unauthorized access. Contact us today to schedule an appointment and upgrade your home's security with our expert lock
                                installation services.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                       
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Bump-Proof Lock Installation
                            </h2>
                            <p className="font-size-16">
                                Bump-Proof Lock Installation provides an extra layer of security for your residential or commercial property. Bump keys are a commonly used tool by intruders to quickly and
                                quietly bypass traditional locks, but with bump-proof locks, you can have peace of mind knowing that your property is protected against this type of forced entry. Our
                                skilled technicians are trained to install a wide range of bump-proof locks, including traditional cylinder locks, deadbolts, and smart locks. We only use high-quality
                                bump-proof locks from reputable brands to ensure the safety and security of your property. With our Bump-Proof Lock Installation services, you can upgrade your property's
                                security and protect against unauthorized access. Contact us today to schedule an appointment and take the first step in securing your property with our expert installation
                                services.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                       
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Deadbolt Installation Service
                            </h2>
                            <p className="font-size-16">
                                Deadbolt Installation Service provides an essential layer of security for your residential or commercial property. Deadbolts are a highly effective lock type and are widely
                                recognized as one of the best options for securing doors. Our skilled technicians are trained to install a wide range of deadbolts, from traditional cylinder locks to smart
                                locks that can be controlled with a smartphone. We use high-quality deadbolts from reputable brands to ensure the safety and security of your property. Our team has the
                                expertise and equipment to get the job done quickly and efficiently, minimizing any disruptions to your daily routine. With our Deadbolt Installation Service, you can
                                upgrade your property's security and protect against unauthorized access. Contact us today to schedule an appointment and take the first step in securing your property with
                                our expert installation services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ResidentialLockInstall1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5 mb-5">
                        <div className="row mb-5 mt-5">
                            <div className="borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2 pb-4">Places To Review Captain Lock and Key</h2>
                                <div className="row justify-content-center">
                                <div className="col-3">
                                    <img src={FB} className="img-thumbnail img-background" alt="Facebook Logo" />
                                </div>
                                <div className="col-3">
                                    <img src={Angi} className="img-thumbnail img-background" alt="Angis Leads Logo" />
                                    </div>
                                    <div className="col-3">
                                        <img src={Google} className="img-thumbnail img-background" alt="Google Logo" />
                                    </div>
                                </div>
                                <Link to={paths.CONTACT} className="mt-3 btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Fresh Lock installation
                            </h2>
                            <p className="font-size-16">
                                Fresh Lock installation is an essential service for securing your residential or commercial property. When you move into a new property, it is important to change the locks
                                to ensure that no one else has access to your property. Our skilled technicians are trained to install a wide range of fresh locks, from traditional cylinder locks to smart
                                locks that can be controlled with a smartphone. We only use high-quality locks from reputable brands to ensure the safety and security of your property. Our team has the
                                expertise and equipment to get the job done quickly and efficiently, minimizing any disruptions to your daily routine. With our Fresh Lock Installation service, you can
                                upgrade your property's security and protect against unauthorized access. Contact us today to schedule an appointment and take the first step in securing your property with
                                our expert installation services.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Broken Lock Repair
                            </h2>
                            <p className="font-size-16">
                                Broken lock repair is an essential service for maintaining the security of your residential or commercial property. When a lock is damaged or broken, it can compromise the
                                security of your property, leaving it vulnerable to unauthorized access. Our skilled technicians are trained to repair a wide range of broken locks, from traditional
                                cylinder locks to smart locks that can be controlled with a smartphone. We use high-quality replacement parts from reputable brands to ensure the safety and security of
                                your property. Our team has the expertise and equipment to get the job done quickly and efficiently, minimizing any disruptions to your daily routine. With our Broken Lock
                                Repair service, you can restore the security of your property and prevent further damage to your locks. Contact us today to schedule an appointment and take the first step
                                in securing your property with our expert repair services.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Doorknob Installation Service
                            </h2>
                            <p className="font-size-16">
                                Doorknob Installation Service is an essential service for ensuring the security and functionality of your residential or commercial property. Our skilled technicians are
                                trained to install a wide range of doorknobs, from traditional designs to modern smart locks that can be controlled with a smartphone. We use high-quality doorknobs from
                                reputable brands to ensure the safety and security of your property. Our team has the expertise and equipment to get the job done quickly and efficiently, minimizing any
                                disruptions to your daily routine. With our Doorknob Installation Service, you can upgrade your property's security and improve the overall functionality of your doors. Our
                                team will work with you to find the right doorknob design that fits your preferences and meets your security needs. Contact us today to schedule an appointment and take the
                                first step in securing your property with our expert installation services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResidentialLockInstallComponent;
