import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";
import Chevy from "../../gallery/chevy-key.webp";
import KeyCut from "../../gallery/Key-Cutting-Bitting.PNG";
import Emergancy from "../../gallery/auto-emergency-key.png";
import GmCyl from "../../gallery/gm_cyl.jpg";
import GmCylRemove from "../../gallery/gm-ignition-remove.jpg";
import Autel from "../../gallery/Autel-program.jpg";
import AutelReset from "../../gallery/Autel-Program-reset.jpg";

const AutomotiveLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid w-100">
            <MetaComponent
                title={setting.seoHelmentTitleAuto}
                description={setting.seoHelmentDescriptionAuto}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeAuto} />
            <div className="row AutomotiveBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Automotive Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Automotive Locksmith In Springville</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <h2 className="fw-bolder mt-5 mb-3 font-size-45 text-center">Car Key Services</h2>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Chevy} className="img-thumbnail-auto img-thumbnail" alt="Close up on a key in a Chevy ignition" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Lost All Keys
                            </h2>
                            <p className="font-size-16">
                                Losing your car keys can be stressful, but don't worry - Captain Lock and Key in Springville has got you covered. Our expert locksmith technicians can make
                                a new key for your vehicle, regardless of the type of car you have. Using special software and techniques, we can retrieve information from your
                                car's VIN to make a new key with the correct blade type and transponder. In some cases, we may need to remove your ignition or locks to cut a new
                                key based on the key code. Rest assured, our team is fully equipped to handle any car key replacement needs you may have. Contact us today for
                                reliable and efficient service.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Emergancy} className="img-thumbnail-auto img-thumbnail" alt="Emergency key duplication" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Emergency Key
                            </h2>
                            <p className="font-size-16">
                                Locked out of your car with no spare key in sight? Don't worry, Captain Lock and Key in Springville has got you covered with our emergency key services. Our
                                emergency keys are designed to only unlock your car door, providing you with a quick solution to get back inside your vehicle. You can keep your
                                emergency key with you at all times, so you're prepared in case of a lockout emergency. Plus, with the added security feature that prevents thieves
                                from using the emergency key to start your vehicle, you can have peace of mind knowing your car is safe. Contact us today for more information about
                                our emergency key services.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={KeyCut} className="img-thumbnail-auto img-thumbnail" alt="Locksmith cutting a laser cut key" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Spare Key Duplication
                            </h2>
                            <p className="font-size-16">
                                Creating a duplicate car key is a cost-effective solution compared to making one from scratch. At Captain Lock and Key in Springville, we highly recommend
                                duplicating an existing key to have a spare one available in case of emergency. The cost of creating a key from scratch involves obtaining information
                                about the depths of the cuts of the key, which can be expensive. However, if you already have a spare key, the depths can be obtained using a machine
                                or by visually examining the cuts on the current key. This way, you can save time and money while ensuring that you always have a spare key on hand.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row AutomotiveBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5 mb-5">
                        <div className="row mb-5 mt-5">
                            <div className="borderingLight pt-5 pb-5">
                                <h2 className="fw-bolder text-center ms-4 pt-2 pb-4">Affordable Automotive Locksmith In Springville?</h2>
                                <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-12">
                    <h2 className="fw-bolder mt-5 mb-3 font-size-45 text-center">Ignition Cylinder Services</h2>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={GmCyl} className="img-thumbnail-auto img-thumbnail" alt="Fixing a gm ignition cylinder" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Ignition Repair
                            </h2>
                            <p className="font-size-16">
                                The mechanism of an ignition involves a series of wafers and springs. When a key is inserted, the springs are elevated and prevent the cylinder from
                                turning in its housing. However, when the correct key is used, the wafers should drop and allow the sidebar to drop, which permits the cylinder to
                                turn inside the housing. Unfortunately, a broken spring or wafer can prevent the wafer from contracting even with the correct key. If you encounter
                                this issue, our skilled locksmith technicians can extract the ignition cylinder from the housing and repair or replace the damaged components as needed.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={GmCylRemove} className="img-thumbnail-auto img-thumbnail" alt="Replaceing ignition in a chevy" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Ignition replacement
                            </h2>
                            <p className="font-size-16">
                                In some instances, repairing the ignition cylinder in your vehicle may not be possible. If this is the case, our skilled locksmith technicians can
                                replace the cylinder, taking into account the make, model, and year of your vehicle. Typically, the retainer pin of the cylinder needs to be pressed
                                to remove it from the housing and install a new one. However, it's important to note that when the ignition is replaced, a new car key will likely be
                                required. This means that the key for the door and the key for the ignition may be different.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="row justify-content-center mb-5 pb-4 bg-light">
                <div className="col-md-12">
                    <h2 className="fw-bolder mt-5 mb-3 font-size-45 text-center">Transponder Key Services</h2>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={Autel} className="img-thumbnail-auto img-thumbnail" alt="Programming a key with a programmer" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Car Key Programming
                            </h2>
                            <p className="font-size-16">
                                In this day and age, the majority of modern cars come equipped with an immobilizer that serves as an additional security feature. This device
                                communicates with a transponder key by utilizing specific radio frequencies. Once the proper programmed car key is inserted, the immobilizer
                                enables the fuel pump to turn on, thus allowing the driver to operate the vehicle. However, if the appropriate transponder key is not present,
                                the immobilizer will disable the fuel pump, rendering the car undrivable. That's why you require a special programmed key to operate most modern
                                vehicles. If you need your car key programmed, you can always contact Captain Lock and Key in Springville for assistance.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pt-3">
                        <div className="col-sm-2">
                        </div>
                        <div className="col-sm-2 me-5">
                            <img src={AutelReset} className="w-100 img-thumbnail-auto img-thumbnail" alt="Programmer reset all keys" />
                        </div>
                        <div className="col-sm-7">
                            <h2 className="fw-bolder font-size-20">
                                Immobilizer Reset
                            </h2>
                            <p className="font-size-16">
                                If you find that your car keys are missing, it's important to act fast. It could be that you've simply misplaced them, or it's possible that someone
                                has stolen a copy. In either case, it's wise to consider an immobilizer reset for your vehicle. An immobilizer reset will clear all previously
                                programmed car keys, which can prevent unauthorized access to your car. If someone has obtained a copy of your key and attempts to steal your vehicle,
                                an immobilizer reset can stop them in their tracks. To learn more about immobilizer resets, contact Captain Lock and Key in Springville today.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AutomotiveLocksmithComponent;
