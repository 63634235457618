import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const LindonLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleLindon}
                description={setting.seoHelmentDescriptionLindon}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeLindon} />
            <div className="row lindonBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Lindon Locksmith Service</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        Looking for reliable locksmith services in Lindon? Look no further than Captain Lock and Key! Our team of skilled technicians is equipped with
                        the latest tools and technology to provide top-quality locksmith services for your home, business, and vehicle.
                    </p>
                    <p className="font-size-16">
                        We offer a wide range of locksmith services in Lindon, including but not limited to:
                    </p>
                    <p className="font-size-16 ms-3">
                        Residential Locksmith Services: Whether you need to rekey your home, replace your locks, or install new ones, we've got you covered. We understand
                        the importance of keeping your family and property safe and secure, and we'll work to ensure your home is protected.
                    </p>
                    <p className="font-size-16 ms-3">
                        Commercial Locksmith Services: As business owners ourselves, we know how important it is to keep your property safe and secure. That's why we offer
                        a full range of commercial locksmith services, including high-security locks, access control systems, and master key systems.
                    </p>
                    <p className="font-size-16 ms-3">
                        Automotive Locksmith Services: If you're ever locked out of your car or need a new key, our technicians are experts in car key duplication,
                        transponder key programming, and ignition repair. We understand how stressful it can be to lose your keys or get locked out of your car, and
                        we'll work quickly to get you back on the road.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we take pride in our fast and friendly service, and we're committed to providing the best locksmith services in Lindon.
                        We understand the importance of security, and we'll work to ensure your locks are in good hands. Contact us today to schedule an appointment or
                        to receive a free quote for our services.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row lindonBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Residential Locksmith Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    At our locksmith company, we understand the importance of feeling safe and secure in your home. That's why we offer a wide range of
                                    residential locksmith services to keep your property secure and protected. From installing new locks to repairing existing ones, our
                                    team of skilled professionals is equipped to handle any locksmith issue you may encounter. We also offer emergency lockout services
                                    to help you regain access to your home quickly and safely. We use only the highest quality products and techniques to ensure that your
                                    locks and security systems are functioning properly and providing the maximum level of protection. Our residential locksmith services
                                    are designed to give you peace of mind knowing that your home is protected from theft and intrusion. Contact us today to schedule a
                                    consultation and take the first step in securing your home.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    When you're locked out of your vehicle or have lost your car keys, it can be a frustrating and stressful experience. But with our automotive
                                    locksmith services, you can rest assured that you'll be back on the road in no time. Our team of skilled technicians is equipped with the
                                    latest technology and tools to provide a wide range of automotive locksmith solutions, including key cutting, key fob programming, ignition
                                    repair and replacement, and more. We can handle any make or model of vehicle, and we offer fast and reliable service at an affordable price.
                                    With our automotive locksmith services, you can have peace of mind knowing that you'll never be stranded without access to your vehicle.
                                    Contact us today to schedule a consultation and experience the convenience and reliability of our automotive locksmith services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Transponder Programming</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Transponder keys are becoming more and more common in modern vehicles, and our locksmith company is here to help you with all of your
                                    transponder programming needs. We specialize in providing top-notch transponder key programming services for a wide range of vehicles,
                                    using the latest technology and equipment to ensure that your keys are programmed accurately and efficiently. Whether you need a new
                                    transponder key programmed or an existing one reprogrammed, our team of skilled professionals has the expertise and experience to get
                                    the job done right. We offer fast and reliable service at an affordable price, and we are committed to providing the best possible
                                    customer experience. With our transponder programming services, you can rest assured that your vehicle will be secure and ready to
                                    go when you need it. Contact us today to schedule a consultation and take the first step in securing your vehicle with our high-quality
                                    transponder programming services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Commercial Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Our locksmith company provides a full range of commercial locksmith services to help protect your business and keep it secure.
                                    We understand that every business has unique security needs, which is why we offer customized solutions to meet your specific
                                    requirements. Our team of skilled professionals has the knowledge and experience to assess your property and recommend the best
                                    solutions for your commercial security needs. We provide a wide range of services, including high-security locks, master key systems,
                                    access control systems, CCTV installation, and more. We use only the highest quality products and techniques to ensure that your locks
                                    and security systems are functioning properly and providing the maximum level of protection. We offer fast and reliable service at an
                                    affordable price, and we are committed to providing the best possible customer experience. With our commercial locksmith services, you
                                    can have peace of mind knowing that your business is protected from theft and intrusion. Contact us today to schedule a consultation and
                                    experience the convenience and reliability of our commercial locksmith services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Lockout Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you find yourself locked out of your car, don't panic. Our locksmith company offers fast and reliable car lockout services to get you back
                                    on the road in no time. We understand how frustrating and stressful it can be to be locked out of your vehicle, which is why we provide fast
                                    and efficient service at an affordable price. Our team of skilled technicians has the expertise and tools to handle any type of car lockout
                                    situation, including broken keys, lost keys, or keys locked inside the vehicle. We use the latest technology and techniques to ensure that we
                                    can get you back into your car quickly and safely. With our car lockout services, you can have peace of mind knowing that help is just a phone
                                    call away. Contact us today to schedule a consultation and experience the convenience and reliability of our car lockout services.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Master Rekey Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    If you're concerned about the security of your property and want to upgrade your locks, our locksmith company offers a comprehensive master
                                    rekey service to help protect your home or business. Our team of skilled professionals has the expertise and experience to provide a wide range
                                    of rekeying services, including master key system installation, lock replacement, and more. With our master rekey service, you can control
                                    access to your property with ease, without the need for multiple keys or complicated lock systems. We use only the highest quality products
                                    and techniques to ensure that your locks and security systems are functioning properly and providing the maximum level of protection. Our
                                    master rekey service is fast and affordable, and we are committed to providing the best possible customer experience. With our master rekey
                                    service, you can have peace of mind knowing that your property is secure and protected. Contact us today to schedule a consultation and
                                    experience the convenience and reliability of our high-quality master rekey service.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default LindonLocksmithComponent;
