import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";

const BlogPageComponent = ({ fetchSetting, fetchPosts }) => {
    const [setting, setSetting] = useState([]);
    const [post, setPosts] = useState([]);

    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    useEffect(() => {
        const abctrl = new AbortController();
        fetchPosts(abctrl)
            .then((res) => setPosts(res))
            .catch((er) =>
                setPosts([
                    { name: er.response.data.message ? er.response.data.message : er.response.data }
                ]),
            );
        return () => abctrl.abort();
    }, [fetchPosts]);
    
    return (
        <div className="container-fluid w-100 mb-5 pb-5">
            <MetaComponent
                title={setting.seoHelmentTitleBlog}
                description={setting.seoHelmentDescriptionBlog}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeBlog} />
            <div className="row blogBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Our Blog Posts</h1>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="container">
            <div className="row mt-5 justify-content-center">
                {post.map((item, idx) => (
                    <div key={idx} className="col-lg-4 mt-5">
                        <div className="card carding">
                            <Link to={`/blog/${item.title}`}>
                                <img src={item.images[0] ? item.images[0].path : ''} className="card-img-topping" alt="..." />
                            </Link>
                            <div className="card-body" >
                                <h5 className="card-title">{item.title}</h5>
                                  {item.content}
                                <p className="card-text">By: {item.author} | {new Date(item.updatedAt).toLocaleDateString()}</p>
                                <Link to={`/blog/${item.title}`} className="btn btn-outline-primary">Read More</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </div>
    )
}

export default BlogPageComponent;