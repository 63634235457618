import axios from "axios";
import HouseLockoutComponent from "./components/HomeLockoutComponent";

const HomeLockoutPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <HouseLockoutComponent fetchSetting={fetchSetting} />;
};

export default HomeLockoutPage;
