import axios from "axios";
import ElkRidgeLocksmithComponent from "./components/ElkRidgeLocksmithComponent";


const ElkRidgeLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <ElkRidgeLocksmithComponent fetchSetting={fetchSetting} />;
};

export default ElkRidgeLocksmithPage;
