import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaComponent from "../../components/MetaComponent";
import paths from "../../router/paths";

const OremLocksmithComponent = ({ fetchSetting }) => {
    const [setting, setSetting] = useState([]);
    useEffect(() => {
        fetchSetting()
            .then((setting) => setSetting(setting))
            .catch((er) => console.log(er));
    }, [fetchSetting]);

    return (
        <div className="container-fluid">
            <MetaComponent
                title={setting.seoHelmentTitleOrem}
                description={setting.seoHelmentDescriptionOrem}
                name={setting.seoHelmentName}
                type={setting.seoHelmentTypeOrem} />
            <div className="row OremBack justify-content-center">
                <div className="col text-center">
                    <div className="zIndex1 justify-content-center">
                        <h3 className="font-size-16 text-light mb-3 text-center">Trusted Locksmith Services from Captain Lock and Key</h3>
                        <div className="row text-center mb-3">
                            <div className="col-sn-12 text-center text-light">
                                <h1 className="font-size-45 fw-bold">Orem Locksmith</h1>
                            </div>
                        </div>
                        <p className="font-size-18 text-light text-center">Contact Us Today For A Free Estimate!</p>
                        <Link to={paths.CONTACT} className="btn btn-outline-primary text-center">Contact Us Today!</Link>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-md-10 mt-5">
                    <p className="font-size-16">
                        Looking for fast and reliable locksmith services in Orem? Look no further than Captain Lock and Key! Our team of skilled and experienced technicians
                        is equipped with the latest tools and technology to provide top-quality locksmith services for your home, business, or vehicle.
                    </p>
                    <p className="font-size-16">
                        We offer a wide range of services to meet your needs, including residential locksmith services such as lock repair, rekeying, and installation; commercial
                        locksmith services such as high-security locks, access control systems, and master key systems; and automotive locksmith services such as car key duplication,
                        transponder key programming, and ignition repair.
                    </p>
                    <p className="font-size-16">
                        At Captain Lock and Key, we know that security is essential for you and your property. That's why we're committed to providing fast and friendly service so you
                        can have peace of mind knowing that your locks are in good hands.
                    </p>
                    <p className="font-size-16">
                        We take pride in being Orem's trusted locksmith, and we're here to help you with any of your locksmith needs. Contact us today to schedule an appointment or to
                        receive a free quote for our services.
                    </p>
                    <p className="font-size-16 text-center mt-5">
                        <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                    </p>
                </div>
            </div>
            <div className="row oremBack1 justify-content-center pt-5 pb-5">
                <div className="col-sm-10 text-center">
                    <div className="w-100 mt-5">
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Automotive Locksmith Services</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Automotive locksmith services are a crucial component of car ownership, providing assistance in a wide range of situations,
                                    such as lockouts, broken keys, or lost car keys. These services are performed by experienced and skilled locksmiths who have
                                    the knowledge and technology to handle any type of automotive lock or key issue. Automotive locksmiths are equipped with the
                                    latest tools and equipment, such as key cutting machines, transponder key programming devices, and specialized extractors, to
                                    ensure that the service is performed quickly and efficiently. Whether you need a new set of car keys, a replacement for a lost key,
                                    or assistance with a lockout, an automotive locksmith has the expertise to get you back on the road in no time. Trustworthy and
                                    reliable, automotive locksmith services are an essential component of car ownership, offering peace of mind and security in any situation.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Lock Rekey Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Lock rekeying is a cost-effective solution for enhancing the security of your home, office, or vehicle. This service involves the
                                    modification of a lock's internal mechanism to prevent the use of the old key and enable the use of a new one. Rekeying is a simpler
                                    and more affordable alternative to replacing locks, especially when you have multiple locks that use the same key. By having your
                                    locks rekeyed, you can ensure that only authorized individuals have access to your property, as well as prevent the use of lost or
                                    stolen keys. A professional locksmith can perform a lock rekey service quickly and efficiently, using specialized tools and techniques
                                    to modify the lock's internal mechanism. Whether you're moving into a new property, need to change the locks for security reasons, or
                                    have lost your keys, a lock rekey service is an ideal solution for enhancing the security of your home, office, or vehicle.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Commercial Locksmith Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Commercial locksmith services play a crucial role in ensuring the security and protection of businesses and commercial properties.
                                    These services are performed by experienced locksmiths who have the knowledge and expertise to handle a wide range of security-related
                                    issues, such as lockouts, broken locks, or the installation of new locks and security systems. Commercial locksmiths have the tools
                                    and technology to handle any type of commercial lock or security system, including access control systems, master key systems, and
                                    high-security locks. By providing comprehensive security solutions, commercial locksmiths help businesses and commercial properties
                                    to maintain a secure and protected environment, while also providing peace of mind and confidence to business owners and employees.
                                    Whether you need a lock repair, a new set of keys, or the installation of a security system, a commercial locksmith has the expertise
                                    and experience to deliver fast, efficient, and reliable solutions.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Car Lockout Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Car lockouts are a common and frustrating inconvenience that can happen to anyone. Whether you've accidentally locked your keys in your car,
                                    or your car key has broken off in the door, being locked out of your car can be a stressful experience. That's why a car lockout service is
                                    a critical aspect of car ownership. Professional locksmiths are trained and equipped to handle any type of car lockout situation, providing
                                    fast and efficient solutions to get you back in your car as soon as possible. With the latest tools and techniques, a locksmith can unlock
                                    your car door without causing any damage to your vehicle. Whether you're stranded on the side of the road, or simply need to get back into
                                    your car in a hurry, a car lockout service is the answer. Trustworthy and reliable, car lockout services provide peace of mind and security,
                                    ensuring that you never have to be locked out of your car for long.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Fresh Installation Service</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Fresh installation services are an essential component of property maintenance and renovation. These services involve the installation
                                    of new locks and security systems, as well as the removal of old and outdated systems. Fresh installations are necessary when you move
                                    into a new property, perform renovations, or simply want to upgrade your security system for enhanced protection. A professional locksmith
                                    can perform a fresh installation service quickly and efficiently, using the latest tools and technology to ensure that your new locks and
                                    security systems are properly installed and functioning. By having a fresh installation performed, you can ensure that your property is secure
                                    and protected, while also enjoying the peace of mind that comes with a state-of-the-art security system. Whether you're looking to
                                    install a new deadbolt, a smart lock, or a comprehensive security system, a fresh installation service is the ideal solution for
                                    achieving the level of security and protection that you need.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="borderingLight">
                                <h2 className="fw-bold text-start ms-4 pt-3">Vehicle Key Programming</h2>
                                <p className="font-size-16 text-start ms-5">
                                    Vehicle key programming is a specialized service that is essential for many car owners. This service involves the creation
                                    and programming of new car keys, as well as the duplication of existing keys. With the advancement of automotive technology,
                                    many modern cars are equipped with transponder keys, which require specialized programming to function. A professional locksmith
                                    with the right tools and technology can perform vehicle key programming quickly and efficiently, ensuring that your new keys
                                    are properly programmed and functional. Whether you need a duplicate key for a spare, or a replacement key for a lost or damaged
                                    key, vehicle key programming is the solution. Trustworthy and reliable, vehicle key programming services provide peace of mind
                                    and security, ensuring that you always have access to your vehicle. With the ability to create and program new keys on the spot,
                                    a locksmith with the capability to perform vehicle key programming is a valuable resource for any car owner.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 justify-content-center">
                <div className="font-size-18 mt-3 col-md-10 fw-bold text-center">
                    AFFORDABLE LOCKSMITH SERVICES IN MAPLETON?
                </div>
                <div className="mt-4 col-md-10 mb-3 text-dark text-center">
                    <Link to={paths.CONTACT} className="btn btn-outline-primary">Contact Us Today!</Link>
                </div>
            </div>
        </div>
    );
};

export default OremLocksmithComponent;
