const paths = {
    ROOT: '/',
    LOGIN: '/login',
    ABOUT: '/about-us',
    BLOG: '/blog',
    BLOGDETAIL: '/blog/:id',
    CONTACT: '/contact',
    AUTOLOCK: '/automotive-locksmith',
    COMMLOCK: '/commercial-locksmith',
    EMERGLOCK: '/emergency-locksmith',
    RESLOCK: '/residential-locksmith',
    BUSINESSLOCK: '/business-lockout',
    HOUSELOCK: '/house-lockout',
    VEHICLELOCK: '/vehicle-lockout',
    PUSHBAR: '/push-bar-services',
    COMMREKEY: '/commercial-rekey-services',
    COMMLOCKINSTALL: '/lock-installation-and-repair',
    COMMMASTERKEY: '/master-key-services',
    COMMKEYDUP: '/key-duplication',
    RESLOCKINSTALL: '/lock-installation',
    RESSAFE: '/safe-install-open',
    RESKEYEXTRACT: '/residential-key-extraction',
    RESREKEY: '/residential-rekey',
    GENOLA: '/genola-locksmith',
    MAPLETON: '/mapleton-locksmith',
    SPANISHFORK: '/spanish-fork-locksmith',
    PAYSON: '/payson-locksmith',
    SALEM: '/salem-locksmith',
    SANTAQUIN: '/santaquin-locksmith',
    OREM: '/orem-locksmith',
    VINEYARD: '/vineyard-locksmith',
    ELKRIDGE: '/elk-ridge-locksmith',
    ALPINE: '/apline-locksmith',
    LEHI: '/lehi-locksmith',
    AMERICANFORK: '/american-fork-locksmith',
    LINDON: '/lindon-locksmith',
    PROVO: '/provo-locksmith',
    EAGLEMOUNTAIN: '/eagle-mountain-locksmith',
    REGISTER: '/register',
    VEHICLES: '/vehicles',
    USERPROFILE: '/user',
    ADMINPOSTS: '/admin/posts',
    ADMINCREATEPOST: '/admin/create-post',
    ADMINEDITPOST: '/admin/edit-post/:id',
    ADMINUSERS: '/admin/users',
    ADMINEDITUSER: '/admin/edit-user/:id',
    ADMINCHATS: '/admin/chats',
    ADMINMESSAGE: '/admin/messages',
    ADMINSETTINGS: '/admin/settings/:id',
    NOTFOUND: '*',

};

export default paths;