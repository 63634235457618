import axios from "axios";
import EagleMountainLocksmithComponent from "./components/EagleMountainLocksmithComponent";

const EagleMountainLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <EagleMountainLocksmithComponent fetchSetting={fetchSetting} />;
};

export default EagleMountainLocksmithPage;
