import axios from "axios";
import BlogPageComponent from "./components/BlogPageComponent";

const BlogPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }
    const fetchPosts = async (abctrl) => {
        const { data } = await axios.get("/api/posts/", {
            signal: abctrl.signal,
        })
        return data;
    }

    return <BlogPageComponent fetchPosts={fetchPosts} fetchSetting={fetchSetting} />;
};

export default BlogPage;
