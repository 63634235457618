import axios from "axios";
import SantaquinLocksmithComponent from "./components/SantaquinLocksmithComponent";


const SantaquinLocksmithPage = () => {

    const fetchSetting = async () => {
        const { data } = await axios.get(`/api/settings/63e46fdf4cfb5c8a65636952`);
        return data;
    }

    return <SantaquinLocksmithComponent fetchSetting={fetchSetting} />;
};

export default SantaquinLocksmithPage;
